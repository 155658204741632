import { COLORS } from '@utils/constants';
import styled from 'styled-components';
import { MdKeyboardArrowDown } from 'react-icons/md';

const TaskItemContainer = styled.div<{ checked: boolean; disabled: boolean; selected: boolean }>`
  min-width: 300px;
  padding: 5px;
  cursor: ${({ checked, disabled }) => (!disabled && !checked ? 'pointer' : 'not-allowed')};
  background-color: ${({ selected }) => (selected ? 'rgba(221, 241, 249, 0.63)' : 'transparent')};
  border-left: 5px solid ${({ selected }) => (selected ? COLORS.darkBlue : 'transparent')};
  transition: border-color 0.3s ease;

  ${({ disabled, checked }) =>
    (disabled || checked) &&
    `
      background-color: ${COLORS.LightGrey};
    `}
  svg {
    width: 20px;
    height: 20px;
  }
`;

const TaskItemTitleContainer = styled.div<{ checked: boolean; disabled: boolean; selected: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  min-width: 300px;
  padding: 10px;
  cursor: ${({ checked, disabled }) => (!disabled && !checked ? 'pointer' : 'not-allowed')};
  ${({ disabled, checked }) =>
    (disabled || checked) &&
    `
      background-color: ${COLORS.LightGrey};
    `}
  color: ${({ selected }) => (selected ? COLORS.darkBlue : COLORS.Black)};

  svg {
    width: 20px;
    height: 20px;
  }
`;

const CheckedIconContainer = styled.div<{ checked: boolean; disabled: boolean }>`
  flex: 4;
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    width: 25px;
    height: 25px;
    color: ${({ checked, disabled }) => (!disabled ? (checked ? COLORS.Green : COLORS.NiceBlue) : COLORS.MediumGrey)};
  }
`;

const TitleContainer = styled.div<{ checked: boolean; disabled: boolean }>`
  flex: 4;
  ${({ disabled, checked }) =>
    !disabled &&
    checked &&
    `
    text-decoration: line-through;
    color: ${COLORS.DarkGrey};
  `}
  ${({ disabled }) =>
    disabled &&
    `
    color: ${COLORS.MediumGrey};
  `}
  font-size: 13px;
  font-weight: 500;
`;

const ArrowContainer = styled.div<{ checked: boolean; disabled: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  color: ${({ checked, disabled }) => (disabled || checked ? COLORS.MediumGrey : COLORS.NiceBlue)};
`;

const SubMenuContainer = styled.div`
  padding-left: 35px;
  margin-top: 10px;
`;

const SubTaskItemContainer = styled.div<{ checked: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  cursor: ${({ checked, disabled }) => (!disabled && !checked ? 'pointer' : 'not-allowed')};
  border-radius: 15px;
  padding: 0 10px;

  svg {
    width: 15px;
    height: 15px;
  }
`;

const SubTaskItemTitleContainer = styled.div<{ checked: boolean; disabled: boolean }>`
  flex: 4;
  color: ${COLORS.Black};
  ${({ disabled, checked }) =>
    !disabled &&
    checked &&
    `
    text-decoration: line-through;
    color: ${COLORS.DarkGrey};
  `}
  ${({ disabled }) =>
    disabled &&
    `
    color: ${COLORS.MediumGrey};
  `}
  ${({ checked }) =>
    checked &&
    `
    color: ${COLORS.Green};
  `}
  text-decoration: underline;
`;

const DescriptionContainer = styled.div`
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
  padding: 0 20px 10px 40px;
`;

const StyledArrow = styled(MdKeyboardArrowDown)<{ up?: boolean }>`
  transform: ${({ up }) => (up ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;

export {
  TaskItemContainer,
  TaskItemTitleContainer,
  CheckedIconContainer,
  TitleContainer,
  ArrowContainer,
  SubMenuContainer,
  SubTaskItemContainer,
  SubTaskItemTitleContainer,
  DescriptionContainer,
  StyledArrow,
};
