import { ACCOUNT_TYPES, BUTTON_COLORS } from '@utils/constants';
import { DashboardNotes } from '@components/dashboard-note/DashboardNote';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllAccounts,
  selectAccount,
  selectAllAccounts,
  selectSelectedAccount,
  setSelectedAccount,
  updateAccount,
} from '../../reducers/account.reducer';
import { useState } from 'react';
import {
  ErrorMessage,
  UserInfosSlider,
  InfoItem,
  InfosFlexContainer,
  Left,
  Right,
  UserDetailsTop,
  UserActionContainer,
  BypassOnboarding,
  LegalOfficerContainer,
  ActionDeleteAndArchive,
} from '@components/admin-infos/AdminInfos_.style';
import { Button } from '@components/Button';
import { BsTrash } from 'react-icons/bs';
import { BsArchive } from 'react-icons/bs';
import { FaRegSave } from 'react-icons/fa';
import { deleteAccountById } from '@utils/api';
import { toast } from 'react-toastify';
import { setLoading } from '../../reducers/app.reducer';

export const AdminInfos = () => {
  //REDUX
  const dispatch = useDispatch<any>();
  const selectedAccount = useSelector(selectSelectedAccount);
  const account = useSelector(selectAccount);
  const allAccounts = useSelector(selectAllAccounts);

  //STATE
  const [isolatedChanges, setIsolatedChanges] = useState<{
    legalOfficer?: object;
    links?: object;
    activity?: object;
  }>({});
  const hasFakeAppointment = selectedAccount.activity.appointments.list.some(appointment => appointment.flag === 'FAKE');
  const [bypassOnboardingAppointment, setBypassOnboardingAppointment] = useState<boolean>(hasFakeAppointment);

  const isAlreadyActivated = selectedAccount?.activatedAt !== undefined;

  const getLegalOfficer = userName => allAccounts.find(account => account.companyName === userName);

  const sortAlphabetically = arr =>
    arr.sort((a, b) => {
      if (a.companyName < b.companyName) {
        return -1;
      }
      if (a.companyName > b.companyName) {
        return 1;
      }
      return 0;
    });

  const getSuperAdmin = () => {
    let admins = sortAlphabetically(allAccounts.filter(account => account.accountType === ACCOUNT_TYPES.ADMIN));
    return admins.map((adminUser, index) => {
      return (
        <option key={index} value={adminUser.companyName}>
          {adminUser.companyName}
        </option>
      );
    });
  };

  const handleAccountInfosChange = (e, isBoolean: boolean = false) => {
    if (e.target.value === selectedAccount[e.target.name]) {
      const newIsolatedChanges = _.cloneDeep(isolatedChanges);
      delete newIsolatedChanges[e.target.name];
      setIsolatedChanges(newIsolatedChanges);
      return;
    }
    setIsolatedChanges({ ...isolatedChanges, [e.target.name]: e.target.value });
  };

  const handleUserLegalOfficerChange = e => {
    if (e.target.value === 'Sélectionner...' || e.target.value === selectedAccount.legalOfficer?.name) {
      const newIsolatedChanges = _.cloneDeep(isolatedChanges);
      delete newIsolatedChanges[e.target.name];
      setIsolatedChanges(newIsolatedChanges);
      return;
    }
    let legalOfficer = getLegalOfficer(e.target.value);

    setIsolatedChanges({
      ...isolatedChanges,
      legalOfficer: {
        name: legalOfficer.companyName,
        email: legalOfficer.users[0].email,
      },
    });
  };

  const hasLegalOfficer = selectedAccount?.legalOfficer?.name !== undefined && selectedAccount?.legalOfficer?.name !== '';

  const saveSelectedAccountInfos = async () => {
    setIsolatedChanges({});
    let isLegalOfficerInitialization = !hasLegalOfficer && isolatedChanges.hasOwnProperty('legalOfficer');

    await dispatch(
      updateAccount({
        accountId: selectedAccount._id,
        infosChanges: isolatedChanges,
        legalOfficerInitialization: isLegalOfficerInitialization,
        bypassOnboardingAppointment,
      })
    );
  };

  const handleDeleteAccount = async accountId => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce compte ?') === true) {
      await deleteAccountById(accountId);
      dispatch(getAllAccounts());
      dispatch(setSelectedAccount(null));
      toast.error('Le client a bien été supprimé', { position: 'top-center' });
    }

    dispatch(setLoading(false));
  };

  const handleArchiveAccount = async () => {
    let isLegalOfficerInitialization = !hasLegalOfficer && isolatedChanges.hasOwnProperty('legalOfficer');

    if (
      window.confirm(
        !selectedAccount.isArchived ? 'Êtes-vous sûr de vouloir archiver ce compte ?' : 'Êtes-vous sûr de vouloir désarchiver ce compte ?'
      ) === true
    ) {
      await dispatch(
        updateAccount({
          accountId: selectedAccount._id,
          infosChanges: { isArchived: !selectedAccount.isArchived, archivedAt: !selectedAccount.isArchived ? Date.now() : null },
          legalOfficerInitialization: isLegalOfficerInitialization,
          bypassOnboardingAppointment,
        })
      );
      toast.info(!selectedAccount.isArchived ? 'Le client a bien été archivé' : 'Le client a bien été enlevé des archives', {
        position: 'top-center',
      });
    }
  };

  return (
    <>
      <UserDetailsTop>
        <UserActionContainer>
          <Button
            type='primary'
            icon={FaRegSave}
            label={'Mettre à jour les infos'}
            onClick={saveSelectedAccountInfos}
            customColor={BUTTON_COLORS.default}
            disabled={JSON.stringify(isolatedChanges) === '{}' && hasFakeAppointment === bypassOnboardingAppointment}
          />
        </UserActionContainer>
      </UserDetailsTop>
      <InfosFlexContainer>
        <Left>
          <UserInfosSlider>
            <InfoItem>
              <span>Nom </span>
              <input
                defaultValue={selectedAccount?.companyName}
                name={'companyName'}
                onChange={handleAccountInfosChange}
                placeholder={'nom complet du groupe'}
                type='text'
              />
            </InfoItem>
            <InfoItem>
              <span>Type de compte</span>
              {account?.accountType === ACCOUNT_TYPES.ADMIN && (
                <select defaultValue={selectedAccount.accountType} onChange={handleAccountInfosChange} name='accountType' id=''>
                  {Object.keys(ACCOUNT_TYPES).map((type, index) => {
                    return (
                      <option key={index} value={ACCOUNT_TYPES[type]}>
                        {ACCOUNT_TYPES[type]}
                      </option>
                    );
                  })}
                </select>
              )}
            </InfoItem>
            <InfoItem>
              <span>Responsable juridique</span>
              {account?.accountType === ACCOUNT_TYPES.ADMIN && (
                <LegalOfficerContainer>
                  <select defaultValue={selectedAccount?.legalOfficer?.name} onChange={handleUserLegalOfficerChange} name='legalOfficer' id=''>
                    <option>Sélectionner...</option>
                    {getSuperAdmin()}
                  </select>
                  <BypassOnboarding disabled={isAlreadyActivated}>
                    <label htmlFor={'bypassAppointment'}>
                      <input
                        defaultChecked={bypassOnboardingAppointment}
                        id={'bypassAppointment'}
                        type='checkbox'
                        onChange={() => {
                          setBypassOnboardingAppointment(prev => !prev);
                        }}
                        disabled={isAlreadyActivated}
                      />
                      Sans prise de RDV
                    </label>
                  </BypassOnboarding>
                </LegalOfficerContainer>
              )}
              {!hasLegalOfficer && <ErrorMessage>Aucun responsable juridique n'est attribué</ErrorMessage>}
            </InfoItem>
            <InfoItem>
              <span>L'utilisateur a un produit : </span>
              <div>
                <label htmlFor={'with_product_true'}>
                  <input
                    defaultChecked={selectedAccount.withProduct === 'yes' || selectedAccount.withProduct === 'true'}
                    name={'withProduct'}
                    onChange={e => handleAccountInfosChange(e)}
                    type='radio'
                    id={'with_product_true'}
                    value={'yes'}
                  />
                  Oui
                </label>
                <label htmlFor={'with_product_false'}>
                  <input
                    defaultChecked={selectedAccount.withProduct === 'no' || selectedAccount.withProduct === 'false'}
                    name={'withProduct'}
                    onChange={e => handleAccountInfosChange(e)}
                    type='radio'
                    id={'with_product_false'}
                    value={'no'}
                  />
                  Non
                </label>
                <label htmlFor={'with_product_idk'}>
                  <input
                    defaultChecked={selectedAccount.withProduct === 'idk' || !selectedAccount.hasOwnProperty('withProduct')}
                    name={'withProduct'}
                    onChange={e => handleAccountInfosChange(e)}
                    type='radio'
                    id={'with_product_idk'}
                    value={'idk'}
                  />
                  Je ne sais pas
                </label>
              </div>
            </InfoItem>
            <InfoItem>
              <span>L’utilisateur a acheté des CGU/CGV : </span>
              <div>
                <label htmlFor={'cgu_true'}>
                  <input
                    defaultChecked={selectedAccount.cgu === 'yes' || selectedAccount.cgu === 'true'}
                    name={'cgu'}
                    onChange={e => handleAccountInfosChange(e)}
                    type='radio'
                    id={'cgu_true'}
                    value={'yes'}
                  />
                  Oui
                </label>
                <label htmlFor={'cgu_false'}>
                  <input
                    defaultChecked={selectedAccount.cgu === 'no' || selectedAccount.cgu === 'false'}
                    name={'cgu'}
                    onChange={e => handleAccountInfosChange(e)}
                    type='radio'
                    id={'cgu_false'}
                    value={'no'}
                  />
                  Non
                </label>
                <label htmlFor={'cgu_idk'}>
                  <input
                    defaultChecked={selectedAccount.cgu === 'idk' || !selectedAccount.hasOwnProperty('cgu')}
                    name={'cgu'}
                    onChange={e => handleAccountInfosChange(e)}
                    type='radio'
                    id={'cgu_idk'}
                    value={'idk'}
                  />
                  Je ne sais pas
                </label>
              </div>
            </InfoItem>
            <InfoItem>
              <span>L'utilisateur est un sous-traitant :</span>
              <div>
                <label htmlFor={'processor_true'}>
                  <input
                    defaultChecked={selectedAccount.processor === 'yes' || selectedAccount.processor === 'true'}
                    name={'processor'}
                    onChange={e => handleAccountInfosChange(e)}
                    type='radio'
                    id={'processor_true'}
                    value={'yes'}
                  />
                  Oui
                </label>
                <label htmlFor={'processor_false'}>
                  <input
                    defaultChecked={selectedAccount.processor === 'no' || selectedAccount.withProduct === 'false'}
                    name={'processor'}
                    onChange={e => handleAccountInfosChange(e)}
                    type='radio'
                    id={'processor_false'}
                    value={'no'}
                  />
                  Non
                </label>
                <label htmlFor={'processor_idk'}>
                  <input
                    defaultChecked={selectedAccount.processor === 'idk' || !selectedAccount.hasOwnProperty('processor')}
                    name={'processor'}
                    onChange={e => handleAccountInfosChange(e)}
                    type='radio'
                    id={'processor_idk'}
                    value='idk'
                  />
                  Je ne sais pas
                </label>
              </div>
            </InfoItem>
            <InfoItem>
              <span>Identifiant de référence Pennylane</span>
              <input
                defaultValue={selectedAccount?.pennylane?.reference}
                name={'pennylane.reference'}
                onChange={handleAccountInfosChange}
                placeholder={'Identifiant de référence Pennylane'}
                type='text'
              />
            </InfoItem>
            <ActionDeleteAndArchive>
              {selectedAccount?.accountType !== 'superadmin' && (
                <>
                  {!selectedAccount.isArchived ? (
                    <Button label={'Archiver'} customColor={'#b2bec3'} icon={BsArchive} onClick={() => handleArchiveAccount()} />
                  ) : (
                    <Button label={'Désarchiver'} customColor={'#b2bec3'} icon={BsArchive} onClick={() => handleArchiveAccount()} />
                  )}
                </>
              )}
              <Button label={'Supprimer'} type={'danger'} icon={BsTrash} onClick={() => handleDeleteAccount(selectedAccount?._id)} />
            </ActionDeleteAndArchive>
          </UserInfosSlider>
        </Left>
        <Right>
          <DashboardNotes
            style={{
              display: 'flex',
              height: 'calc(100% - 10px)',
              flexDirection: 'column',
            }}
          />
        </Right>
      </InfosFlexContainer>
    </>
  );
};
