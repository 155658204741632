import PopUp from '@components-new/atoms/popUp/PopUp';
import { CheckListProps } from './CheckList.type';
import ProgressBar from '@components-new/atoms/progressBar/ProgressBar';
import { COLORS } from '@utils/constants';
import TaskItem from '@components-new/atoms/taskItem/TaskItem';
import { Divider } from '@mui/material';
import { CheckListContainer, SubTitleContainer } from './CheckList_.style';
import { useState } from 'react';

const CheckList = ({ title, subTitle, actionList, icon, open }: CheckListProps) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const getCheckedPercentage = () => {
    if (actionList.length === 0) return 0;
    const nonDisabledActions = actionList.filter(action => !action.disabled);

    const checkedActions = nonDisabledActions.filter(action => {
      if (!action.subMenu) {
        return action.checked;
      } else {
        return action.subMenu.every(subTask => subTask.checked);
      }
    }).length;

    const checkedPercentage = (checkedActions / nonDisabledActions.length) * 100;
    return Math.trunc(checkedPercentage);
  };

  const handleClick = action => {
    setSelectedItem(action.id);
    if (action.onClick) {
      action.onClick();
    }
  };

  return (
    <div>
      <PopUp title={title} icon={icon} opened={open} eventTrackingTitle={'CheckList'}>
        <>
          <SubTitleContainer>{subTitle}</SubTitleContainer>

          <ProgressBar bgcolor={COLORS.Green} completed={getCheckedPercentage()} />
          <Divider />
          <CheckListContainer>
            {actionList.map(action => {
              return (
                <TaskItem
                  selected={selectedItem === action.id}
                  key={action.id}
                  title={action.title}
                  onClick={() => handleClick(action)}
                  checked={action.checked}
                  disabled={action.disabled}
                  subMenu={action.subMenu}></TaskItem>
              );
            })}
          </CheckListContainer>
        </>
      </PopUp>
    </div>
  );
};

export default CheckList;
