import styled from 'styled-components';

import { Infos } from '@components/infos/Infos';
import { Button } from '@components/Button';

import { COLORSV2, STATUS_COLORS } from '@utils/constants';

export const StyledInfos = styled(Infos)`
  position: relative;
  bottom: 0;
  margin-top: 5%;
`;

export const ActionButton = styled(Button)<{ slide: number }>`
  ${({ slide }) =>
    slide === 3 &&
    `
    position: absolute;
    bottom: 5%;
    left: 410px;
    `}
`;

export const WizardContainer = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  background-color: white;

  font-family: Biennale;
  color: ${COLORSV2.primary};
`;

export const SideBar = styled.div`
  width: 210px;
  background-color: #fff9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Separator = styled.div`
  color: #1179a1;
  width: 50px;
  border: 1px solid #1179a1;
  margin-top: 35px;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-size: 1.33rem;
  margin-top: 190px;
  flex: 1;
  user-select: none;
`;

export const Step = styled.div<{ selected: boolean; isSelectable: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  font-size: 16px;
  color: ${({ selected }) => (selected ? COLORSV2.primary : COLORSV2.textLightGrey)};
  font-weight: ${({ selected }) => (selected ? 500 : 400)};
  cursor: ${({ isSelectable }) => (isSelectable ? 'pointer' : 'not-allowed')};

  svg {
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
    height: 24px;
    width: 24px;
  }
`;

export const ContentContainer = styled.div`
  // display: grid;
  // grid-template-columns: minmax(50%, 70%) minmax(0, min-content);
  overflow: hidden;
  max-width: 100%;
  width: calc(100% - 210px);
  height: 100%;
`;

export const Slide = styled.div<{ slide: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${({ slide }) =>
    slide === 3
      ? `
      font-size: 1rem;
      padding: 0 125px 0 0;
    `
      : `
      font-size: 1.33rem;
      padding: 0 125px;
    `}

  row-gap: 40px;
  animation: fadeInRightBig 0.5s;
  height: 100%;
`;

export const StepTitle = styled.div`
  display: flex;
  max-width: 596px;
  font-size: 4.1rem;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.011em;
`;

export const Content = styled.div`
  display: flex;
  max-width: 863px;
  line-height: 26px;
  font-weight: 500;
  font-size: 18px;
  white-space: pre-line;
  button {
    margin-top: 20px;
  }
`;
export const ErrorMessage = styled.div`
  color: red;
`;

export const SpinnerContainer = styled.div<{ slide: number }>`
  margin-left: 15px;
  ${({ slide }) =>
    slide === 3 &&
    `
    position: absolute;
    bottom: 7%;
    margin-left: 45px;
    left: 410px;
    `}
`;

export const QuestionsContainer = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 20px 30px 20px 70px;
  height: calc(100% - 11rem);
  width: calc(100% - 85px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    transition: width 0.2s;
    width: 4px;
    height: 6px;

    &:hover {
      width: 6px;
    }
  }
`;

export const ListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow-y: auto;
  padding-right: 10px;
  margin-top: 10px;
  max-height: 53%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 2.6em;
`;

export const SurveyTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 70px;
  row-gap: 30px;
`;

export const SurveyPageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

export const SurveyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  gap: 1rem;
`;

export const ThemeContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 300px;
  background-color: #fff9f9;
  padding: 40px;
  border-left: 1px solid ${COLORSV2.border};
`;

export const ThemeList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 100%;
`;

export const ThemeTitle = styled.span`
  font-weight: 700;
  font-size: 2.6em;
`;

export const Theme = styled.div<{ status: string }>`
  background-color: white;
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 1.2em;
  font-weight: 400;
  display: flex;
  align-items: center;
  column-gap: 5px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  height: 25px;

  ${({ status }) => {
    switch (status) {
      case 'completed':
        return `
            border: 1.5px solid ${STATUS_COLORS.success};
            background-color: ${COLORSV2.success};
            color: ${COLORSV2.primary};`;
      case 'ongoing':
        return `
          border: 1.5px solid ${COLORSV2.ongoing};
        `;
      default:
        return `border: 1.5px solid ${COLORSV2.grey};`;
    }
  }}

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    flex: 1;
  }

  .share {
    align-self: flex-start;
    opacity: 0.5;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }
`;

export const Circle = styled.div`
  background-color: rgba(200, 200, 200, 1);
  border-radius: 50px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  margin-right: 4px;
`;
