import styled, { css } from 'styled-components';
import { COLORS, COLORSV2 } from '@utils/constants';
import { Tab } from '@mui/material';

const Empty = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  align-self: center;

  svg {
    font-size: 80px;
    animation: swing 0.8s ease;
  }

  span {
    font-size: 1.2em;
  }
`;

const ContentContainer = styled.div<{ empty: boolean }>`
  display: grid;
  grid-template-columns: ${({ empty }) => (empty ? '1fr' : 'repeat(auto-fill, minmax(380px, 1fr))')};
  row-gap: 20px;
  column-gap: 20px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
  ${({ empty }) =>
    empty &&
    css`
      flex: 1;
    `}
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  position: relative;
`;

const MuiTab = styled(Tab)<{ selected: boolean; disabled: boolean }>`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled }) => (disabled ? COLORS.softRed : 'white')};
  transition: all 0.2s ease-in-out;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &:hover {
    border-bottom: 3px solid ${COLORSV2.lightOrange};
  }

  && {
    color: ${COLORSV2.primary};
    font-size: 1.2rem;
    font-weight: 600;
    font-family: Biennale;
    text-transform: none;
    border-bottom: 3px solid transparent;
  }

  &&.Mui-selected {
    color: ${COLORSV2.lightOrange};
    border-bottom: 3px solid ${COLORSV2.lightOrange};
  }
`;

const ContentTitle = styled.div`
  font-family: 'Biennale SemiBold';
  font-size: 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  color: ${COLORSV2.primary};

  div {
    font-family: Nexa;
    font-size: 1.2rem;
    font-weight: 500;
    color: ${COLORSV2.textLightGrey};
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;

const DashboardEntityContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 80px);
  height: 100vh;

  font-family: Biennale;
`;

const DashboardEntityTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${COLORSV2.lightPink};
  padding: 30px 50px 0 50px;
  height: 25%;
`;

const DashboardContent = styled.div`
  min-height: 0;

  flex: 1;
  row-gap: 50px;
  display: flex;
  flex-direction: column;
  padding: 30px;

  background-color: ${COLORSV2.white};
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  align-self: flex-end;
`;

export {
  Empty,
  ContentContainer,
  TabContainer,
  MuiTab,
  ContentTitle,
  DashboardEntityContainer,
  DashboardEntityTop,
  DashboardContent,
  ButtonContainer,
};
