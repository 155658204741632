import { InputComponent } from '@components/form/InputComponent';
import { COLORS } from '@utils/constants';
import styled from 'styled-components';

export const ProductImage = styled.img`
    width: 25px;
    height: 25px;
`;

export const ProductsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    max-height: -webkit-fill-available;
    border: 1px solid #c4c3c250;
    border-radius: 8px;
    height: fit-content;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${COLORS.darkBlue};
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #c7c7c7;
    }

    &::-webkit-scrollbar-track {
        background: #F5F5F5;
        border-radius: 10px;
        box-shadow: inset 2.1px -1px 12px 0px #f0f0f0;
    }
`;

export const ScrollableContent = styled.div`
    &::-webkit-scrollbar-thumb {
        background-color: ${COLORS.darkBlue};
        border-radius: 10px;
    }
`;

export const NoProduct = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Biennale;
  font-size: 16px;
  font-weight: 600;
  height: 100%;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;

  > svg {
    height: 50px;
    width: 50px;
    color: lightgray;
    animation: swing 0.8s ease 0.3s;
  }
`;

export const ProductItemWrapper = styled.div<{ isLast: boolean }>`
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;

  border-bottom: 1px solid #c4c3c250;

  &:last-child {
    border-bottom: none;
  }
`;

export const StepContent = styled.div`
  display: flex;
  width: 100%;
  border-radius: 8px;
  flex: 1;
`;

export const StepContentContainer = styled.div`
  display: flex;
  width: 100%;
  height: inherit;
  min-height: inherit;
`;

export const CreateProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  margin-top: 30px;
  flex: 1;
`;

export const StyledInputComponent = styled(InputComponent)`
  input {
    background-color: rgba(221, 241, 249, 0.63);
  }
`;

export const ProductAddedContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;

  > svg {
    margin-bottom: 30px;
  }
`;

export const ProductAddedText = styled.div`
  font-family: Biennale;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.74px;
  text-align: center;
  margin-bottom: 5px;
`;

export const ProductAddedName = styled.div`
  font-family: Biennale;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.28px;
  text-align: center;
`;

export const FormCompletionNotice = styled.div`
  font-family: Biennale;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.82px;
  text-align: center;
  max-width: 60%;
  margin-top: 30px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    margin-top: 30px;
`;