import styled, { css } from 'styled-components';
import { COLORS } from '@utils/constants';
import { MdArrowForwardIos } from 'react-icons/md';

export const AdminStepsPageContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

export const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100% - 60px);
  padding: 30px;
  row-gap: 30px;
`;

export const Description = styled.div``;

export const EmptyEntity = styled.div`
  border: 1px dashed ${COLORS.MediumGrey};
  width: 100%;
  border-radius: 15px;
  flex: 1;
`;

export const EmptyMessages = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  span {
    text-align: center;
  }

  svg {
    height: 30px;
    width: 30px;
    color: ${COLORS.MediumGrey};
    animation: swing 0.8s ease;
  }
`;

export const EmptyStep = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  span {
    text-align: center;
  }

  svg {
    height: 30px;
    width: 30px;
    color: ${COLORS.MediumGrey};
    animation: swing 0.8s ease;
  }
`;

export const Label = styled.div<{ size: number }>`
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  flex: ${({ size }) => (size ? size : 1)};
  &:first-child {
    padding-left: 5px;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const MailContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const MessageRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Nunito, sans-serif;
  width: calc(100% - 30px);
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.MediumGrey};
  padding: 20px 15px;

  &:hover {
    background-color: ${COLORS.MediumGrey};
  }
`;

export const MessageStatus = styled.div<{ size: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  color: ${COLORS.DarkGrey};
  flex: ${({ size }) => (size ? size : 1)};
  gap: 5px;
  padding-left: 5px;
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const MessagesHeaders = styled.div`
  display: flex;
  width: calc(100% - 30px);
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid ${COLORS.MediumGrey};
  padding: 10px 15px;
`;

export const OnboardingAction = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  input {
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const Status = styled.div<{ step: any; submission: any }>`
  width: 4px;
  height: 100%;
  background-color: ${({ step, submission }) => {
    if (step?.isValidated) {
      return COLORS.DarkGreen;
    } else if (step?.name === 'surveys' && submission?.submitted) {
      return COLORS.Squash;
    }
  }};
`;

export const StatusIcon = styled.div<{ status: string }>`
  display: flex;
  border-radius: 50%;
  background-color: ${({ status }) => (status === 'delivered' ? `${COLORS.xlsx}` : `${COLORS.Red}`)};
  height: 10px;
  width: 10px;
`;

export const Step = styled.div<{ bordered: boolean; disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
         pointer-events: none;
        background: ${COLORS.MediumGrey};
  `}
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid ${COLORS.MediumGrey};

  > svg {
    width: 30px;
    height: 30px;

    &.arrow {
      width: 15px;
      height: 15px;
    }
  }

  ${({ bordered }) =>
    bordered &&
    css`
      overflow: hidden;
      border: 1px solid ${COLORS.MediumGrey};
      border-radius: 4px;
    `}
`;

export const StepDetailsDrawer = styled.div<{ selectedStep: boolean }>`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px ${({ selectedStep }) => (selectedStep ? 'solid' : 'dashed')} ${COLORS.MediumGrey};
  margin: 30px 0;
  height: calc(100% - 102px);
  row-gap: 20px;
`;

export const StepDetailsTabs = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const StepInfos = styled.div<{ open: boolean }>`
  padding: ${({ open }) => (open ? '20px' : '0')};
  height: ${({ open }) => (open ? 'auto' : '0')};
  overflow: hidden;
  transition:
    padding 0.2s ease-in-out,
    height 0.2s ease-in-out;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  width: calc(100% - 40px);
`;

export const StepInfosContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const StepsContainer = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: auto;
  border: 1px solid ${COLORS.MediumGrey};
  flex: 1;
`;

export const StepTitle = styled.div`
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  column-gap: 10px;
  align-items: center;

  span {
    font-size: 1rem;
    color: ${COLORS.Squash};
    font-weight: 400;
  }

  svg {
    color: ${COLORS.xlsx};
    height: 20px;
    width: 20px;
  }
`;

export const StyledMdArrowForwardIos = styled(MdArrowForwardIos)<{ open: boolean }>`
  transform: rotate(${({ open }) => (open ? '90deg' : '0deg')});
  transition: transform 0.2s ease-in-out;
`;

export const Tab = styled.div<{ current: boolean }>`
  padding: 20px 15px;
  height: calc(100% - 40px);
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  cursor: pointer;

  ${({ current }) =>
    current &&
    `
         background-color: #187ead;
         color: #fff;     
  `}
  &:first-child {
    border-top-left-radius: 4px;
    border-right: 1px solid ${COLORS.MediumGrey};
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
  &:hover {
    background-color: #187ead;
    color: #fff;
  }
`;

export const Title = styled.h3`
  color: ${COLORS.DarkGrey};
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
`;

export const Top = styled.div<{ activated?: boolean }>`
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  padding: 20px;
  align-items: center;
  width: calc(100% - 40px);
  transition: background-color 0.2s ease-in-out;

  > svg {
    width: 30px;
    height: 30px;

    color: ${({ activated }) => (activated ? COLORS.xlsx : COLORS.DarkGrey)};

    &.arrow {
      width: 15px;
      height: 15px;
    }
  }
`;

export const TopContainer = styled.div<{ open: boolean }>`
  display: flex;
  width: 100%;
  background-color: ${({ open }) => (open ? COLORS.LightGrey : 'transparent')};

  &:hover {
    background-color: ${COLORS.MediumGrey};
  }
`;

export const TopInfos = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  flex: 1;
  cursor: pointer;

  span {
    color: ${COLORS.xlsx};
  }
`;

export const Value = styled.div<{ size: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  color: ${COLORS.DarkGrey};
  flex: ${({ size }) => (size ? size : 1)};
  gap: 5px;
`;
