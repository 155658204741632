import dayjs from "dayjs";

import Container from "../container/Container";
import { Input, Span } from "./DateRangePicker_.style";
import { DateRangePickerProps } from "./DateRangePicker.type";
import { DATE_PICKER, DATE_PICKER_LABEL_START, DATE_PICKER_INPUT_START, DATE_PICKER_NO_LABEL, DATE_PICKER_LABEL_END, DATE_PICKER_INPUT_END } from "./DateRangePicker.constant";

const DateRangePicker = ({ className, labels, startDate, endDate, setStartDate, setEndDate, inputsWidth, inputsHeight }: DateRangePickerProps) => {
  if (dayjs(endDate).isBefore(dayjs(startDate))) setEndDate('')

  return (
    <Container className={className} flex alignItems="center" mrChild="1rem" data-testid={DATE_PICKER}>
      {labels && <Span size={labels?.size} data-testid={DATE_PICKER_LABEL_START}>{labels?.startDate}</Span>}
      <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} width={inputsWidth} height={inputsHeight} data-testid={DATE_PICKER_INPUT_START} />
      {!labels && <Span size={labels?.size} data-testid={DATE_PICKER_NO_LABEL}> - </Span>}
      {labels && <Span size={labels?.size} data-testid={DATE_PICKER_LABEL_END}>{labels?.endDate}</Span>}
      <Input type="date" min={startDate} value={endDate} onChange={(e) => setEndDate(e.target.value)} disabled={startDate === '' || !startDate} width={inputsWidth} height={inputsHeight} data-testid={DATE_PICKER_INPUT_END} />
    </Container>
  );
};

export default DateRangePicker;
