import { STATUS_COLORS } from '../../utils/constants';
import { useEffect, useState } from 'react';

export interface PieProps {
  percentage: number;
  radius?: number;
  strokeWidth?: number;
  rounded?: boolean;
  fontSize?: string;
  duration?: number;
}

export const Pie = ({ percentage, radius, strokeWidth, rounded, fontSize, duration }: PieProps) => {
  const [progress, setProgress] = useState(0);
  radius = radius ?? 25;

  useEffect(() => {
    setProgress(percentage);
  }, [progress, percentage]);

  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  const setColor = () => {
    switch (progress) {
      case 100:
        return STATUS_COLORS.success;
      case 0:
        return STATUS_COLORS.notStarted;
      default:
        return STATUS_COLORS.ongoing;
    }
  };

  return (
    <svg height={radius * 2} width={radius * 2}>
      {/* Cercle de fond */}
      <circle
        stroke={progress === 0 ? STATUS_COLORS.backgroundNotStarted : '#DDF1F9'}
        fill='transparent'
        strokeWidth={strokeWidth}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      {/* Cercle de progression */}
      <circle
        stroke={setColor()}
        fill='transparent'
        strokeWidth={strokeWidth ?? '10'}
        strokeDasharray={circumference + ' ' + circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap={rounded ? 'round' : 'butt'}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        style={{
          transition: `stroke-dashoffset ${duration ?? '0.3'}s`,
          transform: 'rotate(-90deg)',
          transformOrigin: '50% 50%',
        }}
      />
      <text
        x='50%'
        y='50%'
        textAnchor='middle'
        dy='.3em'
        fontSize='20px'
        style={{ fontFamily: 'Biennale', fontWeight: 600, fontSize: fontSize ?? '12px', fill: setColor() }}>
        {`${progress}%`}
      </text>
    </svg>
  );
};
