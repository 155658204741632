import { Modal } from '../modal/Modal';
import { getFullSurveyList } from '../../utils/utils';
import { FaCheck, FaTimes } from 'react-icons/fa';
import {
  Action,
  ConformityErrorsSummary,
  ErrorItem,
  ErrorList,
  ErrorsActions,
  ErrorTitle,
  Label,
  Slider,
  SurveyName,
} from './ModalSubmitEntityErrors_.style';
import { useEffect, useState } from 'react';

export interface ModalSubmitEntityErrorsProps {
  entity: any;
  show?: boolean;
  onClose: () => void;
  onClick: () => void;
}

export const ModalSubmitEntityErrors = ({ entity, show, onClose, onClick }: ModalSubmitEntityErrorsProps) => {
  const [surveyFullList, setSurveyFullList] = useState([]);

  useEffect(() => {
    (async () => {
      const surveyList = await getFullSurveyList(entity);
      setSurveyFullList(surveyList);
    })();
  }, []);

  return (
    <>
      {show && (
        <Modal onClose={onClose} size={{ height: undefined, width: '40%' }} show={show} title={'Réponses manquantes'}>
          <ConformityErrorsSummary>
            <ErrorTitle>
              Certaines des réponses pour <b>{entity?.name}</b> sont manquantes
            </ErrorTitle>
            <Slider>
              <ErrorList>
                {surveyFullList.length > 0 &&
                  surveyFullList
                    ?.filter(survey => survey.fillingPercentage !== 100) //If not created, versionable surveys returns NaN
                    .map((surveyItem, index) => (
                      <ErrorItem key={index}>
                        <SurveyName>{surveyItem.name}</SurveyName>
                        <span>
                          questions obligatoires:{' '}
                          <b>
                            {surveyItem.filledRequiredQuestions} / {surveyItem.requiredQuestions.amount}
                          </b>
                        </span>
                      </ErrorItem>
                    ))}
              </ErrorList>
            </Slider>
            <ErrorTitle>Etes-vous sûr de vouloir soumettre les réponses pour cette entité malgré tout ? </ErrorTitle>
            <ErrorsActions>
              <Action enabled={true} onClick={onClose}>
                <Label>Annuler</Label>
                <FaTimes style={{ color: '#ff7675' }} />
              </Action>
              <Action enabled={true} onClick={onClick}>
                <Label>Valider</Label>
                <FaCheck style={{ color: '#55efc4' }} />
              </Action>
            </ErrorsActions>
          </ConformityErrorsSummary>
        </Modal>
      )}
    </>
  );
};
