import styled, { css } from 'styled-components';
import { BUTTON_COLORS_V2, COLORSV2 } from '@utils/constants';

export const ModalActions = styled.div`
  display: flex;
  column-gap: 20px;
  width: 100%;
  justify-content: space-between;
`;

export const MenuSection = styled.div<{ expand?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  align-items: center;
  justify-content: flex-start;
  ${({ expand }) => expand && 'flex: 1;'}
`;

export const Divider = styled.hr`
  border-bottom: 0.5px solid ${COLORSV2.lightGrey};
  width: 40%;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-bottom: 50px;
`;

export const MenuItem = styled.div<{ selected?: boolean; impersonated?: boolean }>`
  font-size: 1.3rem;
  font-family: Biennale;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  border-radius: 30px;
  background-color: transparent;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  ${({ selected, impersonated }) => css`
    background-color: ${impersonated && selected ? COLORSV2.blue : 'transparent'};
    background-image: ${selected && !impersonated
      ? 'linear-gradient(94deg, rgba(255, 108, 97, 0.20) 17.18%, rgba(255, 74, 128, 0.20) 83.52%)'
      : 'none'};

    &:hover {
      background-color: ${impersonated && !selected ? COLORSV2.blue : 'none'};
      background-image: ${!selected && !impersonated
        ? 'linear-gradient(94deg, rgba(255, 108, 97, 0.20) 17.18%, rgba(255, 74, 128, 0.20) 83.52%)'
        : 'none'};
    }
  `}

  span {
    margin-top: 3px;
  }

  svg {
    width: 24px;
    height: 24px;
    min-width: fit-content;
    color: ${BUTTON_COLORS_V2.deepBlue};

    ${({ selected, impersonated }) =>
      selected &&
      `
      color: ${impersonated ? COLORSV2.textLightGrey : BUTTON_COLORS_V2.pink};
    `}
  }
`;
