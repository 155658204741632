import { Container, FiltersButton } from '@components-new/atoms';
import { t } from 'i18next';
import { ActionContainer, StyledInputComponent } from '../ActionPlan_.style';
import { Button } from '@components/Button';
import { SearchBar } from '@components-new/molecules';
import { IoMdAddCircleOutline } from 'react-icons/io';

const ActionPlanHeader = ({
  getEntitiesOptions,
  getSelectedEntity,
  setSelectedEntity,
  query,
  setQuery,
  handleFiltersClick,
  handleFiltersReset,
  filtersCount,
  selectedEntity,
  view,
  setShowCustomActionModal,
  expandedCustom,
  actionDirectory,
}) => {
  return (
    <>
      <Container flex m='1rem' height='40px' justifyContent='space-between'>
        <Container flex mrChild='2rem'>
          <Container flex width='20rem' justifyContent='space-between'>
            <StyledInputComponent
              placeholder={t('actionPlan.choose_entity')}
              type={'select'}
              options={getEntitiesOptions()}
              onChange={(_, value) => setSelectedEntity(value)}
              sharable={false}
              defaultValue={getSelectedEntity()}
              noOptionsMessage={() => t('actionPlan.no_entity_available')}
            />
          </Container>
          <SearchBar placeholder={t('actionPlan.search_actions')} query={query} setQuery={setQuery} />
          <FiltersButton
            setSearchValue={setQuery}
            onFilterClick={handleFiltersClick}
            onFilterReset={handleFiltersReset}
            filtersCount={filtersCount}
          />
        </Container>
        {selectedEntity && view.value === 'backlog' && (
          <ActionContainer>
            <Button
              onClick={e => {
                e.preventDefault();
                setShowCustomActionModal(true);
              }}
              label={t('actionPlan.add_custom_action')}
              icon={IoMdAddCircleOutline}
            />
          </ActionContainer>
        )}
      </Container>
      {selectedEntity && view.value === 'backlog' && (
        <Container m='1rem' height={expandedCustom && '100%'}>
          {actionDirectory}
        </Container>
      )}
    </>
  );
};

export default ActionPlanHeader;
