import { Modal } from '../modal/Modal';
import {
  ButtonContainer,
  GlobalContainer,
  DescriptionContainer,
  DescriptionHeader,
  DescriptionContent,
  Previous,
  StepsContainer,
  Step,
  StepLabel,
  StyledMdArrowForwardIos,
  EmptyStep,
  EmptyEntity,
} from './ModalRevertMigration_.style';
import { Button } from '@components/Button';
import { InputComponent } from '@components/form/InputComponent';
import { useState } from 'react';
import { PiBriefcaseThin } from 'react-icons/pi';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { selectSelectedAccount } from '../../reducers/account.reducer';

export interface ModalRevertMigrationProps {
  show?: boolean;
  onClose: () => void;
  onRevertMigration: (sourceSurveyId: string, targetSurveyId: string) => void;
  contentfulSurveys: any;
}

export const ModalRevertMigration = ({
                                      show,
                                      onClose,
                                       onRevertMigration,
                                      contentfulSurveys,
                                    }: ModalRevertMigrationProps) => {
  const selectedAccount = useSelector(selectSelectedAccount);
  const [open, setOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [sourceSurvey, setSourceSurvey] = useState(null);
  const [targetSurvey, setTargetSurvey] = useState(null);

  const getSurveysOptions = () => {
    return contentfulSurveys.map(survey => ({ value: survey.id, label: survey.name })) || [];
  };

  const handlePreviousButton = () => {
    if (currentStep === 2) {
      setSourceSurvey(null);
      setTargetSurvey(null);
    }
    if (currentStep === 3) {
      setTargetSurvey(null);
    }
    setCurrentStep(prevState => prevState - 1);
  };

  const handleSourceSurvey = (e, surveyId) => {
    setCurrentStep(2);
    let fullSurvey = contentfulSurveys.find(survey => survey.id === surveyId);
    setSourceSurvey({ id: fullSurvey.id ?? surveyId, name: fullSurvey.name ?? '' });
  };

  const handleTargetSurvey = (e, surveyId) => {
    let fullSurvey = contentfulSurveys.find(survey => survey.id === surveyId);
    setTargetSurvey({ id: fullSurvey.id ?? surveyId, name: fullSurvey.name ?? '' });
  };

  const handleMigrateSurveys = () => {
    if(currentStep === 3) {
      onClose();
    }
    if (currentStep === 2) {
      onRevertMigration(sourceSurvey?.id, targetSurvey?.id);
    }
    setCurrentStep(prevState => prevState + 1);
  };
  const getButtonLabel = () => {
    switch (currentStep) {
      case 1:
        return 'SUIVANT';
      case 2:
        return 'RESTAURER LA MIGRATION';
      case 3:
        return "J'AI COMPRIS";
    }
  };

  const getSourceSurveyErrorMessage = () => {
    if (!sourceSurvey || !targetSurvey) return;
    for (const entity of selectedAccount.entities) {
      let sourceVersions = entity.responses.find(survey => survey.surveyId === sourceSurvey?.id)?.versions;
      if (sourceVersions?.length) {
        for (const version of sourceVersions) {
          if (!version?.versionResponses || _.isEmpty(version?.versionResponses)) {
            return 'Le questionnaire source possède une ou plusieurs version(s) sans réponse';
          }
        }
      } else {
        return 'Une entité possède un questionnaire source sans version (absence de réponse)';
      }
    }
  }

  const isButtonDisabled = !sourceSurvey?.id || !targetSurvey?.id || !!getSourceSurveyErrorMessage()

  return (
    <>
      {show && (
        <Modal onClose={onClose} size={{ height: '75%', width: '40%' }} show={show} title={'Espace restauration de migration :'}>
          <GlobalContainer>
            {currentStep < 3 && (
              <DescriptionContainer>
                <DescriptionHeader onClick={() => setOpen(prevState => !prevState)}>
                  <b>Comment ça marche ?</b>
                  <StyledMdArrowForwardIos open={open} className={'arrow'} />
                </DescriptionHeader>
                <DescriptionContent open={open}>
                  {open && (
                    <>
                      <div>1. Veuillez sélectionner le questionnaire source qui a été migré</div>
                      <div>2. Sélectionnez ensuite le questionnaire cible qui a reçu la migration</div>
                      <div>3. Restaurez la migration (suppression de la cible et réactivation de la source)</div>
                      <div>(n.b : La migration des réponses impactera l'ensemble des entités du compte)</div>
                    </>
                  )}
                </DescriptionContent>
              </DescriptionContainer>
            )}
            <StepsContainer>
              {currentStep < 3 && (
                <Step>
                  <StepLabel>
                    <b>Etape 1/3 :</b> Sélection du questionnaire source (qui a été migré) :
                  </StepLabel>
                  <InputComponent
                    type={'select'}
                    options={getSurveysOptions()}
                    onChange={handleSourceSurvey}
                    sharable={false}
                    value={getSurveysOptions().filter(value => {
                      return value.value === sourceSurvey?.id;
                    })}
                    error={getSourceSurveyErrorMessage()}
                  />
                </Step>
              )}
              {currentStep < 3 && sourceSurvey && (
                <Step>
                  <StepLabel>
                    <b>Etape 2/3 :</b> Sélection du questionnaire cible (qui a reçu la migration) :
                  </StepLabel>
                  {sourceSurvey ? (
                    <InputComponent
                      type={'select'}
                      options={getSurveysOptions()}
                      onChange={handleTargetSurvey}
                      sharable={false}
                      value={getSurveysOptions().filter(value => {
                        return value.value === targetSurvey?.id;
                      })}
                    />
                  ) : (
                    <EmptyEntity>
                      <EmptyStep>
                        <PiBriefcaseThin />
                        <span>Veuillez sélectionner un questionnaire source</span>
                      </EmptyStep>
                    </EmptyEntity>
                  )}
                </Step>
              )}
              {currentStep === 3 && (
                <Step>
                  <StepLabel>
                    <b>Etape 3/3 :</b> Migration restaurée !
                  </StepLabel>
                  <p>Migration restaurée avec succès !</p>
                  <p>
                    Votre questionnaire "{targetSurvey.name}" a bien été supprimé dans l'ensemble des entités."
                  </p>
                  <p>
                    Le questionnaire "{sourceSurvey.name}" a bien été réactivé.
                  </p>
                </Step>
              )}
            </StepsContainer>
            <ButtonContainer>
              {currentStep > 1 && <Previous onClick={handlePreviousButton}>{'PRÉCÉDENT'}</Previous>}
              <Button onClick={handleMigrateSurveys} label={getButtonLabel()} disabled={isButtonDisabled} customColor={'rgba(244, 157, 29, 1)'} />
            </ButtonContainer>
          </GlobalContainer>
        </Modal>
      )}
    </>
  );
};
