
import React from 'react';

type SubmitIconProps = {
  strokeColor?: string;
} & React.SVGProps<SVGSVGElement>;

const SubmitIcon: React.FC<SubmitIconProps> = ({ strokeColor, ...props }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='17' viewBox='0 0 20 17' fill='none' {...props}>
    <path d='M19.2196 8.5L1 16L4.41618 8.5L1 1L19.2196 8.5Z' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4.35645 8.49805H19.2198' stroke={strokeColor} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export default SubmitIcon;
