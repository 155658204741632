import styled from 'styled-components';
import { InputComponent } from '@components/form/InputComponent';

export const StyledInputComponent = styled(InputComponent)`
  width: 100%;
  background: white;
  border-radius: 17px;
  .input input {
    height: 100% !important;
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000070;
`;

// New SubContractor Modal

export const NewSubContractorContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Nunito';
  color: #197ead;
  position: absolute;
  right: calc(3rem + 20px);
  font-weight: 700;
  font-size: 1.4em;
  span {
    margin-left: 10px;
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
`;

export const NewSubContractorTitle = styled.div`
  font-family: 'Nunito';
  font-size: 2em;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
  }
`;

export const Label = styled.div`
  color: #3d3d3d;
  font-family: Nunito;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 13px;
`;

// Common

export const NoData = styled.div`
  height: 100%;
  align-content: center;
  color: #3d3d3d;
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
`;
