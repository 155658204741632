import styled, { css } from 'styled-components';

import { COLORS } from '@utils/constants';

const inputWidth = '100%';

export const FieldContainer = styled.div<{
  last: boolean;
  span: number;
  display: boolean;
  disabled: boolean;
  subQuestion: boolean;
  filled: boolean;
  locked: boolean;
}>`
    display: ${({ display }) => (!display || display ? 'block' : 'none')};
    width: ${({ subQuestion }) => (subQuestion ? 'calc(100% - 50px)' : '100%')};
    ${({ subQuestion }) =>
      subQuestion &&
      css`
        margin-left: 50px;

        &:before {
          content: '';
          position: absolute;
          top: 20px;
          left: -40px;
          bottom: 0;
          width: 20px;
          transform: translateY(-50%);
          border-bottom-left-radius: 10px;
          border-left: 2px solid #c8c8c8;
          border-bottom: 2px solid #c8c8c8;
        }
      `}
    // font-family: 'Baloo 2';
    //     /*grid-column: span ${({ span }) => (span ? span : 2)} / auto;*/
    background-color: transparent;
    position: relative;

    ${({ locked }) =>
      locked &&
      css`
        border-radius: 5px;
        pointer-events: none;
        background-color: ${COLORS.LightGrey};
        cursor: not-allowed;
        padding: 10px;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 5px;
          opacity: 0.5;
        }
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        border-radius: 5px;
        pointer-events: none;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 5px;
          opacity: 0.5;
        }
      `}
    ${({ last }) =>
      last &&
      `
    padding-bottom: 30px;
  `}
    .input {
        display: flex;
        flex-direction: column;
        font-size: 1.4rem;

        label {
        }

        input[type='text'],
        input[type='password'] {
            width: calc(${inputWidth} - 14px);
            height: 40px;
            border: solid 1px hsl(0, 0%, 80%);
            font-family: 'Open Sans';
            border-radius: 4px;
            font-size: 1.3rem;
        }
    }

    > * {
        z-index: 9999;
    }

    ${({ subQuestion, filled }) =>
      subQuestion &&
      !filled &&
      css`
        animation: backInRight 0.3s ease-in-out;
        
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: -50px;
            width: calc(50px + 100%);
            height: 100%;
            padding: 20px 10px;
            border-radius: 5px;
            background-color: #326c8250;
            pointer-events: none;
            transform: translateY(-50%);
            animation: fadeOut 0.5s forwards;
            z-index: 9;

    `}
}
`;
