import styled from 'styled-components';

import { COLORS } from '@utils/constants';

import { RadioTileLabelProps, RadioTileProps, RadioTilesContainerProps } from './RadioTiles.type';

const RadioTileLabel = styled.label<RadioTileLabelProps>`
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${({ disabled, color }) => (disabled ? COLORS.MediumGrey : '#1179a1')};
  padding: 0 0.5rem;
`;

const RadioTile = styled.div<RadioTileProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: ${({ disabled }) => (disabled ? `2px solid ${COLORS.MediumGrey}` : '2px solid #1179a1')};
  border-radius: 5px;
  transition: transform 300ms ease;
  row-gap: 0.75rem;
  ${({ checked, disabled }) =>
    checked &&
    `
    background-color: ${disabled ? COLORS.LightGrey : '#1179a1'};
    border: 2px solid ${disabled ? COLORS.MediumGrey : '#1179a1'};
    color: white;
    transform: scale(1.2, 1.2);

    svg {
      color: ${disabled ? COLORS.DarkGrey : 'white'};
      background-color: ${disabled ? 'transparent' : '#1179a1'};
    }

    ${RadioTileLabel} {
      color: ${disabled ? COLORS.DarkGrey : 'white'};;
      background-color: ${disabled ? 'transparent' : '#1179a1'};
    }
  `}
`;

const RadioButton = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
  z-index: 2;
`;

const RadioTilesContainer = styled.div<RadioTilesContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  height: 11rem;
  width: 11rem;
  margin: 1rem;
  padding: 0.5rem;
  svg {
    color: ${({ disabled, checked }) => (checked ? 'white' : disabled ? COLORS.MediumGrey : '#1179a1')};
    width: 5rem;
    height: 5rem;
  }
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
  `}

  &:hover {
    ${RadioTile} {
      background-color: #1179a1;
      border: 2px solid #1179a1;
      transform: scale(1.2, 1.2);

      svg {
        color: white;
        background-color: ${({ checked, disabled }) => (!checked && !disabled ? '#1179a1' : 'transparent')};
      }

      ${RadioTileLabel} {
        color: white;
        background-color: ${({ checked, disabled }) => (!checked && !disabled ? '#1179a1' : 'transparent')};
      }
    }
  }
`;

export { RadioTilesContainer, RadioTile, RadioTileLabel, RadioButton };
