import styled, { css } from 'styled-components';

import Footer from './Footer';
import Content from './Content';

import { slideInBottom, slideInRight, slideOutBottom, slideOutRight } from './animations';
import { COLORSV2 } from '@utils/constants';

const Drawer = ({ children, header, setOpen, open, additionalComponent = null, width = '33%' }) => {
  return (
    <DrawerContainer className={`${open ? 'open' : ''}`}>
      <Overlay
        onClick={e => {
          e.preventDefault();
          setOpen();
        }}
      />
      <DrawerInner width={width}>
        <DrawerHeader>
          {header}
          {additionalComponent}
        </DrawerHeader>
        {children}
      </DrawerInner>
    </DrawerContainer>
  );
};

Drawer.Content = Content;
Drawer.Footer = Footer;

export default Drawer;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Biennale;
`;

const DrawerInner = styled.div<{ width?: string }>`
  z-index: 3;

  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;

  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;

  @media (min-width: 43rem) {
    display: flex;
    flex-direction: column;

    overflow-y: hidden;
    ${({ width }) =>
      width &&
      css`
        max-width: ${width};
        width: ${width};
      `}
    height: 100vh;

    background: white;
    border-left: 1px solid #dee2e6;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);

    animation-name: ${slideOutRight};
  }

  @media (max-width: 43rem) {
    display: flex;
    flex-direction: column;

    overflow-y: hidden;

    width: 100vw;
    height: auto;
    max-height: 85vh;

    background: $white;
    border-left: 1px solid #dee2e6;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 0.3rem !default;
    border-top-right-radius: 0.3rem !default;

    animation-name: ${slideOutBottom};
  }
`;

const Overlay = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
  transition-delay: 0.3s;
`;

const DrawerContainer = styled.div`
  position: fixed;
  z-index: 20;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: flex-end;
  font-size: 1.5rem;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;

  font-family: Nexa;
  font-weight: 400;
  color: ${COLORSV2.primary};

  @media (min-width: 43rem) {
    flex-direction: column;
  }
  &.open {
    opacity: 1;
    visibility: visible;

    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 0.3s;

    ${DrawerInner} {
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation-duration: 0.3s;

      @media (max-width: 43rem) {
        animation-name: ${slideInBottom};
      }

      @media (min-width: 43rem) {
        animation-name: ${slideInRight};
      }
    }

    ${Overlay} {
      opacity: 1;
      visibility: visible;
    }
  }
`;
