import { useMemo, useState } from 'react';
import _ from 'lodash';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImFilesEmpty } from 'react-icons/im';
import { IoMdAddCircleOutline } from 'react-icons/io';

import { selectAccount, selectActiveAccount, selectImpersonated, selectUser } from '../../reducers/account.reducer';

import {
  selectCurrentEntity,
  selectCurrentSurvey,
  selectLoading,
  setCurrentStep,
  setCurrentSurvey,
  setCurrentVersion,
  setModal,
  setShareModal,
} from '../../reducers/app.reducer';
import { selectAllContentfulData } from '../../reducers/contentful.reducer';
import { paths } from '@routes/routes.constants';
import { SurveyCard } from '../survey-card/SurveyCard';

import {
  getAuthorizedSurveys,
  getDetailedSurveyById,
  filterSharedVersions,
  filterSharedSteps,
  addEventTracking,
  handleConditions,
  isSurveyDisabled,
} from '../../utils/utils';
import { ACCOUNT_TYPES, COLORS, COLORSV2, MODAL_TYPES, STATUS_COLORS } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import {
  Empty,
  ContentContainer,
  TabContainer,
  MuiTab,
  ContentTitle,
  DashboardEntityContainer,
  DashboardEntityTop,
  DashboardContent,
  ButtonContainer,
} from '@components/dashboard-entity/DashboardEntity_.styles';
import { Tabs } from '@mui/material';
import { Button } from '../Button';

export interface DashboardEntityProps {}

const DashboardEntity = () => {
  const { t } = useTranslation();
  const account = useSelector(selectAccount);
  const impersonated = useSelector(selectImpersonated);
  const currentEntity = useSelector(selectCurrentEntity);
  const currentSurvey = useSelector(selectCurrentSurvey);
  const contentfulData = useSelector(selectAllContentfulData);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const activeAccount = useSelector(selectActiveAccount);
  const currentUser = useSelector(selectUser);

  const [tabValue, setTabValue] = useState(0);

  const authorizedSurveys = getAuthorizedSurveys();

  const versionableSurveys = authorizedSurveys?.filter(survey => survey.versioning.enabled);

  const handleShowSurveyModal = async (version, option, surveyId) => {
    let fullSurvey = await getDetailedSurveyById(surveyId);
    dispatch(setCurrentSurvey(fullSurvey));
    dispatch(setCurrentVersion(version));
    switch (option) {
      case 'duplicate':
        dispatch(setModal({ show: true, type: MODAL_TYPES.DUPLICATE_PRODUCT }));
        break;
      case 'rename':
        dispatch(setModal({ show: true, type: MODAL_TYPES.RENAME_PRODUCT }));
        break;
      case 'delete':
        dispatch(setModal({ show: true, type: MODAL_TYPES.DELETE_PRODUCT }));
        break;
      default:
        break;
    }
  };

  const getChipsLabel = (version, surveyId) => {
    let { completedQuestions, completionPercentage } = version?.completion;
    if (isSurveyDisabled(surveyId, false)) {
      return { backgroundColor: COLORS.softRed, color: COLORS.white, label: t('common.disabled') };
    }
    if (completionPercentage === 100) {
      return { backgroundColor: STATUS_COLORS.backgroundSuccess, color: STATUS_COLORS.success, label: t('common.done') };
    }
    if (completedQuestions === 0) {
      return { backgroundColor: STATUS_COLORS.backgroundNotStarted, color: STATUS_COLORS.notStarted, label: t('common.toFill') };
    }
    if (completedQuestions > 0 && completionPercentage !== 100) {
      return { backgroundColor: STATUS_COLORS.backgroundOngoing, color: STATUS_COLORS.ongoing, label: t('common.inProgress') };
    }
  };

  const getSurveyCardProps = (version, surveyId) => {
    let survey = contentfulData?.surveys?.find(survey => survey.id === surveyId);

    return {
      versionable: survey.versioning.enabled,
      title: version?.versionName,
      label: `${version?.themes?.filled}/${version?.themes?.amount} ${t('dashboardEntity.completedSteps')}`,
      surveyName: survey.name,
      chipsLabel: getChipsLabel(version, surveyId),
      percentage: Math.round(version?.completion?.completionPercentage),
      button: {
        label: t('dashboardEntity.goToForm'),
        onClick: async () => {
          //enhance surveys for shared item
          //because of getLinkedQuestions check on StepQuestions
          if (currentUser.role === 'guest') {
            currentUser.sharedItems.questions.forEach(sharedItem => getDetailedSurveyById(sharedItem.surveyId));
          }
          let fullSurvey = await getDetailedSurveyById(surveyId);
          let sharedSteps = filterSharedSteps(fullSurvey.steps)?.filter(targetStep => handleConditions(targetStep, version));
          dispatch(setCurrentSurvey(fullSurvey));
          dispatch(setCurrentVersion(version));
          dispatch(setCurrentStep({ id: sharedSteps?.[0].id, name: sharedSteps?.[0].name }));
          history.push(paths.survey);
        },
      },
      onOptionsClick: {
        share: () => shareItem(version, survey.id),
        duplicate: async () => await handleShowSurveyModal(version, 'duplicate', survey.id),
        rename: async () => await handleShowSurveyModal(version, 'rename', survey.id),
        delete: async () => await handleShowSurveyModal(version, 'delete', survey.id),
      },
      disabled: version.disabled,
    };
  };

  const shareItem = (version, targetSurveyId) => {
    dispatch(setCurrentVersion(version));
    dispatch(
      setShareModal({
        targetSurveyId: targetSurveyId,
        type: 'surveyVersions',
        id: version._id,
        label: version.versionName,
      })
    );
    dispatch(setModal({ show: true, type: MODAL_TYPES.SHARE }));
  };

  const handleSubmitEntity = async () => {
    // if (currentEntity.completionPercentage === 100) {
    addEventTracking('Entity', 'Submit entity', 'Via dashboard entity');
    dispatch(setModal({ show: true, type: MODAL_TYPES.SUBMIT_ENTITY_CONFIRMATION }));
    // } else {
    // dispatch(setModal({ show: true, type: MODAL_TYPES.SUBMIT_ENTITY_ERRORS }));
    // }
  };

  const isVersionForSurvey = (targetSurvey, group?) => {
    if (group) {
      let responses = [];
      versionableSurveys?.map(surveyItem => {
        return responses.push(
          (impersonated ?? account)?.entities
            .find(entityItem => entityItem.name === currentEntity.name)
            ?.responses.find(survey => survey.surveyId === surveyItem.id)
        );
      });
      return responses.every(response => !response?.versions?.length);
    } else {
      return (impersonated ?? account)?.entities
        .find(entityItem => entityItem.name === currentEntity.name)
        ?.responses.find(survey => survey.surveyId === currentSurvey.id)?.length;
    }
  };

  const getSurveyCards = (products: boolean = false) => {
    if (products) {
      let responses = [];
      versionableSurveys
        ?.filter(survey => isSurveyVisible(survey.id))
        .map(surveyItem => {
          return responses.push(
            (impersonated ?? account)?.entities
              .find(entityItem => entityItem.name === currentEntity.name)
              ?.responses.find(survey => survey.surveyId === surveyItem.id)
          );
        });
      let isAllEmpty = responses.every(response => !response?.versions?.length);
      if (!responses.length || isAllEmpty) {
        return (
          <Empty>
            <span>{t('dashboardEntity.noServices')}</span>
            <Button type='text' onClick={displayNewProductModal} label={t('dashboardEntity.addService')} icon={IoMdAddCircleOutline} />
          </Empty>
        );
      }

      let cardProps = responses.map(element => {
        return filterSharedVersions(_.cloneDeep(element)?.versions)
          ?.sort((a, b) => {
            return new Date(a.creationDate) > new Date(b.creationDate) ? 1 : -1;
          })
          ?.map((version, index) => <SurveyCard key={index} {...getSurveyCardProps(version, element.surveyId)} />);
      });

      return cardProps;
    } else {
      let responses = activeAccount?.entities
        .find(entityItem => entityItem.name === currentEntity.name)
        ?.responses.find(survey => survey.surveyId === currentSurvey.id);

      if (!responses?.versions?.length) {
        return (
          <Empty>
            <ImFilesEmpty />
            <span>{t('dashboardEntity.noServices')}</span>
          </Empty>
        );
      }

      let sharedVersionsCards = filterSharedVersions(responses.versions)?.map((version, index) => {
        return <SurveyCard key={index} {...getSurveyCardProps(version, currentSurvey.id)} />;
      });

      return sharedVersionsCards;
    }
  };

  const displayNewProductModal = () => {
    dispatch(setModal({ show: true, type: MODAL_TYPES.NEW_PRODUCT }));
  };

  const isSubmissionDisabled: boolean = useMemo(() => {
    let entity = activeAccount?.entities?.find(entityItem => entityItem.name === currentEntity?.name);
    let submission = entity?.submission;
    let isAuthorized =
      currentUser?.role !== 'guest' ||
      currentUser?.sharedItems?.entities?.includes(sharedEntity => sharedEntity.entityId === currentEntity?._id && sharedEntity.shareTarget);
    return !isAuthorized || submission?.submitted || submission?.validated;
  }, [account, currentUser]);

  if (!currentEntity || !currentSurvey) {
    return <Redirect to={paths.dashboardGroup} />;
  }

  const isSurveyVisible = surveyId => {
    return account.accountType === ACCOUNT_TYPES.ADMIN || !isSurveyDisabled(surveyId, false);
  };

  return (
    <>
      {!loading && currentEntity && contentfulData && currentSurvey && (
        <DashboardEntityContainer>
          <DashboardEntityTop>
            <ContentTitle>
              <div>
                <span>{(impersonated ?? account).companyName}</span>
                <span>-</span>
                <span>{currentEntity.name}</span>
              </div>
              <span>{t('dashboardEntity.mySurveys')}</span>
            </ContentTitle>
            <ButtonContainer>
              {!isVersionForSurvey(currentSurvey, currentSurvey.versioning.enabled) && currentSurvey.versioning.enabled && (
                <Button type='text' onClick={displayNewProductModal} label={t('dashboardEntity.addService')} icon={IoMdAddCircleOutline} />
              )}
              <Button type='primary' onClick={handleSubmitEntity} label={t('dashboardEntity.submitEntity')} disabled={isSubmissionDisabled} />
            </ButtonContainer>

            <TabContainer>
              <Tabs
                value={tabValue}
                onChange={(e, value) => setTabValue(value)}
                TabIndicatorProps={{ style: { backgroundColor: COLORSV2.lightOrange } }}>
                {authorizedSurveys
                  ?.filter(survey => !survey.versioning.enabled)
                  .map((survey, index) => {
                    return (
                      isSurveyVisible(survey.id) && (
                        <MuiTab
                          selected={survey.id === currentSurvey?.id}
                          onClick={() => dispatch(setCurrentSurvey(survey))}
                          key={index}
                          disabled={isSurveyDisabled(survey.id, false)}
                          label={survey?.name}
                        />
                      )
                    );
                  })}
                {versionableSurveys.length > 0 && (
                  <MuiTab
                    selected={versionableSurveys.some(survey => survey.id === currentSurvey?.id)}
                    onClick={() => dispatch(setCurrentSurvey(versionableSurveys[0]))}
                    disabled={false}
                    label={t('dashboardEntity.services')}
                  />
                )}
              </Tabs>
            </TabContainer>
          </DashboardEntityTop>
          <DashboardContent>
            <ContentContainer empty={isVersionForSurvey(currentSurvey, currentSurvey.versioning.enabled)}>
              {getSurveyCards(currentSurvey.versioning.enabled)}
            </ContentContainer>
          </DashboardContent>
        </DashboardEntityContainer>
      )}
    </>
  );
};

export default DashboardEntity;
