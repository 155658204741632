import { t } from 'i18next';

//
// Selects Options mapper
//

export const urgencyComplexity = [
  {
    value: 'low',
    label: t('common.low'),
  },
  {
    value: 'medium',
    label: t('common.medium'),
  },
  {
    value: 'high',
    label: t('common.high'),
  },
];
export const status = [
  {
    value: 'pending',
    label: t('common.status_to_handle'),
  },
  {
    value: 'ongoing',
    label: t('common.status_ongoing'),
  },
  {
    value: 'completed',
    label: t('common.status_completed'),
  },
];
export const category = [
  {
    value: 'Marketing',
    label: t('common.Marketing'),
  },
  {
    value: 'RH',
    label: t('common.RH'),
  },
  {
    value: 'Tech',
    label: t('common.Tech'),
  },
  {
    value: 'Audit',
    label: t('common.Audit'),
  },
];

export const subCategory = [
  {
    value: 'Plateform',
    label: t('common.plateform'),
  },
  {
    value: 'Mobile app',
    label: t('common.mobile_app'),
  },
  {
    value: 'WebSite',
    label: t('common.website'),
  },
  {
    value: 'HR and safety',
    label: t('common.hr_safety'),
  },
  {
    value: 'Marketing',
    label: t('common.Marketing'),
  },
  {
    value: 'Health',
    label: t('common.health'),
  },
  {
    value: 'AI',
    label: t('common.ai'),
  },
  {
    value: 'E-commerce',
    label: t('common.e_commerce'),
  },
  {
    value: 'Training / consulting',
    label: t('common.training_consulting'),
  },
  {
    value: 'Public authorities / Establishments',
    label: t('common.public_authorities_establishments'),
  },
];

export const customActionInitialState = {
  title: '',
  description: '',
  complexity: '',
  urgency: '',
  category: '',
  explanation: '',
  precision: '',
  conditions: [],
  custom: true,
  isReady: false,
  proof: {},
};

export const filtersInitialState = { urgency: [], status: [], category: [], product: [], complexity: [] };
