import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  //margin-top: 60px;
`;

export const PreviousButton = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const NextButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;
