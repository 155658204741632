import styled from 'styled-components';
import { COLORS, COLORSV2, STATUS_COLORS } from '@utils/constants';

const DashboardGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 80px);
  height: 100vh;

  font-family: Biennale;
`;

const DashboardTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${COLORSV2.lightPink};
  padding: 30px 50px;
  height: 25%;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const Title = styled.div`
  font-family: 'Biennale SemiBold';
  font-size: 30px;
  display: flex;
  align-items: center;
  column-gap: 20px;
  color: ${COLORSV2.primary};

  svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
`;

const Description = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: ${COLORS.Black};
  width: 50vw;

  b {
    color: ${STATUS_COLORS.ongoing};
  }
`;

const DashboardContent = styled.div`
  flex: 1;
  row-gap: 50px;
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

const Counters = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
`;

const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  overflow-y: auto;

  p {
    font-size: 1.3em;
    font-weight: 400;
    line-height: 1.5em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export { DashboardGroupContainer, DashboardTop, Left, Title, Description, DashboardContent, Counters, BasicModalContainer, ButtonContainer };
