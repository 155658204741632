import styled from 'styled-components';
import Div from 'src/components/div/Div';

import { CgBlock } from 'react-icons/cg';
import { IoMdAddCircleOutline } from 'react-icons/io';

const WeekDaysList = ({ unavailabilityDays, setUnavailabilityDays }) => {
  const daysOfWeek = [
    { name: 'lundi', index: 1 },
    { name: 'mardi', index: 2 },
    { name: 'mercredi', index: 3 },
    { name: 'jeudi', index: 4 },
    { name: 'vendredi', index: 5 },
  ];

  const handleUnavailabilitiesChange = (e, dayIndex) => {
    const { name, value } = e.target;
    setUnavailabilityDays(prevState => {
      const updatedDays = [...prevState];
      updatedDays[dayIndex - 1] = {
        ...updatedDays[dayIndex - 1],
        [name]: value,
      };
      return updatedDays;
    });
  };

  const handleUnavailableAllDay = (dayIndex, type) => {
    setUnavailabilityDays(prevState => {
      const updatedDays = [...prevState];
      updatedDays[dayIndex - 1] = {
        ...updatedDays[dayIndex - 1],
        allDay: type === 'block' ? true : false,
      };
      return updatedDays;
    });
  };

  return (
    <>
      {daysOfWeek.map((day, index) => (
        <Div key={index} mt='1rem' mb='1rem' alignItems='center' style={{ display: 'grid' }} columnTemplate='10% 45% 10%'>
          <span style={{ width: '3rem' }}>{day.name.toLocaleUpperCase().slice(0, 3)}</span>
          <Div alignItems='center'>
            {unavailabilityDays?.find(elt => elt.day === day.index)?.allDay ? (
              <span style={{ fontSize: ' 1em', fontWeight: 'bold' }}>Non Disponible toute la journée</span>
            ) : (
              <>
                <TimeInput
                  type='time'
                  name='startTime'
                  value={unavailabilityDays?.find(elt => elt.day === day.index)?.startTime}
                  onChange={e => handleUnavailabilitiesChange(e, day.index)}
                />
                <span> - </span>
                <TimeInput
                  type='time'
                  name='endTime'
                  value={unavailabilityDays?.find(elt => elt.day === day.index)?.endTime}
                  onChange={e => handleUnavailabilitiesChange(e, day.index)}
                />
              </>
            )}
          </Div>
          {unavailabilityDays?.find(elt => elt.day === day.index)?.allDay ? (
            <IoMdAddCircleOutline
              size='23'
              style={{ marginLeft: '1.2rem', cursor: 'pointer' }}
              onClick={() => handleUnavailableAllDay(day.index, 'add')}
            />
          ) : (
            <CgBlock size='26' style={{ marginLeft: '1rem', cursor: 'pointer' }} onClick={() => handleUnavailableAllDay(day.index, 'block')} />
          )}
        </Div>
      ))}
    </>
  );
};

const TimeInput = styled.input`
  width: 8rem;
`;

export default WeekDaysList;
