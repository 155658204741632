import { useDispatch, useSelector } from 'react-redux';
import { selectActiveAccount } from '../../reducers/account.reducer';
import {
  selectCurrentEntity,
  selectCurrentSurvey,
  selectSidenavState,
  setCurrentEntity,
  setCurrentSurvey,
  setCurrentVersion,
  setModal,
  setSidenavState,
} from '../../reducers/app.reducer';
import { AiOutlineFolder, AiOutlineFolderOpen, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import { RiShareForwardLine } from 'react-icons/ri';
import { GoChevronRight } from 'react-icons/go';
import { paths } from '@routes/routes.constants';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { filterSharedEntities, getAuthorizedSurveysByEntity } from '../../utils/utils';
import { UserList } from '../user-list/UserList';
import { tippy } from '@tippyjs/react';
import { CollaboratorsList } from '../collaborators-list/CollaboratorsList';
import { Infos } from '../infos/Infos';
import { DashboardCreateUser } from '../dashboard-create-account/DashboardCreateAccount';
import { ServicesSubListMenu } from '../servicesSubListMenu/servicesSubListMenu';
import {
  TopContainer,
  SearchContainer,
  ItemList,
  SubItemWrapper,
  SubMenuContainer,
  Title,
  MenuItem,
  SubItemList,
  SubItem,
  Entity,
} from './SubMenu_.style';
import { useTranslation } from 'react-i18next';

export const SubMenu = () => {
  const { t } = useTranslation();
  const preventDefault = e => e.preventDefault();
  const sidenavState = useSelector(selectSidenavState);
  const currentEntity = useSelector(selectCurrentEntity);
  const currentSurvey = useSelector(selectCurrentSurvey);
  const [search, setSearch] = useState('');
  const itemsRef = useRef(null);
  const addIconRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const [selectedEntity, setSelectedEntity] = useState(null);
  const activeAccount = useSelector(selectActiveAccount);

  const [isServicesSelected, setIsServicesSelected] = useState({ selected: false, entityId: null });

  const getMap = () => (!itemsRef.current ? (itemsRef.current = new Map()) : itemsRef.current);

  const getChildrenHeight = entity => {
    const map = getMap();
    const node = map.get(entity?.name);
    return node?.clientHeight;
  };

  const handleEntityClick = entity => {
    if (selectedEntity === entity) {
      setSelectedEntity(null);
    } else {
      setSelectedEntity(entity);
    }
  };

  const handleSurveyClick = survey => {
    dispatch(setCurrentEntity(selectedEntity));
    dispatch(setCurrentSurvey(survey));
    dispatch(setCurrentVersion(null));
    closeSidenav();
    dispatch(setModal({ show: false, type: '' }));
    history.push(paths.dashboardEntity);
  };

  const closeSidenav = () => {
    dispatch(setSidenavState(false));
    setSelectedEntity(null);
    setSearch(null);
    setIsServicesSelected({ selected: false, entityId: null });
  };

  const isSelectedEntity = entity => {
    if (selectedEntity) {
      return selectedEntity?.name === entity?.name;
    } else {
      if (currentEntity?.name === entity?.name) {
        setSelectedEntity(entity);
        return true;
      }
      return false;
    }
  };

  const isSelectedSurvey = survey => {
    if (selectedEntity?.name === currentEntity?.name) {
      if (currentSurvey?.name === survey?.name) {
        return true;
      }
    }
    return false;
  };

  const handleSearchChange = e => {
    const value = e.target.value;
    setSearch(value === '' ? null : value);
  };

  const isSearchedString = () => search !== null && search !== '';

  const getFilteredEntities = () => {
    const sharedEntities = filterSharedEntities(activeAccount.entities);

    return !isSearchedString()
      ? sharedEntities
      : sharedEntities?.filter(entity =>
          entity?.name
            ?.toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            ?.includes(
              search
                ?.toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            )
        );
  };

  const handleSubItemListRef = (entity, node) => {
    const map = getMap();
    if (node) {
      map.set(entity.name, node);
    } else {
      map.delete(entity.name);
    }
  };

  const handleAddAccount = () => {
    dispatch(
      setSidenavState({
        open: true,
        type: 'addAccount',
        level: 2,
      })
    );
  };

  const hasActiveProducts = entity => {
    return !!entity?.responses?.filter(
      response =>
        response?.versions?.length &&
        getAuthorizedSurveysByEntity(entity)
          ?.filter(survey => survey.versioning.enabled)
          ?.some(survey => survey.id === response?.surveyId)
    ).length;
  };

  const handleEntityProducts = (entity, survey?) => {
    if (hasActiveProducts(entity)) {
      dispatch(
        setSidenavState({
          open: true,
          type: 'entityProducts',
          level: 2,
        })
      );
    } else {
      handleSurveyClick(survey);
    }
  };

  useEffect(() => {
    if (!sidenavState) {
      setSelectedEntity(null);
    }
    tippy(addIconRef.current, {
      content: t('subMenu.addClient'),
      arrow: true,
      placement: 'right',
    });
  }, [sidenavState]);

  return (
    <>
      <SubMenuContainer level={0} open={(sidenavState?.open && sidenavState?.type === 'entities') || sidenavState?.type === 'entityProducts'}>
        <>
          <MdClose onClick={closeSidenav} />
          <Title>{t('common.myEntities')}</Title>
          <SearchContainer>
            <input onChange={handleSearchChange} placeholder={`${t('subMenu.searchEntity')}`} type='text' />
          </SearchContainer>
          <ItemList>
            {getFilteredEntities()?.map((entity, index) => (
              <MenuItem key={index} onMouseOver={preventDefault} onMouseOut={preventDefault}>
                <Entity selected={isSelectedEntity(entity)} onClick={() => handleEntityClick(entity)}>
                  {isSelectedEntity(entity) ? <AiOutlineFolderOpen /> : <AiOutlineFolder />}
                  {sidenavState && <span>{entity?.name}</span>}
                  {isSelectedEntity(entity) ? <AiOutlineMinus /> : <AiOutlinePlus />}
                </Entity>
                <SubItemWrapper
                  menuOpen={sidenavState}
                  open={isSelectedEntity(entity)}
                  childHeight={entity ? getChildrenHeight(entity) : null}
                  style={{ width: '100%' }}>
                  <SubItemList ref={node => handleSubItemListRef(entity, node)}>
                    {entity &&
                      getAuthorizedSurveysByEntity(entity)
                        ?.filter(survey => !survey.versioning.enabled)
                        .map((survey, index) => (
                          <SubItem selected={isSelectedSurvey(survey)} key={`version_${index}`} onClick={() => handleSurveyClick(survey)}>
                            <span>{survey?.name}</span>
                          </SubItem>
                        ))}
                    {entity && getAuthorizedSurveysByEntity(entity)?.filter(survey => survey.versioning.enabled)?.length > 0 && (
                      <SubItem
                        style={{ justifyContent: 'space-between' }}
                        selected={isServicesSelected?.selected && isServicesSelected?.entityId === entity?._id}
                        onClick={() => {
                          setIsServicesSelected({ selected: true, entityId: entity?._id });
                          handleEntityProducts(entity, getAuthorizedSurveysByEntity(entity)?.filter(survey => survey.versioning.enabled)[0]);
                        }}>
                        <span>{t('common.services')}</span>
                        {hasActiveProducts(entity) && <GoChevronRight />}
                      </SubItem>
                    )}
                  </SubItemList>
                </SubItemWrapper>
              </MenuItem>
            ))}
          </ItemList>
        </>
      </SubMenuContainer>
      <SubMenuContainer
        level={1}
        open={sidenavState?.open && sidenavState?.type === 'entityProducts' && hasActiveProducts(selectedEntity)}
        style={{ width: '330px' }}>
        <ServicesSubListMenu selectedEntity={selectedEntity} setSelectedEntity={setSelectedEntity} closeSidenav={closeSidenav} />
      </SubMenuContainer>
      <SubMenuContainer level={0} open={sidenavState?.open && sidenavState?.type === 'collaborators'}>
        <TopContainer>
          <Title>{t('common.collaborators')}</Title>
        </TopContainer>
        <CollaboratorsList />
        {sidenavState?.open && sidenavState?.type === 'collaborators' && (
          <Infos
            icon={RiShareForwardLine}
            text={t('subMenu.collaborators', {
              companyName: activeAccount?.companyName,
            })}
          />
        )}
      </SubMenuContainer>
      <SubMenuContainer level={0} open={sidenavState?.open && (sidenavState?.type === 'admin' || sidenavState?.type === 'addAccount')}>
        <TopContainer>
          <Title>{t('common.users')}</Title>
          <span ref={addIconRef} onClick={handleAddAccount}>
            <IoMdAddCircleOutline />
          </span>
        </TopContainer>
        <UserList />
      </SubMenuContainer>
      <SubMenuContainer level={1} open={sidenavState?.open && sidenavState?.type === 'addAccount'}>
        <DashboardCreateUser open={sidenavState?.open} />
      </SubMenuContainer>
    </>
  );
};
