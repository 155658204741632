import { Aside, Paragraph, SideBarItems, SidebarLink } from './Sidebar.styles';

interface SidebarProps {
  items: { label: string; value: string; icon?: React.ReactElement; component: JSX.Element }[];
  activeItem: {
    label: string;
    value: string;
    icon?: JSX.Element;
    component: JSX.Element;
  };
  setActiveItem: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
      icon?: JSX.Element;
      component: JSX.Element;
    }>
  >;
  sideBarTitle: string;
}
const Sidebar: React.FC<SidebarProps> = ({ items, activeItem, setActiveItem, sideBarTitle }) => {
  return (
    <Aside>
      <Paragraph id='sidebar-title'>{sideBarTitle}</Paragraph>

      <SideBarItems id='sidebar-items'>
        {items.map(item => {
          return (
            <SidebarLink key={item.value} onClick={() => setActiveItem(item)} active={item.value === activeItem.value}>
              {item?.icon}
              {item.label}
            </SidebarLink>
          );
        })}
      </SideBarItems>
    </Aside>
  );
};

export default Sidebar;
