import styled from 'styled-components';
import { Container } from '@components-new/atoms';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1.5rem;
`;

export const Chips = styled.div`
  display: inline-flex;
  border-radius: 12px;
  height: 30px;
  font-size: 14px;
  padding: 0 4px 0 0.5rem;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  border: 1px #c4c3c2 solid;
  gap: 7px;
`;

export const ChipsButton = styled.div`
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 22px;
`;

export const UserRow = styled.div<{ checked?: boolean; alreadyShared: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  box-sizing: border-box;

  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  column-gap: 20px;

  width: 100%;
  max-width: 100%;

  background-color: ${({ alreadyShared, checked }) => (alreadyShared ? 'rgba(0, 198, 161, 0.1)' : checked ? '#C8E3F64D' : 'white')};
  transition: background-color 0.2s ease-in-out;
  cursor: ${({ checked }) => !checked && 'pointer'};
  pointer-events: ${({ alreadyShared }) => (alreadyShared ? 'none' : 'auto')};

  &:hover {
    background-color: ${({ checked }) => !checked && '#f3f3f3'};
  }

  span[data-element='accountOfficer'] {
    font-size: 12px;
    color: #757575;
  }
  span[data-element='sharedElement'] {
    font-size: 1.125rem;
    color: #00c6a1;
  }

  padding: 5px 10px;
  gap: 7px;
  border-radius: 4px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 20px;
  font-family: 'Roboto';
`;

export const ModalDescription = styled.span`
  font-size: 16px;
  margin-bottom: 20px;
`;

export const Span = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

// Comment Input Components
export const CommentInputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 8px 16px;
  width: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

export const StyledInput = styled.input`
  flex-grow: 1;
  border: none;
  background: transparent;
  font-size: 14px;
  color: #65676b;
  outline: none;
  &::placeholder {
    color: #65676b;
  }
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const AlreadySharedContainer = styled(Container)`
  box-sizing: border-box;
  max-height: 192px;
  overflow-y: auto;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  & > :not(:last-child) {
    margin-bottom: 10px;
  }
  @media (max-height: 800px) {
    max-height: 142px;
  }
  @media (max-height: 700px) {
    max-height: 100px;
  }
  @media (max-height: 600px) {
    max-height: 58px;
  }
`;

export const AlreadySharedTitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #3d3d3d;
`;
