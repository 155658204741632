import styled from 'styled-components';
import { COLORSV2 } from '../../utils/constants';

interface ProgressBarProps {
  level: number;
  max: number;
  label?: string;
}
export const ProgressBar = ({ level, max, label }: ProgressBarProps) => {
  return (
    <GlobalContainer>
      <Title>
        <span>
          {level}/{max} {label}
        </span>
      </Title>
      <ProgressBarMeter level={level} max={max} />
    </GlobalContainer>
  );
};

const GlobalContainer = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-size: 1.33rem;
  color: ${COLORSV2.lightOrange};

  span {
    font-weight: 600;
  }
`;

const ProgressBarMeter = styled.div<{ level: number; max: number }>`
  height: 0.5rem;
  background-color: ${COLORSV2.deepPink};
  border-radius: 35px;
  margin: 0.5rem 0;

  &:before {
    content: '';
    background-color: ${COLORSV2.lightOrange};
    height: 100%;
    width: ${({ level, max }) => (level / max) * 100}%;
    display: block;
    border-radius: 35px;
    transition: width 0.2s;
  }
`;
