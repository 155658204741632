import { COLORSV2, STATUS_COLORS, Z_INDEX } from '../../utils/constants';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  position: relative;
  display: inline-block;
  svg {
    width: 50px;
    height: 50px;
  }
`;

export const NotificationIconButton = styled.button<{ showNotification?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 50%;
  background-color: transparent;
  &:hover {
    cursor: pointer;
    background-color: #e4e4e4;
    transition: 0.2s;
  }

  background-color: ${({ showNotification }) => (showNotification ? '#e4e4e48a' : 'transparent')};
`;

export const NotificationBadge = styled.span`
  position: absolute;
  top: -2px;
  right: 0px;
  width: 20px;
  height: 20px;
  background: #326c82;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 10000;

  p {
    color: #ffffff;
    margin: 0;
    font-weight: 500;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  z-index: ${Z_INDEX.modal};
  border: 1px solid rgba(0, 0, 0, 0.2);
  min-height: 0;
  max-height: 485px;
`;

export const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: calc(100% - 110px);
  height: calc(100% - 110px);
  font-family: 'Roboto';
  font-weight: 400;

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 2.1px -1px 12px 0px #f0f0f0;
  }
`;
export const NotificationItemContainer = styled.div``;

export const Separator = styled.div`
  border: 1px solid rgba(196, 196, 196, 0.37);
`;

export const NotificationContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 25px;
  width: 100%;
`;

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 10px;
`;

export const SmallRound = styled.div`
  width: 7px;
  height: 7px;
  background-color: rgba(244, 157, 29, 1);
  border-radius: 50%;
`;

export const NotificationContent = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  flex: 1;
  width: 90%;
`;

export const NotificationTitle = styled.div`
  span {
    color: ${COLORSV2.primary};
    font-family: 'Nexa Bold';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
  }
  strong {
    color: #000000c4;
  }
`;
export const NotificationLabel = styled.div`
  word-wrap: break-word;
  width: 100%;
  p {
    color: ${COLORSV2.textLightGrey};
    font-family: 'Nexa Regular';
    font-size: 11.5px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
  }
  strong {
    color: #000000c4;
  }
`;

export const NotificationDate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  span {
    color: ${STATUS_COLORS.ongoing};
    font-family: 'Nexa Regular';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }
`;
export const Slider = styled.div`
  flex: 1;
  min-height: 0;
  overflow-y: scroll;
  max-height: 400px;
`;

export const AllNotificationContainer = styled.div`
  display: flex;
  align-items: center;

  h3 {
    font-family: Biennale;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: ${STATUS_COLORS.ongoing};
  }
`;

export const MarkAsReadContainer = styled.h3`
  display: flex;
  justify-content: end;
  color: #326c82;
`;

export const NotificationsContainer = styled.div`
  dispaly: flex;
  align-self: center;
`;

export const EmptyNotificationContainer = styled.div`
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderNotificationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const AllReadContainer = styled.div`
  color: ${STATUS_COLORS.notStarted};
  font-family: 'Nexa Bold';
  font-size: 11.5px;
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
  margin: 0;
  cursor: pointer;
`;
