import styled from 'styled-components';
import { IconType } from 'react-icons';

export interface ChipsProps {
  label: string;
  icon?: IconType;
  color?: string;
  backgroundColor?: string;
  forwardedRef?: React.Ref<HTMLDivElement> | null;
}

export const Chips = ({ label, icon: Icon, color, backgroundColor, forwardedRef }: ChipsProps) => {
  return (
    <ChipsContainer color={color} backgroundColor={backgroundColor} ref={forwardedRef}>
      {Icon && <Icon />}
      {label}
    </ChipsContainer>
  );
};

const ChipsContainer = styled.div<{ color: string; backgroundColor: string }>`
  padding: 6px 12px;
  background-color: ${({ backgroundColor }) => backgroundColor};

  color: ${({ color }) => color};
  white-space: nowrap;
  border-radius: 25px;
  font-family: 'Roboto', serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  column-gap: 5px;

  svg {
    width: 20px;
    height: 20px;
  }
`;
