import { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { HiOutlineInformationCircle } from 'react-icons/hi2';
import { ButtonContainer, CloseButtonWrapper, InfoButtonStyled, PopupContainer, PopupContent, PopupHeader, PopupItem, PopupTitle, StyledCloseIcon } from './InfoButton_.style';
import { PopupPortalProps, InfoButtonProps } from './InfoButton.type';

// The portal popup component
const PopupPortal = ({ isOpen, onClose, items, title, buttonRef, noDataMessage }: PopupPortalProps) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  // Handle positioning
  useEffect(() => {
    if (isOpen && buttonRef.current && popupRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();

      // Position to the right of the button
      setPosition({
        top: buttonRect.top,
        left: buttonRect.right + 10 // 10px gap between button and popup
      });
    }
  }, [isOpen, buttonRef]);

  // Close when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose, buttonRef]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <PopupContainer
      ref={popupRef}
      data-testid="info-popup"
      style={{
        position: 'fixed',
        top: `${position.top}px`,
        left: `${position.left}px`
      }}
    >
      <PopupHeader data-testid="popup-header">
        <PopupTitle data-testid="popup-title">{title}</PopupTitle>
        <CloseButtonWrapper onClick={onClose} aria-label="Close" data-testid="close-button">
          <StyledCloseIcon data-testid="close-icon" />
        </CloseButtonWrapper>
      </PopupHeader>

      <PopupContent data-testid="popup-content">
        {items.length > 0 ? items.map((item, index) => (
          <PopupItem key={index} data-testid={`popup-item-${index}`}>
            {item}
          </PopupItem>
        )) : <PopupItem data-testid="popup-no-data">{noDataMessage}</PopupItem>}
      </PopupContent>
    </PopupContainer>,
    document.body // Render directly into the body
  );
};

const InfoButton = ({ items = [], title = "Information", size = 'medium', noDataMessage = '' }: InfoButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const iconSizes = {
    small: 18,
    medium: 24,
    large: 32
  };

  const getIconSize = () => iconSizes[size];

  return (
    <ButtonContainer data-testid="info-button-container">
      <InfoButtonStyled
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        data-testid="info-button"
      >
        <HiOutlineInformationCircle size={getIconSize()} color={isOpen ? 'white' : '#326C82'} fill={isOpen ? '#326C82' : 'white'} data-testid="info-icon" />
      </InfoButtonStyled>

      <PopupPortal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        items={items}
        title={title}
        buttonRef={buttonRef}
        noDataMessage={noDataMessage}
      />
    </ButtonContainer>
  );
};

export default InfoButton;