import { useState } from 'react';
import { CardProps } from './PopUp.type';
import { ClosePopUpContainer, Content, PopUpTitleContainer, TitleContainer, Wrapper } from './PopUp_.style';
import { MdClose } from 'react-icons/md';
import { addEventTracking } from '@utils/utils';

const PopUp = ({ children, title, icon, opened = false, eventTrackingTitle }: CardProps) => {
  const [showPopup, setShowPopup] = useState(opened);

  const closePopup = () => {
    setShowPopup(false);
  };

  const togglePopup = () => {
    if (!showPopup) {
      addEventTracking(eventTrackingTitle, 'Click');
    }
    setShowPopup(!showPopup);
  };

  return (
    <>
      {showPopup && (
        <Wrapper isActive={showPopup}>
          <Content isActive={showPopup}>
            <PopUpTitleContainer>
              <TitleContainer>{title}</TitleContainer>
              <ClosePopUpContainer>
                <MdClose onClick={closePopup} />
              </ClosePopUpContainer>
            </PopUpTitleContainer>
            {children}
          </Content>
        </Wrapper>
      )}
      <div onClick={togglePopup}>
        <>{icon}</>
      </div>
    </>
  );
};

export default PopUp;
