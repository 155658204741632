import { Modal } from '../modal/Modal';
import { BasicModalContainer, ButtonContainer, StyledSpan } from './ModalSubmitEntity_.style';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';

export interface ModalSubmitEntityProps {
  entity: any;
  show?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  hasProduct: boolean;
  onAddProduct: () => void;
}

export const ModalSubmitEntity = ({ entity, show, onClose, onSubmit, hasProduct, onAddProduct }: ModalSubmitEntityProps) => {
  const { t } = useTranslation();

  return (
    <>
      {show && (
        <Modal
          onClose={onClose}
          size={{ height: undefined, width: '40%' }}
          show={show}
          title={`Terminer et soumettre votre entité : <b>${entity?.name}</b>`}>
          <BasicModalContainer>
            {hasProduct ? (
              <StyledSpan>
                {' '}
                <b>{t('modal.submit_entity.congratulations')}</b>
                <br />
                <br />
                {t('modal.submit_entity.products_addition_message')}
              </StyledSpan>
            ) : (
              <StyledSpan>
                {' '}
                <b>{t('modal.submit_entity.congratulations')}</b>
                <br />
                <br />
                {t('modal.submit_entity.no_products_message')}
              </StyledSpan>
            )}

            <ButtonContainer>
              <Button onClick={onSubmit} label={t('common.submit')} />
              {!hasProduct && <Button onClick={onAddProduct} label={t('modal.submit_entity.add_product_button')} />}
            </ButtonContainer>
          </BasicModalContainer>
        </Modal>
      )}
    </>
  );
};
