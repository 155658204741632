import { useEffect, useState } from 'react';
import {
  Content,
  DragOverlay,
  Empty,
  ExplorerItem,
  FileContainer,
  FileExplorerContainer,
  FileExplorerWindow,
  FileSlider,
  Link,
} from './DragAndDrop_.style';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { DragAndDropProps } from './DragAndDrop.type';

import { RiDeleteBin7Line } from 'react-icons/ri';
import { COLORS } from '@utils/constants';
import FileIcons from '../fileIcons/FileIcons';
import { HiOutlineCloudDownload, HiOutlineDownload } from 'react-icons/hi';
import { deleteDocumentFromS3ByKey } from '@utils/api';
import Loader from '../loader/Loader';

const DragAndDrop: React.FC<DragAndDropProps> = ({
  emptyText,
  customClick,
  uploadFiles,
  getDocumentsFromS3,
  storedFiles,
  setStoredFiles,
  currentAccountId,
}) => {
  const { t } = useTranslation();
  const [fileLoading, setFileLoading] = useState(false);

  const onDrop = async files => {
    setFileLoading(true);
    await uploadFiles(files);
    const documents = await getDocumentsFromS3();
    setStoredFiles(documents);
    setFileLoading(false);
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      setFileLoading(true);
      const documents = await getDocumentsFromS3();
      setStoredFiles(documents);
      setFileLoading(false);
    };

    fetchDocuments();
  }, []);

  const onDropRejected = rejectedFiles => {
    rejectedFiles.forEach(file => {
      if (file.errors[0].code === 'file-too-large') {
        console.error(`${file.file.name} is too large`);
      } else if (file.errors[0].code === 'file-invalid-type') {
        console.error(`${file.file.name} is an invalid file type`);
      } else {
        console.error(`${file.file.name} is not allowed`);
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/msword': ['.doc'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    maxSize: 5 * 1024 * 1024, // 5 Mo limit
    onDrop,
    onDropRejected,
  });

  const handleDeleteFile = async (event, key) => {
    event.preventDefault();
    if (!window.confirm(t('FileExplorer.confirmDeleteFile'))) return;
    setFileLoading(true);
    await deleteDocumentFromS3ByKey(key, currentAccountId);

    const documents = await getDocumentsFromS3();
    setStoredFiles(documents);
    setFileLoading(false);
  };

  const files = storedFiles.map((file, index) => {
    return (
      <ExplorerItem
        selected={storedFiles.includes(file.name?.replaceAll('_', ' '))}
        onClick={() => customClick && customClick(file.name)}
        type={file.type}
        key={'file_' + file.name + index}>
        <FileIcons filesType={[file.fileType]} />
        <span>{file.name?.replaceAll('_', ' ').slice(0, file.name.lastIndexOf('.'))}</span>
        <div>
          <div>
            <RiDeleteBin7Line style={{ color: COLORS.Red }} onClick={event => handleDeleteFile(event, file.Key)} />

            <Link href={file.link}>
              <HiOutlineCloudDownload />
            </Link>
          </div>
        </div>
      </ExplorerItem>
    );
  });

  return (
    <FileExplorerContainer>
      <Content isLoading={fileLoading}>
        {fileLoading ? (
          <Loader loaderOnly />
        ) : (
          <>
            <input {...getInputProps()} />
            {storedFiles.length !== 0 ? (
              <FileSlider role={'slider'}>
                <FileContainer> {files}</FileContainer>
              </FileSlider>
            ) : (
              <FileExplorerWindow {...getRootProps({ className: 'dropzone' })}>
                <Empty>
                  <HiOutlineDownload />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: emptyText,
                    }}></span>
                  {isDragActive && <DragOverlay>{t('FileExplorer.dropFiles')}</DragOverlay>}
                </Empty>
              </FileExplorerWindow>
            )}
          </>
        )}
      </Content>
    </FileExplorerContainer>
  );
};

export default DragAndDrop;
