import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveAccount, selectUser, setAccount } from 'src/reducers/account.reducer';
import { setLoading } from 'src/reducers/app.reducer';
import { activateBillingSpace, downloadInvoiceFile, getInvoices } from 'src/utils/api';
import {
  DataTableContainer,
  EmptyTableContainer,
  EmptyText,
  EmptyTextDescription,
  InvoicesContainer,
  InvoicesTable,
  RowContainer,
  Subtitle,
} from './Invoices.styles';
import Div from '../div/Div';
import FileDownload from '../fileDownload/FileDownload';
import TitlePage from '../titlePage/TitlePage';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Button';
import { toast } from 'react-toastify';

const Invoices = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const activeAccount = useSelector(selectActiveAccount);
  const currentUser = useSelector(selectUser);

  const [invoices, setInvoices] = useState(null);

  useEffect(() => {
    const getAllInvoices = async () => {
      dispatch(setLoading(true));
      const response = await getInvoices(activeAccount._id);
      let sortedInvoices = response;
      if (response.invoices) {
        sortedInvoices = [...response.invoices].sort((a, b) => dayjs(b.date).unix() - dayjs(a.date).unix());
      }
      setInvoices(sortedInvoices);
      dispatch(setLoading(false));
    };
    activeAccount && getAllInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusTranslations = {
    incomplete: t('common.informationIncomplete'),
    draft: t('common.draft'),
    upcoming: t('common.upcoming'),
    late: t('common.late'),
    paid: t('common.paid'),
    cancelled: t('common.cancelled'),
    partially_cancelled: t('common.partially_cancelled'),
    partially_cancelled_draft: t('common.partially_cancelled_draft'),
    credit_note: t('common.credit_note'),
  };

  const requestInvoices = async () => {
    const response = await activateBillingSpace(activeAccount._id, currentUser._id);
    dispatch(setAccount(response.updatedAccount));

    toast.info(t('invoice.request_area_invoices_successfully'));
  };
  return (
    <InvoicesContainer>
      <TitlePage title={t('invoice.title')} />

      <Subtitle>{t('invoice.subtitle')}</Subtitle>
      <DataTableContainer>
        <div className='container-fluid data-table font-weight-bold'>
          <div className='row'>
            <Div className={'col-3'}>
              <span>{t('invoice.invoice_name')}</span>
            </Div>
            <Div className='col-2-5'>{t('common.company')}</Div>
            <Div className='col-1'>{t('common.amount')}</Div>
            <Div className='col-1-5'>{t('common.date')}</Div>
            <Div className='col-2'>{t('common.status')}</Div>
            <Div className='col-1'>{t('common.download')}</Div>
          </div>
        </div>
        <Divider />
      </DataTableContainer>
      <InvoicesTable>
        <DataTableContainer>
          <div className='container-fluid data-table'>
            {activeAccount.pennylane?.reference ? (
              invoices?.map(invoice => (
                <div key={invoice.id}>
                  <RowContainer className='row'>
                    <Div className='col-3'>{invoice.label.split('(')[0].trim()}</Div>
                    <Div className='col-2-5'>{invoice.customer.name}</Div>

                    <Div className={'col-1'}>
                      {invoice.amount} {invoice.currency}
                    </Div>
                    <Div className='col-1-5'>{dayjs(invoice.date).format('D MMMM YYYY')}</Div>
                    <Div className='col-2'>{statusTranslations[invoice.status]}</Div>
                    <Div className='col-1'>
                      <FileDownload fileUrl={invoice.file_url} fileName={invoice.filename} getFileContent={downloadInvoiceFile} />
                    </Div>
                  </RowContainer>
                </div>
              ))
            ) : activeAccount.pennylane?.activationRequest ? (
              <EmptyTableContainer>
                <EmptyText>{t('invoice.request_invoices_made')}</EmptyText>
                <EmptyTextDescription>{t('invoice.invoices_area_will_be_activated')}</EmptyTextDescription>
              </EmptyTableContainer>
            ) : (
              <EmptyTableContainer>
                <EmptyText>{t('invoice.access_not_available')}</EmptyText>
                <Button onClick={requestInvoices} label={t('invoice.request_invoices')}></Button>
                <EmptyTextDescription>{t('invoice.invoices_area_not_activated')}</EmptyTextDescription>
                <EmptyTextDescription>{t('invoice.invoices_area_will_be_activated')} </EmptyTextDescription>
              </EmptyTableContainer>
            )}
          </div>
        </DataTableContainer>
      </InvoicesTable>
    </InvoicesContainer>
  );
};

export default Invoices;
