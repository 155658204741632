import { COLORS } from '@utils/constants';
import styled from 'styled-components';

export const ProgressBarWithLabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

export const ProgressBarLabel = styled.span`
  font-weight: bold;
  font-size: 20px;
  color: ${COLORS.Black};
`;

export const ProgressBarContainer = styled.div`
  flex: 1;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 50px;
  min-width: 300px;
  width: 100%;
`;

export const ProgressBarFiller = styled.div<{ completed: number; bgcolor: string }>`
  height: 100%;
  width: ${({ completed }) => completed}%;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: inherit;
  transition: width 1s ease-in-out;
`;
