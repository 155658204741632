import { COLORSV2 } from '@utils/constants';
import styled from 'styled-components';

export const WizardContainer = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  background-color: white;
`;

export const SideBar = styled.div`
  width: 210px;
  background-color: ${COLORSV2.lightPink};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Separator = styled.div`
  color: #1179a1;
  width: 50px;
  border: 1px solid #1179a1;
  margin-top: 35px;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-family: 'Roboto';
  font-size: 1.33rem;
  margin-top: 190px;
  flex: 1;
  user-select: none;
`;

export const Step = styled.div<{ selected: boolean; isSelectable: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  color: ${({ selected }) => (selected ? 'rgba(0, 0, 0,    0.8)' : 'rgba(0, 0, 0, 0.63)')};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
  cursor: ${({ isSelectable }) => (isSelectable ? 'pointer' : 'not-allowed')};

  svg {
    visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
    height: 24px;
    width: 24px;
  }
`;

export const ContentContainer = styled.div``;

export const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Roboto';
  font-size: 1.33rem;
  padding: 230px 125px 0px;
  row-gap: 40px;
  animation: fadeInRightBig 0.5s;
`;

export const Title = styled.div`
  display: flex;
  max-width: 596px;
  font-family: 'Nunito';
  font-size: 3.9rem;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.011em;
`;

export const Content = styled.div`
  display: flex;
  max-width: 863px;
  line-height: 26px;
  font-family: 'Roboto';
  font-weight: 400;

  button {
    margin-top: 20px;
  }
`;
export const ErrorMessage = styled.div`
  color: red;
`;
