import { COLORS } from '@utils/constants';
import { ProgressBarProps } from './ProgressBar.type';
import { ProgressBarContainer, ProgressBarFiller, ProgressBarLabel, ProgressBarWithLabelContainer } from './ProgressBar_.style';

const ProgressBar = ({ bgcolor = COLORS.Green, completed = 0 }: ProgressBarProps) => {
  const clampedCompleted = Math.min(Math.max(completed, 0), 100);

  return (
    <ProgressBarWithLabelContainer>
      <ProgressBarLabel>{`${clampedCompleted}%`}</ProgressBarLabel>
      <ProgressBarContainer>
        <ProgressBarFiller completed={clampedCompleted} bgcolor={bgcolor}></ProgressBarFiller>
      </ProgressBarContainer>
    </ProgressBarWithLabelContainer>
  );
};

export default ProgressBar;
