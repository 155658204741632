import styled from 'styled-components';
import { MdArrowForwardIos } from 'react-icons/md';
import { COLORS } from '@utils/constants';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  text-align: left;
  background-color: rgba(229, 229, 229, 0.24);
  border-radius: 15px;
  padding: 10px;
`;

export const DescriptionHeader = styled.div`
  display: flex;
  font-weight: 700;
  justify-content: space-between;
`;

export const DescriptionContent = styled.div<{ open: boolean }>`
  height: ${({ open }) => (open ? 'auto' : '0')};
  padding-top: ${({ open }) => (open ? '10px' : '0px')};
`;

export const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  height: 100%;
  font-family: 'Nunito';
`;

export const Label = styled.div``;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  flex: 1;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const StepLabel = styled.div`
  font-size: 1.2em;
  font-weight: 500;

  b {
    color: rgba(58, 169, 212, 1);
  }
`;

export const StyledMdArrowForwardIos = styled(MdArrowForwardIos)<{ open: boolean }>`
  transform: rotate(${({ open }) => (open ? '90deg' : '0deg')});
  transition: transform 0.2s ease-in-out;
`;

export const Previous = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 1.4em;
  font-weight: 700;
  text-decoration: underline;
  flex: 1;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Percentage = styled.div<{ type: string }>`
  width: 36px;
  background-color: ${({ type }) => (type === 'success' ? 'rgba(0, 198, 161, 1)' : 'rgba(244, 157, 29, 1)')};
  //color: ${({ type }) => (type === 'success' ? 'rgba(0, 198, 161, 1)' : 'rgba(255, 118, 117, 1)')};
  display: flex;
  opacity: 0.7;
  justify-content: left;
  align-items: center;
  font-weight: 700;
  padding: 5px 10px;
`;

export const PercentageContainer = styled.div<{ type: string }>`
  display: flex;
  align-items: center;
  gap: 10px;

  b {
    color: ${({ type }) => (type === 'success' ? 'rgba(0, 198, 161, 1)' : 'rgba(244, 157, 29, 1)')};
  }

  :last-child {
    justify-self: right;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px;
  background-color: rgba(50, 108, 130, 0.03);
  border: 0.5px;
  border-radius: 12px;
  font-size: 1.2rem;
  font-family: Nunito;
`;

export const PreviewTitle = styled.div`
  text-decoration: underline;
  font-weight: 700;
`;

export const PreviewSummary = styled.div``;

export const Download = styled.div`
  align-self: center;
  cursor: pointer;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.011em;
  text-align: center;
  text-decoration: underline;
`;

export const ResultContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EmptyEntity = styled.div`
  border: 1px dashed ${COLORS.MediumGrey};
  width: 100%;
  border-radius: 15px;
  flex: 1;
`;

export const EmptyStep = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  span {
    text-align: center;
  }

  svg {
    height: 30px;
    width: 30px;
    color: ${COLORS.MediumGrey};
    animation: swing 0.8s ease;
  }
`;
