export const GENERATION_MODES = {
  WORD: 'docx',
  EXCEL: 'excel',
  ALL: 'all',
};

export const INITIAL_STATE = {
  loading: false,
  generationMode: GENERATION_MODES.WORD,
  targetGeneration: null,
  targetZipContent: [],
  lang: 'FR',
  linkedSurveys: [],
  onlySelectedProducts: false,
};