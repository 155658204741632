import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllContentfulData } from './contentful.reducer';

export const changeLangAndFetchContentful = createAsyncThunk('app/CHANGE_LANG', async (lang: any, thunkApi) => {
  let dispatch = thunkApi.dispatch;
  await dispatch(fetchAllContentfulData(lang));
  return lang;
});

const initialState = {
  loading: false,
  lang: 'FR',
  targetDocument: null,
  targetExcel: null,
  displayDebug: false,
  currentSurvey: null,
  targetedSurveyGrp: null,
  currentStep: { id: '', name: '' },
  currentStepDetailed: null,
  currentVersion: undefined,
  darkMode: false,
  currentEntity: undefined,
  filteredSurveys: [],
  entitiesSetup: false,
  currentPage: null,
  errors: null,
  dirty: false,
  modal: { show: false, type: null },
  displayShareModal: false,
  shareModal: { type: '', id: '', label: '' },
  sidenavState: {
    open: false,
    type: '',
  },
  selectedCollaborator: null,
  adminStepsSelectedEntity: undefined,
};

export const appReducer = createSlice({
  name: 'APP',
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
    setTargetDocument: (state, action) => {
      state.targetDocument = action.payload;
    },
    setTargetExcel: (state, action) => {
      state.targetExcel = action.payload;
    },
    setDisplayDebug: (state, action) => {
      state.displayDebug = action.payload;
    },
    setCurrentSurvey: (state, action) => {
      state.currentSurvey = action.payload;
    },
    setTargetedSurveyGrp: (state, action) => {
      state.targetedSurveyGrp = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setCurrentStepDetailed: (state, action) => {
      state.currentStepDetailed = action.payload;
    },
    setCurrentVersion: (state, action) => {
      state.currentVersion = action.payload;
    },
    setDirty: (state, action) => {
      state.dirty = action.payload;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setCurrentEntity: (state, action) => {
      state.currentEntity = action.payload;
    },
    setFilteredSurveys: (state, action) => {
      state.filteredSurveys = action.payload;
    },
    setEntitiesSetup: (state, action) => {
      state.entitiesSetup = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setShareModal: (state, action) => {
      state.shareModal = action.payload;
    },
    setSelectedCollaborator: (state, action) => {
      state.selectedCollaborator = action.payload;
    },
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setAdminStepsSelectedEntity: (state, action) => {
      state.adminStepsSelectedEntity = action.payload;
    },
    resetState: state => {
      state.loading = false;
      state.lang = 'FR';
      state.targetDocument = null;
      state.targetExcel = null;
      state.displayDebug = false;
      state.currentSurvey = null;
      state.targetedSurveyGrp = null;
      state.currentStep = { id: '', name: '' };
      state.currentVersion = undefined;
      state.darkMode = false;
      state.currentEntity = undefined;
      state.filteredSurveys = [];
      state.entitiesSetup = false;
      state.errors = null;
      state.dirty = false;
      state.displayShareModal = false;
      state.shareModal = { type: '', id: '', label: '' };
      state.modal = { show: false, type: null };
      state.adminStepsSelectedEntity = undefined;
    },
    setSidenavState: (state, action) => {
      state.sidenavState = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(changeLangAndFetchContentful.fulfilled, (state, action) => {
      state.lang = action.payload;
    });
  },
});

export const {
  setLoading,
  setLang,
  setDisplayDebug,
  setCurrentSurvey,
  setCurrentVersion,
  setCurrentStep,
  setDirty,
  setCurrentEntity,
  resetState,
  setSidenavState,
  setErrors,
  setShareModal,
  setSelectedCollaborator,
  setModal,
  setAdminStepsSelectedEntity,
} = appReducer.actions;

export const selectLoading = state => state.app.loading;

export const selectLang = state => state.app.lang;

export const selectTargetDocument = state => state.app.targetDocument;

export const selectTargetExcel = state => state.app.targetExcel;

export const selectDisplayDebug = state => state.app.displayDebug;

export const selectCurrentSurvey = state => state.app.currentSurvey;

export const selectTargetedSurveyGrp = state => state.app.targetedSurveyGrp;

export const selectCurrentStep = state => state.app.currentStep;

export const selectCurrentVersion = state => state.app.currentVersion;

export const selectDirty = state => state.app.dirty;

export const selectDarkMode = state => state.app.darkMode;

export const selectCurrentEntity = state => state.app.currentEntity;

export const selectFilteredSurveys = state => state.app.filteredSurveys;

export const selectEntitiesSetup = state => state.app.entitiesSetup;

export const selectCurrentPage = state => state.app.currentPage;

export const selectErrors = state => state.app.errors;

export const selectDisplayShareModal = state => state.app.displayShareModal;

export const selectShareModal = state => state.app.shareModal;

export const selectSidenavState = state => state.app.sidenavState;

export const selectSelectedCollaborator = state => state.app.selectedCollaborator;

export const selectModal = state => state.app.modal;

export const selectAdminStepsSelectedEntity = state => state.app.adminStepsSelectedEntity;

export default appReducer.reducer;
