import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../modal/Modal';
import { Button } from '../Button';
import { InputComponent } from '../form/InputComponent';
import { selectActiveAccount } from 'src/reducers/account.reducer';
import { useSelector } from 'react-redux';
import { BasicModalContainer, ButtonContainer } from './ModalAddEntity_.style';

export interface ModalAddEntityProps {
  show?: boolean;
  onClose: () => void;
  addEntity: (newEntityName: string) => void;
  isValidEntityName: (newEntityName: string) => boolean;
}

const ModalAddEntity = ({ show, onClose, addEntity, isValidEntityName }: ModalAddEntityProps) => {
  const { t } = useTranslation();
  const activeAccount = useSelector(selectActiveAccount);
  const [newEntityName, setNewName] = useState(null);

  return (
    <Modal alignment={'start'} size={{ width: '50%' }} show={show} title={t('entityTable.modalTitles.addEntity')} onClose={onClose}>
      <BasicModalContainer>
        <InputComponent
          focus={true}
          placeholder={t('entityTable.placeholders.entityName')}
          sharable={false}
          type={'text'}
          onChange={e => setNewName(e.target.value)}
          error={activeAccount?.entities?.some(entity => entity.name === newEntityName?.trim()) && t('common.nameUsed')}
          value={newEntityName}
        />
        <ButtonContainer>
          <Button type='text' onClick={onClose} label={t('common.cancel')} />
          <Button type='primary' onClick={() => addEntity(newEntityName)} label={t('common.add')} disabled={!isValidEntityName(newEntityName)} />
        </ButtonContainer>
      </BasicModalContainer>
    </Modal>
  );
};

export default ModalAddEntity;
