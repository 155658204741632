import styled from 'styled-components';
import { COLORS } from '@utils/constants';

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSpan = styled.span`
  font-size: 1.2rem;
  color: ${COLORS.Grey};
  line-height: 25px;

  b {
    color: ${COLORS.Red};
  }
`;
