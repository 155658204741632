import styled, { css } from 'styled-components';
import { HiOutlineLink } from 'react-icons/hi';

import { COLORSV2 } from '../../utils/constants';
import { MoreOptionItem, MoreOptions } from '../more-option/MoreOption';
import { useTranslation } from 'react-i18next';

export interface FillingInfos {
  total: number;
  filled: number;
}

export interface SharedItemProps {
  type: string;
  value?: string;
  entityName?: string;
  surveyName?: string;
  stepName?: string;
  versionName?: string;
  options?: Array<MoreOptionItem>;
  questionValue?: string;
  valid?: boolean;
  filling?: FillingInfos;
  onClickFn?: () => void;
  linked?: boolean;
  actionStatus?: string;
  cursor?: string | null;
  backgroundItems?: string;
}

export const SharedItem = ({
  type,
  value,
  entityName,
  surveyName,
  stepName,
  versionName,
  options,
  questionValue,
  valid,
  filling,
  onClickFn,
  linked = false,
  actionStatus,
  cursor = 'pointer',
  backgroundItems,
}: SharedItemProps) => {
  const { t } = useTranslation();

  const getBreadCrumb = () => {
    let breadCrumb = '';
    if (entityName) {
      breadCrumb += entityName;
    }
    if (surveyName) {
      breadCrumb += ' - ' + surveyName;
    }
    if (versionName) {
      breadCrumb += ' - ' + versionName;
    }
    if (stepName) {
      breadCrumb += ' - ' + stepName;
    }
    return breadCrumb;
  };

  return (
    <SharedItemContainer linked={linked} onClick={onClickFn} cursor={cursor} backgroundItems={backgroundItems} type={type}>
      <ShareItemContent>
        <Title>
          <span>{getBreadCrumb()}</span>
          {value && <SubTitle>{value}</SubTitle>}
        </Title>
        <SharedItemName>
          {type === 'questions' && <span>{valid ? questionValue : t('collaborators.shared_items.no_answer_yet')}</span>}
          {type === 'steps' && (
            <span>
              {filling?.filled}/{filling?.total} {t('collaborators.shared_items.questions_filled')}
            </span>
          )}
          {type === 'entities' && (
            <span>
              {filling?.filled}/{filling?.total} {t('collaborators.shared_items.surveys_completed')}
            </span>
          )}
          {type === 'surveys' && (
            <span>
              {filling?.filled}/{filling?.total} {t('collaborators.shared_items.completed_steps')}
            </span>
          )}
          {type === 'actions' && <span>{actionStatus}</span>}
        </SharedItemName>
      </ShareItemContent>
      <MoreOptionsContainer>
        {linked && <HiOutlineLink />}
        {options?.length > 0 && <MoreOptions options={options} />}
      </MoreOptionsContainer>
    </SharedItemContainer>
  );
};

const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: ${COLORSV2.textLightGrey};
  width: 80%;
`;

const SubTitle = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: ${COLORSV2.primary};
  margin-top: 10px;
`;

const ShareItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  span {
    font-weight: 400;
  }
`;

const MoreOptionsContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const SharedItemContainer = styled.div<{ linked: boolean; cursor: string; backgroundItems: string; type: string }>`
  position: relative;

  ${({ type }) => {
    switch (type) {
      case 'actions':
        return css`
          width: 48rem;
        `;
      case 'entities':
        return css`
          height: 8rem;
          width: 18rem;
        `;
      case 'questions':
        return;
      case 'steps':
        return css`
          width: 28rem;
        `;
      default:
        return;
    }
  }}

  background: ${({ backgroundItems }) => backgroundItems};
  padding: 15px;
  border-radius: 10px;
  border: 1px solid rgba(94, 119, 140, 0.22);
  cursor: ${({ cursor }) => cursor};

  font-family: Biennale;

  > svg {
    font-size: 18px;
    color: lightgray;
  }
`;

const SharedItemName = styled.div`
  font-size: 1.1rem;
  color: ${COLORSV2.primaryBlue};
  display: flex;
  align-items: center;
`;
