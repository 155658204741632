import dayjs from 'dayjs';

import Calendar from '../calendar/Calendar';

export const WizardSlides = (account, t) => {
  const calendar = (isStepPointInvitation: boolean) => {
    return [
      {
        step: 1,
        label: isStepPointInvitation ? t('wizard.calendar.labelStepPointInvitation') : t('wizard.calendar.labelWelcome'),
        title: isStepPointInvitation
          ? t('wizard.calendar.titleStepPointInvitation')
          : t('wizard.calendar.titleWelcome', { companyName: account.companyName }),
        content: isStepPointInvitation ? (
          <div>{t('wizard.calendar.contentStepPointInvitation')}</div>
        ) : (
          <div>{t('wizard.calendar.contentWelcome')}</div>
        ),
        input: false,
        inputType: 'text',
        options: [],
        buttonLabel: t('common.next'),
        conditions: [!account.activity.appointments.didScheduleFirstAppointment || isStepPointInvitation],
      },
      {
        step: 2,
        label: t('wizard.calendar.labelSchedule'),
        title: t('wizard.calendar.titleSchedule'),
        content: t('wizard.calendar.contentSchedule'),
        input: false,
        inputType: 'text',
        options: [],
        buttonLabel: t('wizard.calendar.buttonLabel'),
        conditions: [!account.activity.appointments.didScheduleFirstAppointment || isStepPointInvitation],
        children: Calendar,
      },
      {
        step: 3,
        label: t('wizard.final.label'),
        title: t('wizard.final.title'),
        content: <div>{t('wizard.calendar.contentFinal')}</div>,
        input: false,
        inputType: 'text',
        options: [],
        conditions: [],
      },
    ];
  };
  const appointmentConfirmation = () => {
    const firstAppointment = account.activity.appointments.list[0];
    const formattedSlotStart = dayjs(firstAppointment?.startTime).format('HH:mm');
    const formattedDate = dayjs(firstAppointment?.startTime).format('dddd, D MMMM YYYY');
    return [
      {
        step: 1,
        label: t('wizard.final.label'),
        title: t('wizard.final.title'),
        content: <div>{t('wizard.appointmentConfirmation.content', { formattedDate, formattedSlotStart })}</div>,
        input: false,
        inputType: 'text',
        options: [],
        conditions: [],
      },
    ];
  };

  if (!account) {
    return [];
  }

  let isStepPointInvitationEmailSent = account.users.some(user =>
    user.activity.sentMails.some(email => email.templateId === 'd-cf2a5491a81242a98fd6a103dfedb99e')
  );
  let isStepPointInvitation = isStepPointInvitationEmailSent && !account.activity.appointments.didScheduleStepPointAppointment;
  if (!account.activity.appointments.didScheduleFirstAppointment || isStepPointInvitation) {
    return calendar(isStepPointInvitation);
  }
  return appointmentConfirmation();
};
