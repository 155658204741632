import React from 'react';
import styled from 'styled-components';
import { InputComponent } from '../form/InputComponent';
import { useEffect, useMemo, useState } from 'react';
import {} from // getRelationsBetweenSurveys,
// getRelationsBetweenSurveysAndRegistries,
// getAllDocDependencies,
// getAllRegistriesDependencies,
    '../../utils/utils';
import { selectAllContentfulData } from '../../reducers/contentful.reducer';
import { useSelector } from 'react-redux';
import { FiLink } from 'react-icons/fi';
import { Button } from '../Button';
import { selectSelectedAccount } from '../../reducers/account.reducer';
import Loader from '../loader/loader';

export const DashboardDependencies = () => {
  //redux
  const contentfulData = useSelector(selectAllContentfulData);
  const selectedAccount = useSelector(selectSelectedAccount);

  //state
  const [loading, setLoading] = useState(false);
  const [targetDependencies, setTargetDependencies] = useState(null);
  const [targetEntity, setTargetEntity] = useState(null);
  const [dependencies, setDependencies] = useState([]);
  const [orphanKeys, setOrphanKeys] = useState([]);
  const [multipleSurveysKeys, setMultipleSurveysKeys] = useState([]);
  const [dependencyTree, setDependencyTree] = useState({ name: '', conditions: [], replaces: [] });

  interface linkedSurvey {
    id: string;
    name: string;
    linkedQuestions: object[];
  }

  const linkedSurveys: linkedSurvey[] = [];
  const env = process.env.NODE_ENV === 'development' ? 'stage' : 'production';
  const baseLink = `https://app.contentful.com/spaces/5h3y0cofo0sv/environments/${env}/entries/`;

  const getDocOptions = () =>
    contentfulData.documents.map(document => ({
      label: document.name.replaceAll('_', ' '),
      value: document.name,
      id: document.id,
    }));

  const getRegistriesOptions = () =>
    contentfulData.registries.map(registry => ({
      label: registry.name.replaceAll('_', ' '),
      value: registry.name,
      id: registry.id,
    }));

  const getEntitiesOptions = () =>
    selectedAccount?.entities?.map(entity => ({
      label: entity.name.replaceAll('_', ' '),
      value: entity.name,
    }));

  const handleTargetDoc = (name, value) => {
    setTargetDependencies({
      name: value,
      type: 'document',
    });
  };

  const handleTargetRegistry = (name, value) => {
    setTargetDependencies({
      name: value,
      type: 'registry',
    });
  };

  const handleTargetEntity = (name, value) => {
    setTargetEntity(value);
  };

  const getTargetedValues = () => {
    let targetedValues = {};
    let entityResponses = selectedAccount.entities.find(entity => entity.name === targetEntity).responses;
    if (!entityResponses.length) {
      return;
    }
    entityResponses.forEach(surveyResponses => {
      targetedValues = {
        ...targetedValues,
        ...(targetDependencies[surveyResponses.surveyId]
          ? surveyResponses.versions.find(version => version.versionName === targetDependencies[surveyResponses.surveyId])?.versionResponses
          : surveyResponses.versions[0]?.versionResponses),
      };
    });
    return targetedValues;
  };

  const getConditionsAndReplaces = () => {
    let target = undefined;
    let values = getTargetedValues();
    let dependencyTree = { name: '', replaces: [], conditions: [] };

    /**
     * Gère les conditions (avec support des blocs et OR)
     */
    const handleConditions = (conditions, sheet, entryId) => {
      if (Array.isArray(conditions)) {
        conditions.forEach(conditionBlock => {
          if (conditionBlock.OR) {
            // Cas OR => Une seule condition du bloc doit être vraie
            dependencyTree.conditions.push({
              worksheet: targetDependencies.type === 'document' ? sheet.id : sheet.name,
              type: 'OR',
              list: conditionBlock.OR.map(cond => ({
                conditionKey: cond.key,
                conditionValue: cond.value,
                valueInSurvey: values[cond.key] ?? null,
                entryId,
              })),
            });
          } else if (conditionBlock.conditions) {
            // Cas Bloc de Conditions (AND entre toutes)
            dependencyTree.conditions.push({
              worksheet: targetDependencies.type === 'document' ? sheet.id : sheet.name,
              type: 'AND',
              list: conditionBlock.conditions.map(cond => ({
                conditionKey: cond.key,
                conditionValue: cond.value,
                valueInSurvey: values[cond.key] ?? null,
                entryId,
              })),
            });
          }
        });
      }
    };

    /**
     * Gère les remplacements de texte
     */
    const handleReplaces = (replaces, sheet, entryId) => {
      if (Array.isArray(replaces)) {
        replaces.forEach(replace => {
          dependencyTree.replaces.push({
            worksheet: targetDependencies.type === 'document' ? sheet.id : sheet.name,
            textToReplace: replace.key,
            replaceKey: replace.value,
            replaceValue: values[replace.value] ?? null,
            entryId,
          });
        });
      }
    };

    /**
     * Récupération du bon objet en fonction du type
     */
    switch (targetDependencies.type) {
      case 'survey':
        target = contentfulData.surveys.find(survey => survey.name === targetDependencies.name);
        break;
      case 'registry':
        target = contentfulData.registries.find(registry => registry.name === targetDependencies.name);
        break;
      case 'document':
        target = contentfulData.documents.find(document => document.name === targetDependencies.name);
        break;
    }
    dependencyTree.name = target?.name;

    /**
     * Itération sur les éléments et application des handlers
     */
    let targetType = targetDependencies.type === 'document' ? 'parts' : 'sheets';
    target[targetType].forEach(sheet => {
      handleConditions(sheet.conditions, sheet, targetDependencies.type === 'document' ? sheet.id : target.id);
      handleReplaces(sheet.replace, sheet, targetDependencies.type === 'document' ? sheet.id : target.id);

      if (targetDependencies.type === 'registry') {
        sheet.rows.forEach(row => {
          handleConditions(row.conditions, sheet, row.id);
          handleReplaces(row.replace, sheet, row.id);
          row.values.forEach(value => {
            handleConditions(value.conditions, sheet, row.id);
            handleReplaces(value.replace, sheet, row.id);
            if (value.composed) {
              value.values.forEach(composedRowValueValue => {
                handleConditions(composedRowValueValue.conditions, sheet, row.id);
                handleReplaces(composedRowValueValue.replace, sheet, row.id);
                if (composedRowValueValue.composed) {
                  composedRowValueValue.values.forEach(level2ComposedRowValueValue => {
                    handleConditions(level2ComposedRowValueValue.conditions, sheet, row.id);
                    handleReplaces(level2ComposedRowValueValue.replace, sheet, row.id);
                  });
                }
              });
            }
          });
        });
      }

      if (targetDependencies.type === 'document') {
        sheet.listItems?.forEach(listItem => {
          handleConditions(listItem.conditions, sheet, sheet.id);
          handleReplaces(listItem.replace, sheet, sheet.id);
        });
        sheet.rows?.forEach(row => {
          handleConditions(row.conditions, sheet, sheet.id);
          handleReplaces(row.replace, sheet, sheet.id);
          row.values.forEach(value => {
            handleConditions(value.conditions, sheet, sheet.id);
            handleReplaces(value.replace, sheet, sheet.id);
          });
        });
      }
    });

    /**
     * Regroupement des conditions et remplacements par worksheet
     */
    const groupItemsByWorksheet = (items) => {
      return Object.entries(items.reduce((group, item) => {
        group[item.worksheet] = group[item.worksheet] ?? [];
        group[item.worksheet].push(item);
        return group;
      }, {})).map(([worksheet, list]) => ({ worksheet, list }));
    };

    dependencyTree.conditions = groupItemsByWorksheet(dependencyTree.conditions);
    dependencyTree.replaces = groupItemsByWorksheet(dependencyTree.replaces);

    /**
     * Vérification des éléments non trouvés et des multi-surveys
     */
    let unfoundItems = [];
    let multipleSurveysItems = [];

    const checkUnfoundItems = (type, key) => {
      let itemFound = false;
      linkedSurveys.forEach(linkedSurvey => {
        linkedSurvey.linkedQuestions.forEach(linkedQuestion => {
          if (Object.values(linkedQuestion).includes(key)) {
            if (itemFound && !multipleSurveysItems.includes(key)) {
              multipleSurveysItems.push(key);
            }
            itemFound = true;
          }
        });
      });
      if (!itemFound) {
        unfoundItems.push(key);
      }
    };

    dependencyTree.replaces.forEach(replace => {
      replace.list.forEach(item => checkUnfoundItems('replace', item.replaceKey));
    });

    dependencyTree.conditions.forEach(condition => {
      condition.list.forEach(item => checkUnfoundItems('condition', item.conditionKey));
    });

    setOrphanKeys(unfoundItems);
    setMultipleSurveysKeys(multipleSurveysItems);
    setDependencyTree(dependencyTree);
  };

  const handleClick = () => {
    setLoading(true);
  };

  const handleDependencies = () => {
    getSurveyDependencies();
    getConditionsAndReplaces();
  };

  const isReady = useMemo(() => {
    return !!targetDependencies && !!targetEntity && dependencies.length > 0 && dependencyTree.name !== '';
  }, [targetDependencies, targetEntity, dependencies, dependencyTree]);

  useEffect(() => {
    setLoading(false);
  }, [isReady]);

  useEffect(() => {
    if (loading) handleDependencies();
  }, [loading]);

  const getSurveyDependencies = () => {
    let target = undefined;
    let keysToSurveys = undefined; // Link between Registry or Doc and Questions
    let documentQuestions = undefined;
    // let registrySheetQuestions = undefined;

    // let allActionRights = contentfulData.surveys.map(survey => {
    //   return {
    //     value: survey.id,
    //     label: survey.name,
    //   };
    // });

    switch (targetDependencies.type) {
      case 'survey':
        target = contentfulData.surveys.find(survey => survey.name === targetDependencies.name);
        break;
      case 'registry':
        target = contentfulData.registries.find(registry => registry.name === targetDependencies.name);
        // let registryResult = getRelationsBetweenSurveysAndRegistries(contentfulData.surveys, contentfulData.registries, allActionRights);
        // keysToSurveys = registryResult.keysToSurveys;
        // documentQuestions = getAllRegistriesDependencies(contentfulData.registries).linksKeyToRegistry;
        break;
      case 'document':
        target = contentfulData.documents.find(document => document.name === targetDependencies.name);
        // let docResult = getRelationsBetweenSurveys(contentfulData.surveys, contentfulData.documents, allActionRights);
        // keysToSurveys = docResult.keysToSurveys;
        // documentQuestions = getAllDocDependencies(contentfulData.documents);
        break;
    }
    for (const [key, value] of Object.entries(documentQuestions)) {
      //Boucle sur l'ensemble des questions des documents
      if (key === target.id) {
        //Si le document est celui recherché
        if (Array.isArray(value)) {
          value.map(documentQuestion => {
            // On boucle sur l'ensemble des questions
            for (const [key, value] of Object.entries(keysToSurveys)) {
              //On boucle sur l'ensemble des questionnaires
              if (Array.isArray(value)) {
                let linkedQuestionToSurvey = value.find(surveyQuestion => surveyQuestion === documentQuestion.key); // Pour une question du document on a trouvé un questionnaire associé
                let surveyAlreadyExist = linkedSurveys.find(linkedSurvey => linkedSurvey.id === key);
                if (linkedQuestionToSurvey && surveyAlreadyExist) {
                  surveyAlreadyExist.linkedQuestions.push({
                    name: linkedQuestionToSurvey,
                    entryId: documentQuestion.id,
                    previousEntries: target.id,
                  });
                } else if (linkedQuestionToSurvey) {
                  linkedSurveys.push({
                    id: key,
                    name: contentfulData.surveys.find(survey => survey.id === key).name,
                    linkedQuestions: [{
                      name: linkedQuestionToSurvey,
                      entryId: documentQuestion.id,
                      previousEntries: target.id,
                    }],
                  });
                }
              }
            }
          });
        }
      }
    }
    setDependencies(linkedSurveys);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <DashboardGenerationContainer>
          <Content>
            <>
              <FixInputContainer>
                <InputContainer>
                  <InputComponent
                    name={'document_choice'}
                    type={'select'}
                    label={'Choix du Document'}
                    options={getDocOptions()}
                    onChange={handleTargetDoc}
                    clearable={true.toString()}
                    sharable={false}
                  />
                </InputContainer>
                <InputContainer>
                  <InputComponent
                    key={'registry_choice'}
                    name={'registry_choice'}
                    type={'select'}
                    label={'Choix du Registre'}
                    options={getRegistriesOptions()}
                    onChange={handleTargetRegistry}
                    clearable={true.toString()}
                    sharable={false}
                  />
                </InputContainer>
                <InputContainer>
                  <InputComponent
                    key={'entity_choice'}
                    name={'entity_choice'}
                    type={'select'}
                    label={'Choix de l\'entreprise'}
                    options={getEntitiesOptions()}
                    onChange={handleTargetEntity}
                    sharable={false}
                  />
                </InputContainer>
              </FixInputContainer>
              {isReady && (
                <VersionChoiceContainer>
                  <ListTitle>Questionnaires associés (condition + replace) :</ListTitle>
                  <ListTitle>Replaces (Clé - Texte à remplacer - Remplacé par) :</ListTitle>
                  <ListTitle>Conditions (clé - valeur - réponse) :</ListTitle>
                  <div></div>
                  <Legend>
                    <div>associé à pluieurs questionnaires</div>
                    <div>aucun questionnaire asocié</div>
                  </Legend>
                  <Legend>
                    <div>associé à pluieurs questionnaires</div>
                    <div>aucun questionnaire asocié</div>
                  </Legend>
                  <DependencyListContainer>
                    {dependencies.map((linkedSurvey, index) => {
                      return (
                        <React.Fragment key={index}>
                          <VersionItem>
                            <SurveyTitle>
                              {linkedSurvey.name} ({linkedSurvey.linkedQuestions.length})
                            </SurveyTitle>
                            {linkedSurvey.linkedQuestions.map((linkedQuestion, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <VersionItem>
                                    {linkedQuestion.name}{' '}
                                    <a
                                      href={`${baseLink}${linkedQuestion.entryId}?previousEntries=${linkedQuestion.previousEntries}`}
                                      target={'_blank'}>
                                      <FiLink />
                                    </a>
                                  </VersionItem>
                                </React.Fragment>
                              );
                            })}
                          </VersionItem>
                        </React.Fragment>
                      );
                    })}
                  </DependencyListContainer>
                  <DependencyListContainer>
                    {dependencyTree.replaces.map((replace, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div>
                            <SurveyTitle>
                              {targetDependencies.type === 'registry' ? 'Onglet :' : 'Document part :'} {replace.worksheet} (
                              {replace.list.filter(item => item.replaceValue !== undefined).length} / {replace.list.length})
                            </SurveyTitle>
                            {replace.list.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <DependencyRow
                                    isOrphan={orphanKeys.includes(item.replaceKey)}
                                    isOnMultipleSurveys={multipleSurveysKeys.includes(item.replaceKey)}>
                                    {item.replaceKey} - {item.textToReplace} - {String(item.replaceValue)}{' '}
                                    <a href={`${baseLink}${item.entryId}`} target={'_blank'}>
                                      <FiLink />
                                    </a>
                                  </DependencyRow>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </DependencyListContainer>
                  <DependencyListContainer>
                    {dependencyTree.conditions.map((condition, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div>
                            <SurveyTitle>
                              {targetDependencies.type === 'registry' ? 'Onglet :' : 'Document part :'} {condition.worksheet} (
                              {condition.list.filter(item => item.valueInSurvey !== undefined).length} / {condition.list.length})
                            </SurveyTitle>
                            {condition.list.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <DependencyRow
                                    isOrphan={orphanKeys.includes(item.conditionKey)}
                                    isOnMultipleSurveys={multipleSurveysKeys.includes(item.conditionKey)}>
                                    {item.conditionKey} - {item.conditionValue} - {String(item.valueInSurvey)}{' '}
                                    <a href={`${baseLink}${item.entryId}`} target={'_blank'}>
                                      <FiLink />
                                    </a>
                                  </DependencyRow>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </DependencyListContainer>
                </VersionChoiceContainer>
              )}
            </>
            <ActionContainer>
              <Button onClick={handleClick} label={'Générer les dépendances'}
                      disabled={!targetDependencies || !targetEntity} />
            </ActionContainer>
          </Content>
        </DashboardGenerationContainer>
      )}
    </>
  );
};

const VersionChoiceContainer = styled.div`
    display: grid;
    flex-direction: column;
    flex: 1;
    row-gap: 20px;
    column-gap: 20px;
    overflow-y: auto;
    padding: 20px 20px;
    grid-template-columns: 1fr 1fr 1fr;

    ::-webkit-scrollbar {
        width: 9px;
        height: 9px;
    }

    ::-webkit-scrollbar-thumb {
        background: #e0e0e0;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #c7c7c7;
    }

    ::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: 10px;
        box-shadow: inset 2.1px -1px 12px 0px #f0f0f0;
    }
`;

const DashboardGenerationContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Content = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`;

const InputContainer = styled.div``;

const ListTitle = styled.div`
    font-size: 1.3em;
    font-weight: 500;
    color: #187ead;
    border-bottom: 1px solid #e9edf7;
    margin-bottom: 10px;
    width: max-content;
    height: 22px;
`;

const Legend = styled.div`
    > div:first-child {
        color: #f49d1d;
    }

    color: #dd0615;
`;

const SurveyTitle = styled.div`
    font-size: 1.1em;
    font-weight: 500;
    width: max-content;
`;

const DependencyListContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`;

const FixInputContainer = styled.div`
    display: flex;
    column-gap: 15px;

    > * {
        flex: 1;
    }
`;

const VersionItem = styled.div``;

const ActionContainer = styled.div`
    display: flex;
    column-gap: 15px;
    justify-content: flex-end;
    padding: 0 100px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    align-items: center;
    min-height: 70px;
`;

const DependencyRow = styled.div<{ isOrphan: boolean; isOnMultipleSurveys: boolean }>`
    ${props =>
            props.isOrphan &&
            `
         color: #dd0615;
         
  `}
    ${props =>
            props.isOnMultipleSurveys &&
            `
         color: #f49d1d;
         
  `}
`;
