import { customFetch } from '@utils/utils';

export const fetchAccountSubContractors = async (accountId: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/getAccountSubcontractors`,
    'POST',
    JSON.stringify({
      accountId,
    }),
    true
  );
};

export const fetchLegalOfficerClientsSubContractors = async (legalOfficerEmail: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/getLegalOfficerClientsSubContractors`,
    'POST',
    JSON.stringify({
      legalOfficerEmail,
    }),
    true
  );
};

export const fetchAllSubContractors = async (): Promise<any> => {
  return await customFetch(`${process.env.REACT_APP_HEROKU_TARGET}/audit/getAllSubContractors`, 'GET', null, true);
};

export const downloadAllSubContractorsInventory = async (finality?: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/downloadFullInventory`,
    'POST',
    JSON.stringify({
      finality,
    }),
    true
  );
};

export const downloadFilteredSubContractorsInventory = async (
  accountId: string,
  entityId?: string,
  finality?: string,
  relatedVersionId?: string
): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/downloadInventory`,
    'POST',
    JSON.stringify({
      accountId,
      entityId,
      finality,
      relatedVersionId,
    }),
    true
  );
};

export const downloadLegalOfficerClientInventory = async (legalOfficerEmail: string, finality?: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/downloadLegalOfficerClientInventory`,
    'POST',
    JSON.stringify({
      legalOfficerEmail,
      finality,
    }),
    true
  );
};

export const fetchEntityAuditActions = async (entityId: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/getEntityActions`,
    'POST',
    JSON.stringify({
      entityId,
    }),
    true
  );
};

export const fetchAccountAuditActions = async (accountId: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/getAccountActions`,
    'POST',
    JSON.stringify({
      accountId,
    }),
    true
  );
};

export const createSubContractor = async (params: {
  accountId: string;
  entityId: string;
  finality: string;
  contactEmail: string;
  subContractorName: string;
}): Promise<any> => {
  return await customFetch(`${process.env.REACT_APP_HEROKU_TARGET}/audit/subContractor/create`, 'POST', JSON.stringify(params), true);
};

export const downgradeAuditAction = async (accountId: string, actionId: string): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/downgrade`,
    'POST',
    JSON.stringify({
      accountId,
      actionId,
    }),
    true
  );
};

export const updateSubContractorReAuditFrequency = async (
  accountId: string,
  subContractorId: string,
  reminderFreq: number,
  contactEmail?: string
): Promise<any> => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/subContractor/reminders`,
    'POST',
    JSON.stringify({
      accountId,
      subContractorId,
      reminderFreq,
      contactEmail,
    }),
    true
  );
};

export const updateSubContractorFinality = async (subContractorId, finality) => {
  return await customFetch(
    `${process.env.REACT_APP_HEROKU_TARGET}/audit/subContractor/updateFinality`,
    'POST',
    JSON.stringify({ subContractorId, finality }),
    true
  );
};

export const validateOrRefuseAudit = async (accountId, entityId, actionId, type, versionProductId, relatedProductId, subContractorId) => {
  return type === 'validate'
    ? await customFetch(
        `${process.env.REACT_APP_HEROKU_TARGET}/audit/validate`,
        'POST',
        JSON.stringify({ accountId, entityId, actionId, versionProductId, relatedProductId, subContractorId }),
        true
      )
    : await customFetch(
        `${process.env.REACT_APP_HEROKU_TARGET}/audit/refuse`,
        'POST',
        JSON.stringify({ accountId, entityId, actionId, versionProductId, relatedProductId, subContractorId }),
        true
      );
};
