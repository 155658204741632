import { LoaderProps } from './Loader.type';
import { LoaderContainer, LoaderElt } from './Loader_.style';

const Loader = ({ loaderOnly = false }: LoaderProps) => {
  return (
    <>
      {!loaderOnly && (
        <LoaderContainer>
          <LoaderElt />
        </LoaderContainer>
      )}
      {loaderOnly && <LoaderElt />}
    </>
  );
};

export default Loader;
