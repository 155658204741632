import { COLORS } from '../../utils/constants';
import Spinner from '../spinner/Spinner';
import styled, { css } from 'styled-components';

export const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  row-gap: 20px;
  border: 1px dashed ${COLORS.MediumGrey};

  span {
    font-size: 1.3rem;
    width: 500px;
    max-width: 90%;
    text-align: center;

    b {
      color: ${COLORS.NiceBlue};
    }
  }

  svg {
    color: ${COLORS.MediumGrey};
    width: 60px;
    height: 60px;
    animation: swing 0.8s ease 0.3s;
  }
`;

export const EmptyMessages = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  span {
    text-align: center;
  }

  svg {
    color: ${COLORS.MediumGrey};
    width: 60px;
    height: 60px;
    animation: swing 0.8s ease 0.3s;
  }
`;

export const InputContainer = styled.div`
  width: 50%;
`;

export const Label = styled.div<{ size: number }>`
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
  flex: ${({ size }) => (size ? size : 1)};
  &:first-child {
    padding-left: 5px;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid ${COLORS.MediumGrey};
  max-height: 80%;
`;

export const MessagesContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow-y: scroll;
`;

export const MessagesHeaders = styled.div`
  display: flex;
  width: calc(100% - 30px);
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid ${COLORS.MediumGrey};
  padding: 10px 15px;
`;

export const MessageRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Nunito, sans-serif;
  width: calc(100% - 30px);
  border-bottom: 1px solid ${COLORS.MediumGrey};
  padding: 20px 15px;
`;

export const MessageStatus = styled.div<{ size: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  color: ${COLORS.DarkGrey};
  flex: ${({ size }) => (size ? size : 1)};
  gap: 5px;
  padding-left: 5px;
`;

export const MessageSubject = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Reminder = styled.div`
  height: 50px;
  width: 100%;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;
  border: 1px solid #e9edf7;
  column-gap: 20px;
`;

export const ReminderActions = styled.button<{ alreadySent: boolean }>`
  color: white;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid ${props => (props.alreadySent ? COLORS.softRed : '#00b894')};
  border-radius: 12px;
  background-color: ${props => (props.alreadySent ? COLORS.softRed : '#00b894')};
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
  pointer-events: ${props => (props.alreadySent ? 'none' : null)};

  &:hover {
    opacity: 1;
  }
`;

export const ReminderActionsContainer = styled.div`
  display: flex;
  column-gap: 20px;
  max-width: 25%;
`;

export const RemindersContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 10px;
`;

export const ReminderName = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.125em;
  max-width: 70%;
`;

export const ReminderRecipient = styled.div`
  font-size: 0.8em;
  font-weight: 500;
  color: ${COLORS.MediumGrey};
`;

// Utils
const getColor = (status: 'success' | 'error') => {
  switch (status) {
    case 'success':
      return '#87d37c';
    case 'error':
      return '#ef3e36';
    default:
      return '#e9edf7';
  }
};
export const ReminderResultContainer = styled.div<{ status: 'success' | 'error' }>`
  height: 50px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  column-gap: 20px;
  border: 1px solid ${props => getColor(props.status)};
  color: ${props => getColor(props.status)};
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    font-family: 'Baloo 2';
    font-size: 1.2em;
    width: 100%;
    padding: 10px 10px 10px 10px;

    &:focus-visible {
      outline: none;
    }
  }
`;

export const SpinnerContainer = styled(Spinner)`
  margin-right: 35px;
`;

export const StatusIcon = styled.div<{ status: string }>`
  display: flex;
  border-radius: 50%;
  background-color: ${({ status }) => (status === 'delivered' ? `${COLORS.xlsx}` : `${COLORS.Red}`)};
  height: 10px;
  width: 10px;
`;

export const Tab = styled.div<{ selected: boolean }>`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1em;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  font-weight: 500;
  border: ${COLORS.MediumGrey} solid 1px;
  border-radius: 5px;
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    background-color: ${COLORS.darkBlue};
    color: white;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${COLORS.darkBlue};
      color: white;
    `}
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export const Title = styled.h3`
  color: ${COLORS.DarkGrey};
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
`;

export const Value = styled.div<{ size: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  color: ${COLORS.DarkGrey};
  flex: ${({ size }) => (size ? size : 1)};
  gap: 5px;
  min-width: 0;
`;
