import React from 'react';
import { IconsProps } from './Icons.type';

const CheckIcon: React.FC<IconsProps> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 75 75' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.2502 39.225L30.6002 49.575L54.7502 25.425'
        stroke='url(#paint0_linear_289_941)'
        stroke-width='6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M37.5 72C56.5537 72 72 56.5537 72 37.5C72 18.4462 56.5537 3 37.5 3C18.4462 3 3 18.4462 3 37.5C3 56.5537 18.4462 72 37.5 72Z'
        stroke='url(#paint1_linear_289_941)'
        stroke-width='6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <defs>
        <linearGradient id='paint0_linear_289_941' x1='37.5002' y1='25.425' x2='37.5002' y2='49.575' gradientUnits='userSpaceOnUse'>
          <stop stop-color='#326C82' />
          <stop offset='1' stop-color='#3BA7D1' />
        </linearGradient>
        <linearGradient id='paint1_linear_289_941' x1='37.5' y1='3' x2='37.5' y2='72' gradientUnits='userSpaceOnUse'>
          <stop stop-color='#326C82' />
          <stop offset='1' stop-color='#3BA7D1' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CheckIcon;
