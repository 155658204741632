import {
  GlobalLayout,
  TitleContainer,
  ContentContainer,
  ButtonContainer,
  Previous,
  CustomButton,
} from '@components/chat/ConfirmationModal_.style';
import { useTranslation } from 'react-i18next';

export interface ConfirmationModalProps {
  show?: boolean;
  onClose: () => void;
  onSubmit: (event: any) => Promise<void>;
}


export const ConfirmationModal = ({ show, onClose, onSubmit }: ConfirmationModalProps) => {
  const { t } = useTranslation();
  return (
    <>
      {show && (
        <GlobalLayout>
          <TitleContainer>
            {t('chat.modal.title')}
          </TitleContainer>
          <ContentContainer>
            <div>
              {t('chat.modal.content')}
            </div>
            <ButtonContainer>
              <CustomButton onClick={onSubmit} label={t('chat.modal.confirm')} />
              <Previous onClick={onClose}>{t('common.cancel')}</Previous>
            </ButtonContainer>
          </ContentContainer>
        </GlobalLayout>
      )}
     </>
  )
}