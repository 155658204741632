import { t } from 'i18next';
import { FiFilter } from 'react-icons/fi';
import { RiLoopRightLine } from 'react-icons/ri';

import Container from '../container/Container';

import { FiltersCustom, NbFilterCustom, ResetButtonCustom, TextCustom } from './filtersButton_.style';
import { CONTAINER_TEST_ID, FILTERS_BUTTON_TEST_ID, FILTERS_COUNT_TEST_ID, RESET_BUTTON_TEST_ID } from './FiltersButton.constant';

const FiltersButton = ({ setSearchValue, onFilterClick, filtersCount, onFilterReset }) => {
  return (
    <Container flex data-testid={CONTAINER_TEST_ID}>
      <FiltersCustom onClick={onFilterClick} appliedFilters={filtersCount > 0} data-testid={FILTERS_BUTTON_TEST_ID}>
        <TextCustom>{t('common.filters')}</TextCustom>
        <FiFilter size={20} />
        {filtersCount > 0 && <NbFilterCustom data-testid={FILTERS_COUNT_TEST_ID}>{filtersCount}</NbFilterCustom>}
      </FiltersCustom>
      {!!filtersCount && (
        <ResetButtonCustom
          onClick={() => {
            onFilterReset();
            setSearchValue('');
          }}
          data-testid={RESET_BUTTON_TEST_ID}>
          <TextCustom>{t('actionPlan.reset_filters')}</TextCustom>
          <RiLoopRightLine size={20} />
        </ResetButtonCustom>
      )}
    </Container>
  );
};

export default FiltersButton;
