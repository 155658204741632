import { forwardRef, useMemo, useRef, useState } from 'react';
import { IoIosCloseCircle, IoIosLink, IoMdCheckmarkCircle } from 'react-icons/io';

import { ChipContainer, IconContainer, ChipText, DeleteIconContainer, HoverIconContainer, CustomTippy } from './Chip_.style';
import { ChipProps } from './Chip.type';

const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      label,
      icon: Icon,
      iconSize,
      color = '#000000',
      labelColor,
      variant = 'filled',
      onClick,
      backgroundColor,
      height = '30px',
      onDelete,
      deleteIcon: DeleteIcon,
      onCopy,
      showTooltip = false,
      tooltipTitle,
      enableCopy = false,
      successCopyTooltip = 'Copied!',
      bolder = false,
      borderRadius = '16px',
      width,
      justifyContent,
      fontSize,
      fontFamily,
      ...props
    },
    ref
  ) => {
    const chipRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef(null);

    const [copied, setCopied] = useState(false);
    const [hover, setHover] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleDelete = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onDelete?.();
    };

    const handleHover = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (!copied) {
        onCopy?.()?.then(() => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        });
      }
    };

    const tooltipContent = useMemo(() => (copied ? successCopyTooltip : tooltipTitle), [copied]);

    const onMouseEnter = () => {
      setHover(true);
      showTooltip && setTooltipOpen(true);
    };

    const onMouseLeave = () => {
      setHover(false);
      showTooltip && tooltipOpen && setTooltipOpen(false);
      setCopied(false);
    };

    return (
      <>
        <ChipContainer
          ref={chipRef || ref}
          variant={variant}
          color={color}
          backgroundColor={backgroundColor}
          height={height}
          onClick={onClick || (enableCopy ? handleHover : undefined)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          bolder={bolder}
          borderRadius={borderRadius}
          width={width}
          justifyContent={justifyContent}
          {...props}>
          {Icon && (
            <IconContainer iconOnly={!label}>
              <Icon size={iconSize || 22} />
            </IconContainer>
          )}
          {label && (
            <ChipText labelColor={labelColor} hasIcon={!!Icon} fontSize={fontSize} fontFamily={fontFamily}>
              {label}
            </ChipText>
          )}
          {enableCopy && hover && (
            <HoverIconContainer onClick={handleHover}>
              {!copied ? <IoIosLink size={20} /> : <IoMdCheckmarkCircle size={20} color='green' />}
            </HoverIconContainer>
          )}
          {onDelete && (
            <DeleteIconContainer onClick={handleDelete}>{DeleteIcon ? <DeleteIcon size={22} /> : <IoIosCloseCircle size={22} />}</DeleteIconContainer>
          )}
        </ChipContainer>
        {showTooltip && (
          <CustomTippy content={tooltipContent} ref={tooltipRef} reference={chipRef} arrow={false} visible={tooltipOpen} interactiveDebounce={75} />
        )}
      </>
    );
  }
);

export default Chip;

