import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { IoIosArrowDown, IoIosArrowUp, IoMdCheckmark } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';
import { TbEye } from 'react-icons/tb';
import { BsFillPinFill } from 'react-icons/bs';

import { auditsStatus } from '@components-new/common/constants';
import { Chip, Container, Loader } from '@components-new/atoms';

import { selectActiveAccount } from '@reducers/account.reducer';

import { downgradeAuditAction, fetchEntityAuditActions } from '@api/audit';
import useDidUpdate from '@hooks/useDidUpdate';
import { fetchContentfulEntryById, fetchSubContractorResponses } from '@utils/api';

import { NoData } from '../AuditsAndInventory_.styles';
import ModalAudit from '../components/ModalAudit';

import { ST_ENTRY_ID } from './Audit.constants';
import {
  ActionsContainer, ContainerTitle, DowngradeButton, Link, List, ListItem, NoSubContractor, SubContractorName, LegalOpsElement, AuditsCompletedText, SeparatorCompleted
} from './Audit_.style';

const Audits = ({ currentAccount, selectedEntity }) => {
  const { t } = useTranslation();

  const activeAccount = useSelector(selectActiveAccount);

  const [audits, setAudits] = useState(auditsStatus);
  const [actionActive, setActionActive] = useState<any>('');
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedFinality, setSelectedFinality] = useState<{ label: string; value: string } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [subContractorData, setSubContractorData] = useState(null);
  const [surveyData, setSurveyData] = useState(null);
  const [emailReminder, setEmailReminder] = useState(null);
  // Fetched Actions from API
  const [entityAuditActions, setEntityAuditActions] = useState(null)

  useEffect(() => {
    const updatedAudits: any = Object.keys(audits).reduce((acc, status) => {
      acc[status] = {
        ...audits[status],
        actions: [],
        actionsToVerify: []
      };
      return acc;
    }, {});

    entityAuditActions
      ?.filter((item) => item.category === "Audit" && item.active)
      .forEach((action) => {
        if ((action.status === 'verify' && action.subContractor?.status === 'ongoing') || action.subContractor?.status === 'conforme' || action.subContractor?.status === 'non_conforme') {
          updatedAudits['completed'].actionsToVerify.push(action);
        } else {
          const actionStatus = action.status === 'verify' ? 'completed' : action.status
          if (updatedAudits[actionStatus]) {
            updatedAudits[actionStatus].actions.push(action);
          }
        }
      });
    setAudits(updatedAudits);
  }, [entityAuditActions]);

  // Fetch Subcontractor survey data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchContentfulEntryById(ST_ENTRY_ID);
        setSurveyData(result);
      } catch (err) {
        console.log("🚀 ~ err:", err)
      }
    };
    fetchData();
  }, []);

  useDidUpdate(async () => {
    const subContractorId = selectedAction?.subContractor?.id;
    if (!subContractorId) {
      setSubContractorData(null);
      return;
    }

    try {
      const result = await fetchSubContractorResponses(subContractorId);
      if (result && Object.keys(result).length > 0) {
        setSubContractorData(result);
        setEmailReminder(selectedAction?.subContractor?.contactEmail || result?.responsableEmail);
      }
      else {
        setSubContractorData(null);
      }
    } catch (error) {
      console.error("Error fetching subcontractor responses:", error);
      setSubContractorData(null);
    }
  }, [selectedAction?.subContractor?.id]);

  const openPendingAudit = ((subContractor) => {
    if (actionActive === subContractor) {
      setActionActive('')
    } else {
      setActionActive(subContractor)
    }
  })

  const openModalAudit = ((subContractor) => {
    setSelectedAction(subContractor)
    setDisplayModal(true)
    setSelectedFinality({ label: subContractor.finality, value: subContractor.finality })
  })

  const Action = ({ action, index, statusName }) => {
    return (
      <>
        {statusName === 'pending' ?
          <ListItem
            key={index}
            active={actionActive === action?.subContractor && true}
            status={statusName}
            onClick={() => statusName === 'pending' && openPendingAudit(action.subContractor)}>
            <Container flex alignItems={actionActive === action?.subContractor ? 'flex-start' : 'center'} justifyContent='space-between'>
              <Container>
                <SubContractorName>{action?.subContractor?.name}</SubContractorName>
              </Container>
              <Container flex gap='5px'>
                {action.isLegalOpsCreation && <LegalOpsElement fontSize="0.8em" mt="-2px"><BsFillPinFill size={12} /><span>{t('common.legalOps')}</span></LegalOpsElement>}
                {actionActive === action?.subContractor ? <IoIosArrowUp size='25px' color='#3D3D3D' /> : <IoIosArrowDown size='25px' color='#C4C3C2' />}
              </Container>
            </Container>
            {actionActive === action?.subContractor &&
              <>
                <Container mr='25px'>
                  {actionActive === action?.subContractor && <NoSubContractor>{t('audits.noSubContractor')}</NoSubContractor>}
                </Container>
                <Container flex justifyContent='center'>
                  <DowngradeButton onClick={() => handleAuditActionDowngrade(action.actionId)}><span>{t('common.downgrade').toUpperCase()}</span><RxCross2 size='20px' /></DowngradeButton>
                </Container>
              </>
            }
          </ListItem> :

          statusName === 'ongoing' && action.subContractor?.status === 'ongoing' ?
            <ListItem
              onClick={() => openModalAudit(action)}
              key={index}
              active={false}
              status={statusName}>
              <Container flex justifyContent='space-between'>
                <SubContractorName>{action?.subContractor?.name}</SubContractorName>
                {action.isLegalOpsCreation && <LegalOpsElement fontSize="0.8em" mt="-2px"><BsFillPinFill size={12} /><span>{t('common.legalOps')}</span></LegalOpsElement>}
              </Container>
            </ListItem> :

            statusName === 'completed' || action.subContractor?.status !== 'ongoing' ?
              <ListItem
                onClick={() => openModalAudit(action)}
                key={index}
                active={false}
                status={statusName}>
                <Container flex justifyContent='space-between'>
                  <Container flex gap='10px' alignItems='center'>
                    <SubContractorName>{action?.subContractor?.name}</SubContractorName>
                    {action.subContractor?.status === 'non_conforme' && <Chip icon={RxCross2} color={'#FF7675'} backgroundColor={'#FFF1F1'} iconSize={15} height='20px' borderRadius='5px'></Chip>}
                    {action.subContractor?.status === 'conforme' && <Chip icon={IoMdCheckmark} color={'#00C6A1'} backgroundColor={'#00C6A114'} iconSize={15} height='20px' borderRadius='5px'></Chip>}
                  </Container>
                  {action.isLegalOpsCreation && <LegalOpsElement fontSize="0.8em" mt="-2px"><BsFillPinFill size={12} /><span>{t('common.legalOps')}</span></LegalOpsElement>}
                </Container>
                <Container mt='10px' alignItems='center' flex>
                  {(statusName === 'completed' || action.subContractor?.status !== 'ongoing') ? <Link>{t('audits.viewSurvey')}</Link> : <Link>{t('audits.checkSurvey')}<span><TbEye color='#757575' size={20} /></span></Link>}
                </Container>
              </ListItem> :
              <></>
        }
      </>
    );
  };

  const handleAuditActionDowngrade = async (actionId) => {
    try {
      setLoading(true)
      const result = await downgradeAuditAction(currentAccount._id, actionId)
      if (result.error) {
        throw new Error(result.error);
      }
      setEntityAuditActions(prevState => prevState.filter(action => action.actionId !== actionId))

    } catch (error) {
      console.error("Error downgrading action:", error);
      toast.error(t('audits.downgradeError'))
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchAudits();
  }, []);

  useDidUpdate(async () => {
    fetchAudits();
  }, [selectedEntity?._id, currentAccount?._id]);

  const fetchAudits = async () => {
    if (selectedEntity?._id) {
      try {
        const result = await fetchEntityAuditActions(selectedEntity._id);
        if (result.error) {
          throw new Error(result.error);
        }
        if (result.success) {
          setEntityAuditActions(result.data);
        }
      } catch (error) {
        console.error("Error fetching entity audit actions:", error);
        toast.error(t('audits.error'))
      }
    }
    else {
      setEntityAuditActions([]);
    }
  }

  useDidUpdate(() => {
    if (selectedAction) {
      const targetedAction = entityAuditActions?.find(action => action.actionId === selectedAction.actionId)
      if (targetedAction) {
        setSelectedAction(targetedAction)
      } else {
        setSelectedAction(null)
      }
    }
  }, [entityAuditActions]);

  if (loading) {
    return (
      <Container flex justifyContent='center' mt='200px'>
        <Loader loaderOnly />
      </Container>
    )
  }

  if (!selectedEntity) {
    return (
      <NoData dangerouslySetInnerHTML={{
        __html: t('audits.noEntity', { account: currentAccount?.companyName }),
      }} />
    )
  }

  return (
    <Container flex gap='23px' height='100%' mt='33px' position='relative' >
      {Object.entries(audits).map(([statusName, status]) => (
        <ActionsContainer>
          <ContainerTitle color={status.color} backgroundColor={status.backgroundColor}>
            <Chip icon={status.icon} color={status.color} backgroundColor='transparent' /><span>{t(status.label)} ({status.actions.length + status.actionsToVerify?.length})</span>
          </ContainerTitle>
          <List>
            {status.actionsToVerify && status.actionsToVerify
              .map((action, index) => {
                return (
                  <Action key={action.id || index} action={action} index={index} statusName={statusName} />
                );
              })}
            {statusName === 'completed' && status.actions.length !== 0 &&
              <AuditsCompletedText>
                <SeparatorCompleted />
                <span>
                  {t('audits.auditsCompleted', { number: status.actions.length })}
                </span>
                <SeparatorCompleted />
              </AuditsCompletedText>
            }
            {status.actions
              .map((action, index) => {
                return (
                  <Action key={action.id || index} action={action} index={index} statusName={statusName} />
                );
              })}
          </List>
        </ActionsContainer>
      ))
      }
      {displayModal &&
        <ModalAudit
          view={'audit'}
          selectedEntity={selectedEntity}
          selectedFinality={selectedFinality}
          setSelectedFinality={setSelectedFinality}
          selectedSubContractorOrAction={selectedAction}
          activeAccount={activeAccount}
          currentAccount={currentAccount}
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
          subContractorData={subContractorData}
          setSubContractorData={setSubContractorData}
          surveyData={surveyData}
          emailReminder={emailReminder}
          setEmailReminder={setEmailReminder}
          fetchAudits={fetchAudits}
          setLoading={setLoading}
        />
      }
    </Container>
  );
};

export default Audits;