import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Modal } from '../modal/Modal';
import { Button } from '../Button';

import { paths } from '@routes/routes.constants';

import { disconnect } from '@utils/utils';
import { logout } from '@utils/api';

export interface ModalErrorProps {
  show?: boolean;
  onClose: () => void;
  onClick: () => void;
  onChangeLoginMode: (loginMode) => void;
  errors: any;
}

export const ModalError = ({ show, onClose, onClick, errors, onChangeLoginMode }: ModalErrorProps) => {
  const history = useHistory();
  const onMessageClick = e => {
    if (errors?.resetPassword) {
      onChangeLoginMode('passwordReset');
    }
  };
  return (
    <>
      {show && (
        <Modal
          onClose={onClose}
          size={{ height: undefined, width: '40%' }}
          show={show}
          title={errors?.title || 'Erreur'}
          hideClose={errors?.reconnectButton}>
          <ErrorModalContainer>
            <ErrorMessageContainer>
              <ErrorMessage onClick={onMessageClick} dangerouslySetInnerHTML={{ __html: errors?.message }} />
            </ErrorMessageContainer>
            {errors?.reconnectButton && (
              <Button
                type='primary'
                label={'Me reconnecter'}
                onClick={async () => {
                  await logout();
                  disconnect();
                  history.push(paths.login);
                }}
              />
            )}
          </ErrorModalContainer>
        </Modal>
      )}
    </>
  );
};

const ErrorModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 40px);
`;

const ErrorMessage = styled.div`
  pointer-events: none;
  font-size: 1.2em;
  text-align: center;

  b {
    pointer-events: auto;
    cursor: pointer;
    color: #1179a0;
  }
`;
