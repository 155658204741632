import makeAnimated from 'react-select/animated';

import { Menu } from '@mui/material';

import { StyledMenuItem, StyledSelect } from './FiltersMenu_.style';
import { FiltersMenuProps } from './FiltersMenu.type';

const FiltersMenu = ({ handleFilterChange, items, filtersMenAnchorEl, setFiltersMenAnchorEl }: FiltersMenuProps) => {
  const animatedComponents = makeAnimated();
  const isFilterMenuOpen = Boolean(filtersMenAnchorEl);

  const handleFiltersClose = () => {
    setFiltersMenAnchorEl(null);
  };

  return (
    <Menu
      style={{ marginTop: '1rem' }}
      anchorEl={filtersMenAnchorEl}
      id='filters-menu'
      open={isFilterMenuOpen}
      onClose={handleFiltersClose}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
      {items.map((item, index) => (
        <StyledMenuItem key={index}>
          <StyledSelect
            menuPosition='fixed'
            placeholder={item.placeholder}
            closeMenuOnSelect
            components={animatedComponents}
            isMulti
            value={item.value}
            options={item.options}
            onChange={selectedOptions => handleFilterChange(selectedOptions, item.targetFilter)}
          />
        </StyledMenuItem>
      ))}
    </Menu>
  );
};

export default FiltersMenu;
