import styled from 'styled-components';

export const IconContainer = styled.div<{ index?: number }>`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  & > * {
    margin-left: -15px;
    position: relative;
    background: linear-gradient(to right, transparent 20%, white 80%);
    border-radius: 0 45% 0 0;
    path {
      transform: translateX(10%);
    }
  }
`;
