import { useTranslation } from 'react-i18next';

import { FlagIcon } from '@components-new/atoms';

import { SVG } from './LanguageSwitcher_.style';

import './index.scss';

// TODO! : further optimization and upgrade the styles from scss => styled-component & more animation control

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (language: 'en' | 'fr') => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <div className='dropdown'>
        <input type='checkbox' id='dropdown' />

        <label className='dropdown__face' htmlFor='dropdown'>
          <div className='dropdown__text'>
            <FlagIcon country={i18n.language === 'en' ? 'gb' : i18n.language} />
          </div>

          <div className='dropdown__arrow'></div>
        </label>

        <ul className='dropdown__items'>
          <li onClick={() => handleLanguageChange('en')}>
            <FlagIcon country='gb' />
          </li>
          <li onClick={() => handleLanguageChange('fr')}>
            <FlagIcon country='fr' />
          </li>
        </ul>
      </div>

      <SVG>
        <filter id='goo'>
          <feGaussianBlur in='SourceGraphic' stdDeviation='5' result='blur' />
          <feColorMatrix in='blur' type='matrix' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7' result='goo' />
          <feBlend in='SourceGraphic' in2='goo' />
        </filter>
      </SVG>
    </>
  );
};

export default LanguageSwitcher;