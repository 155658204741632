import { PlaceholderLine, PlaceholderSquare, PlaceholderRounded } from './Placeholder_.style';
import { PlaceholderProps } from './Placeholder.type';

const variants = { line: PlaceholderLine, rounded: PlaceholderRounded, square: PlaceholderSquare };
const Placeholder = ({ variant, ...props }: PlaceholderProps) => {
  const Component = variants[variant];
  if (!Component || !props.width) {
    return null;
  }

  return <Component {...props} data-testid={`placeholder-${variant}`} />;
};

export default Placeholder;
