import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { MdClose } from 'react-icons/md';
import { HiPencil } from 'react-icons/hi2';

import { selectActiveAccount, selectAllAccounts } from '../../reducers/account.reducer';

import { Input } from '@components-new/molecules';
import { Avatar, Container } from '@components-new/atoms';
import { Button } from '../Button';

import { isEmailValid } from '@utils/utils';
import { USER_ROLES } from '@utils/constants';

import {
  AlreadySharedContainer,
  AlreadySharedTitle,
  ButtonContainer,
  Chips,
  ChipsButton,
  ChipsContainer,
  CommentInputContainer,
  IconWrapper,
  InputContainer,
  ModalDescription,
  Span,
  StyledInput,
  UserRow,
} from './ModalShare.style';

const ModalShareListUser = ({
  type,
  shareID,
  isAlreadyShared,
  onClose,
  handleShare,
  emailInputList,
  setEmailInputList,
  commentInput,
  setCommentInput,
}) => {
  const { t } = useTranslation();

  const allAccounts = useSelector(selectAllAccounts);
  const activeAccount = useSelector(selectActiveAccount);

  const userList = useMemo(() => {
    return [...activeAccount?.users]
      .sort((a, b) => isAlreadyShared(a, type, shareID) - isAlreadyShared(b, type, shareID))
      ?.map(user => {
        return {
          value: user.email,
          label: user.email,
          user,
        };
      });
  }, [activeAccount]);

  const handleCommentChange = e => {
    setCommentInput(e.target.value);
  };
  const removeEmailFromList = emailToDelete => {
    setEmailInputList(emailInputList.filter(email => email.value !== emailToDelete));
  };

  const isEmailAlreadyUsed = email => {
    if (email === '') return false;
    let emailFound = false;
    allAccounts.forEach(account => {
      if (account.users.some(user => user.email === email)) {
        emailFound = true;
      }
    });
    if (emailInputList.some(emailCheck => emailCheck === email)) {
      emailFound = true;
    }
    return emailFound;
  };

  const isChecked = user => {
    return emailInputList.some(userCopy => userCopy.user?._id === user?._id || userCopy.value === user);
  };

  const getShareTypeLabel = itemType => {
    switch (itemType) {
      case 'questions':
        return t('modalShare.share_question');
      case 'surveySteps':
        return t('modalShare.share_step');
      case 'surveyVersions':
        return t('modalShare.share_survey');
      case 'surveys':
        return t('modalShare.share_survey');
      case 'entities':
        return t('modalShare.share_entity');
      case 'actions':
        if (shareID === 'selectedActions') {
          return t('modalShare.share_actions');
        } else {
          return t('modalShare.share_action');
        }
      default:
        return '';
    }
  };

  const formatOption = ({ value, label, user }, formatOptionLabelMeta) => {
    return (
      <UserRow key={formatOptionLabelMeta.id} checked={isChecked(user)} alreadyShared={(user?._id && isAlreadyShared(user, type, shareID)) ?? false}>
        <Container flex alignItems='center' gap='7px'>
          <Avatar
            name={user ? (user?.name !== '' ? user?.name : user?.email) : value}
            firstName={user?.firstName}
            userId={user?._id ?? ''}
            size='small'
          />
          <span> {user ? (user?.firstName ? user?.name + ' ' + user?.firstName : user?.name || user?.email) : label}</span>
          {user?.role === USER_ROLES.AccountOfficer && <span data-element='accountOfficer'>Référent RGPD</span>}
        </Container>
        {user?._id && isAlreadyShared(user, type, shareID) && <span data-element='sharedElement'>{t('modalShare.already_shared')}</span>}
      </UserRow>
    );
  };

  const filterOptions = (option: { label: string; value: string; data: any }, input: string) => {
    if (input) {
      return option.value.includes(input) || option.label.includes(input);
    }
    return true;
  };

  const customNoOptionsMessage = ({ inputValue }) => {
    if (!isEmailValid(inputValue)) {
      return <Span color='red'>{t('modalShare.invalid_email_error')}</Span>;
    } else if (isEmailAlreadyUsed(inputValue)) {
      return <Span color='red'>{t('modalShare.email_already_used_error')}</Span>;
    }
  };

  const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    return (
      inputValue.trim().length > 0 &&
      !selectOptions.some(option => filterOptions(option, inputValue)) &&
      isEmailValid(inputValue) &&
      !isEmailAlreadyUsed(inputValue)
    );
  };

  const handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'select-option' || actionMeta.action === 'create-option') {
      const newOption = newValue[newValue.length - 1];
      const optionExists = emailInputList.some(option => option.value === newOption.value);
      if (!optionExists) {
        setEmailInputList([...emailInputList, newValue[newValue.length - 1]]);
      }
    }
  };

  const formatCreateLabel = inputValue => {
    return `${t('common.add')} "${inputValue}"`;
  };

  const isOptionDisabled = (option: { label: string; value: string; user: any; __isNew__: boolean }) => {
    if (!option.__isNew__) {
      return isChecked(option.user) || isAlreadyShared(option?.user, type, shareID);
    }
  };

  return (
    <>
      <InputContainer>
        <ModalDescription
          dangerouslySetInnerHTML={{
            __html: t('modalShare.modal_description', { type: getShareTypeLabel(type) }),
          }}
        />
        <Input
          type={'select'}
          name='emailShare'
          placeholder={t('modalShare.add_email_placeholder')}
          onChange={handleChange}
          value={[]}
          options={userList}
          isMulti
          isClearable
          closeMenuOnSelect={true}
          isCreatable
          formatOptionLabel={formatOption}
          formatCreateLabel={formatCreateLabel}
          filterOption={filterOptions}
          isOptionDisabled={isOptionDisabled}
          styles={{
            control: base => ({ ...base, padding: '0 0 0 10px', borderRadius: '10px' }),
            menu: base => ({ ...base, marginBottom: 76 }),
            option: base => ({
              ...base,
              padding: '5px 10px',
              backgroundColor: 'transparent',
              ':active': { backgroundColor: 'transparent' },
              color: 'black',
            }),
            menuList: base => ({
              ...base,
              padding: '15px 5px !important',
              maxHeight: '200px',
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            container: base => ({ ...base, zIndex: 9999 }),
          }}
          noOptionsMessage={customNoOptionsMessage}
          isValidNewOption={isValidNewOption}
        />
        <Container transition='minHeight 0.3s ease' minHeight='50px'>
          <ChipsContainer>
            {emailInputList?.map(
              (email, index) =>
                email && (
                  <Chips key={index}>
                    <Avatar
                      name={email.user ? (email.user?.name !== '' ? email.user?.name : email.user?.email) : email.value}
                      firstName={email.user?.firstName}
                      userId={email.user?._id ?? ''}
                      size='small'
                    />
                    {email.value}
                    <ChipsButton onClick={() => removeEmailFromList(email.value)}>
                      <MdClose />
                    </ChipsButton>
                  </Chips>
                )
            )}
          </ChipsContainer>
        </Container>
      </InputContainer>
      <Container>
        <Container m='10px 0px 15px 0'>
          <AlreadySharedTitle>{t('modalShare.already_shared_users', { type: getShareTypeLabel(type) })}</AlreadySharedTitle>
        </Container>
        {activeAccount?.users?.filter(user => isAlreadyShared(user, type, shareID)).length > 0 && (
          <AlreadySharedContainer>
            {activeAccount?.users
              ?.filter(user => isAlreadyShared(user, type, shareID))
              ?.map((user, index) => (
                <UserRow key={index} checked={isChecked(user)} alreadyShared={(user?._id && isAlreadyShared(user, type, shareID)) ?? false}>
                  <Container flex alignItems='center' gap='7px'>
                    <Avatar
                      name={user && user?.name !== '' ? user?.name : user?.email}
                      firstName={user?.firstName}
                      userId={user?._id ?? ''}
                      size='small'
                    />
                    <span> {user?.firstName ? user?.name + ' ' + user?.firstName : user?.name || user?.email}</span>
                    {user?.role === USER_ROLES.AccountOfficer && <span data-element='accountOfficer'>Référent RGPD</span>}
                  </Container>
                </UserRow>
              ))}
          </AlreadySharedContainer>
        )}
      </Container>

      {/* TODO : implement the Input Component with type 'text' when it's completed instead */}
      <CommentInputContainer>
        <IconWrapper>
          <HiPencil size={20} color='#757575' />
        </IconWrapper>
        <StyledInput placeholder={t('modalShare.add_comment_placeholder')} onChange={handleCommentChange} value={commentInput} />
      </CommentInputContainer>
      <ButtonContainer>
        <Button type='text' label={t('common.cancel')} onClick={onClose} />
        <Button type='primary' disabled={emailInputList.length < 1} label={t('common.share')} onClick={handleShare} />
      </ButtonContainer>
    </>
  );
};

export default ModalShareListUser;
