import { Modal } from '../modal/Modal';

import styled from 'styled-components';
import { Button } from '../Button';
import { useState } from 'react';
import { InputComponent } from '../form/InputComponent';
import { useTranslation } from 'react-i18next';
import { COLORSV2 } from '@utils/constants';

export interface ModalAddUserProps {
  show?: boolean;
  onClose: () => void;
  addUser: (user: any) => void;
  isEmailAlreadyUsed: (email: string) => boolean;
}

export const ModalAddUser = ({ show, onClose, addUser, isEmailAlreadyUsed }: ModalAddUserProps) => {
  const { t } = useTranslation();
  const [newUser, setNewUser] = useState({ firstName: '', lastName: '', email: '' });

  const handleNewUserInfosChange = e => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value.trim(),
    });
  };

  return (
    <>
      <Modal onClose={onClose} show={show} title={t('modalAddUser.admin.add_admin')} size={{ height: '50%' }}>
        <WarningContainer>{t('modalAddUser.admin.add_admin_warning')}</WarningContainer>

        <BasicModalContainer>
          <InputContainer>
            <InputComponent
              focus={true}
              placeholder={t('modalAddUser.placeholders.last_name')}
              sharable={false}
              label={`${t('common.name')} :`}
              type={'text'}
              onChange={handleNewUserInfosChange}
              name={'lastName'}
              value={newUser?.lastName}
            />
            <InputComponent
              focus={true}
              placeholder={t('modalAddUser.placeholders.first_name')}
              sharable={false}
              label={`${t('common.first_name')} :`}
              type={'text'}
              onChange={handleNewUserInfosChange}
              name={'firstName'}
              value={newUser?.firstName}
            />
          </InputContainer>
          <InputComponent
            focus={true}
            placeholder={t('modalAddUser.placeholders.email')}
            sharable={false}
            label={`${t('common.email')} :`}
            type={'text'}
            onChange={handleNewUserInfosChange}
            name={'email'}
            error={isEmailAlreadyUsed(newUser?.email) && t('common.email_already_used')}
            value={newUser?.email}
          />
          <ButtonContainer>
            <Button type='text' onClick={onClose} label={t('common.cancel')} />
            <Button
              type='primary'
              disabled={isEmailAlreadyUsed(newUser.email) || !newUser.firstName || !newUser.lastName || !newUser.email}
              onClick={() => addUser(newUser)}
              label={t('common.validate')}
            />
          </ButtonContainer>
        </BasicModalContainer>
      </Modal>
    </>
  );
};

const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

const WarningContainer = styled.div`
  font-size: 1.3em;
  padding-top: 35px;
  padding-bottom: 20px;
  color: ${COLORSV2.primary};
  font-family: Nexa;
  font-weight: 400;
  padding: 0;
`;
