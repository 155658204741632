import React from 'react';
import { FaFileInvoice } from 'react-icons/fa';
import { DownloadContainer } from './FileDowload.styles';

interface FileDownloadProps {
  fileUrl: string;
  fileName: string;
  getFileContent: (fileUrl: string) => Promise<Response>;
}
const FileDownload: React.FC<FileDownloadProps> = ({ fileUrl, fileName, getFileContent }) => {
  const handleDownload = async () => {
    try {
      const response = await getFileContent(fileUrl);

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);

      link.click();

      window.URL.revokeObjectURL(url);
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier:', error);
    }
  };

  return (
    <DownloadContainer onClick={handleDownload}>
      <FaFileInvoice />
    </DownloadContainer>
  );
};

export default FileDownload;
