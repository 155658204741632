import { COLORSV2 } from '@utils/constants';
import styled from 'styled-components';

export const DataTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  .data-table {
    overflow: auto;

    .header {
      /* styles for the header */
    }

    .row {
      align-items: center;
      column-gap: 0.75%;
      padding: 0.5rem;
    }
    .row:not(last-child) {
      margin-bottom: 0.5rem;
    }
    .row:not(first-child) {
      margin-top: 0.5rem;
    }
  }
`;

export const InvoicesTable = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const RowContainer = styled.div<{ backlog?: boolean }>`
  opacity: ${({ backlog }) => (backlog ? '50%' : '100%')};
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const InvoicesContainer = styled.div`
  padding-top: 35px;
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled.div`
  font-size: 1.3em;
  padding-top: 35px;
  padding-bottom: 20px;
  color: ${COLORSV2.lightOrange};
  font-family: Nexa Bold;
  font-weight: 700;
`;

export const EmptyTableContainer = styled.div`
  margin: 15% auto 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const EmptyText = styled.h2`
  color: black;
`;

export const EmptyTextDescription = styled.div`
  color: black;
  text-align: center;
`;
