import { COLORS, Z_INDEX } from '../../utils/constants';
import styled, { css } from 'styled-components';

const LoaderContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Badge = styled.div<{ color?: string; hover?: boolean; selected?: boolean }>`
  border-radius: 10px;
  padding: 5px 10px;
  background-color: ${({ color }) => color ?? COLORS.Squash};
  font-size: 13px;
  white-space: nowrap;
  color: white;
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: 1px solid transparent;

  ${({ hover, color, selected }) =>
    hover &&
    css`
      border: 1px solid ${COLORS.MediumGrey};
      color: ${COLORS.MediumGrey};
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      background-color: transparent;

      &:hover {
        color: ${COLORS.Black};
        border: 1px solid ${color};
      }

      ${selected &&
      css`
        color: white;
        border: 1px solid ${COLORS.Squash};
        background-color: ${COLORS.Squash};

        &:hover {
          color: white;
          border: 1px solid ${COLORS.Squash};
        }
      `}
    `}
`;

const Counter = styled.div``;
const ListSlider = styled.div`
  overflow-y: auto;

  padding-left: 30px;
  position: relative;
  flex: 1;
`;

const ListTitle = styled.div`
  font-size: 1.2em;
  background-color: white;
  padding-bottom: 5px;
  margin-left: -25px;
  z-index: 999;
  span {
    color: ${COLORS.MediumGrey};
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  position: relative;
  padding-right: 15px;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  &:before {
    content: '';
    position: absolute;
    left: -20px;
    width: 2px;
    background-color: ${COLORS.MediumGrey};
    bottom: 30px;
    top: 0;
    z-index: ${Z_INDEX.sidenav + Z_INDEX.below};
  }
`;

const User = styled.div<{ color: string }>`
    color: ${COLORS.Black};
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 1.3em;
    font-weight: 400;
    color: ${({ color }) => color ?? COLORS.Black};
    font-family: 'Roboto', sans-serif;
    position: relative;
    width: 100%;
    user-select: none;

    span {
        transition: margin-left 0.2s ease-in-out;
        padding: 5px 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border-radius: 5px;
        display: flex;
        align-items: center;

        svg {
            margin-right: 5px;
            min-width: 20px;
            min-height: 20px;
        }
    }

    &:hover {
        span {
            margin-left: 3px;
        }
    }

    &:after {
        content: '';
        position: absolute;
        width: 18px;
        height: 15px;
        left: -20px;
        top: 55%;
        transform: translateY(-100%);
        border-bottom-left-radius: 10px;
        border-left: 2px solid ${COLORS.MediumGrey};
        border-bottom: 2px solid ${COLORS.MediumGrey};
    }
`;

const Name = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const UserListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 80px);
  row-gap: 20px;
  flex: 1;
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: fit-content;
  width: 100%;

  input {
    border-radius: 5px;
    border: 1px solid #d4d4d4;
    font-family: 'Baloo 2';
    font-size: 1.2em;
    width: 100%;
    padding: 10px 0 10px 10px;

    &:focus-visible {
      outline: none;
    }
  }

  svg {
    font-size: 20px;
    position: absolute;
    left: 6px;
    top: 5px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export { Badge, ListSlider, List, LoaderContainer, User, UserListContainer, SearchContainer, FilterContainer, Counter, ListTitle, Name };
