import styled, { css } from 'styled-components';
import { COLORSV2 } from '@utils/constants';

export const Content = styled.div<{ applyPadding?: boolean; customPadding?: string; position?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 35%;
    background-color: ${COLORSV2.lightPink};
    z-index: -1; // Place behind content
  }

  ${({ applyPadding, customPadding }) =>
    applyPadding &&
    css`
      padding: ${customPadding ? customPadding : '3rem'};
    `}
  position: ${({ position }) => position};
`;

export const PageTitle = styled.h1`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  svg {
    margin-left: 1rem;
    transition: transform 0.3s ease;
  }

  color: ${COLORSV2.primary};
  font-family: Biennale;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
