import { useEffect, useState } from 'react';
import Div from 'src/components/div/Div';
import { fireActionsDueDateReminder, fireUnchangedActionStatusReminder, getRemindersByAccount, getMessagesByEmails } from 'src/utils/api';
import {
  BasicModalContainer,
  ButtonContainer,
  Container,
  Empty,
  EmptyMessages,
  InputContainer,
  Label,
  LoaderContainer,
  MessageContainer,
  MessagesContent,
  MessagesHeaders,
  MessageRow,
  MessageStatus,
  MessageSubject,
  Reminder,
  ReminderActions,
  ReminderActionsContainer,
  RemindersContainer,
  ReminderName,
  ReminderRecipient,
  ReminderResultContainer,
  SearchContainer,
  SpinnerContainer,
  StatusIcon,
  Tab,
  TabContainer,
  Title,
  Value,
} from './Reminders.style';
import { BsSendCheck, BsSendX } from 'react-icons/bs';
import { InputComponent } from '../form/InputComponent';
import { useSelector } from 'react-redux';
import { selectSelectedAccount, sendReminder } from '../../reducers/account.reducer';
import { PiWarningLight } from 'react-icons/pi';
import Loader from '../loader/loader';
import { store } from '../../store/store';
import { MdOutlineBusinessCenter, MdRefresh } from 'react-icons/md';
import { Button } from '../Button';
import { IReminderElement } from './Reminders.type';
import { Modal } from '@components/modal/Modal';

const Reminders = () => {
  const selectedAccount = useSelector(selectSelectedAccount);
  const [selectedEntity, setSelectedEntity] = useState(undefined);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [remindersList, setRemindersList] = useState(undefined);
  const [search, setSearch] = useState(null);
  const [viewMode, setViewMode] = useState('table');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedReminder, setSelectedReminder] = useState<any>({});
  const initialLoadingStates = {};

  REMINDERS.forEach(element => {
    initialLoadingStates[element.id] = { isLoading: false, success: false, error: false };
  });

  const [loadingStates, setLoadingStates] = useState<{ [key: string]: { isLoading: boolean; success: boolean; error: boolean } }>(
    initialLoadingStates
  );

  const fetchMessages = async () => {
    try {
      const emails = selectedAccount.users.map(user => user.email);
      if (emails.length === 0) return;
      const sendgridMessages = await getMessagesByEmails(emails);
      if (sendgridMessages && sendgridMessages.messages) {
        const messages = sendgridMessages.messages.filter(Boolean);
        setMessages(messages);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };


  const isSearchedString = () => search !== null && search !== '';

  const getFilteredMessages = () =>
    !isSearchedString()
      ? messages
      : messages.filter(
          message =>
            message['to_email']
              ?.toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              ?.includes(
                search
                  ?.toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
              )
        );

  const refreshMessages = async () => {
    setLoading(true);
    await fetchMessages();
    setLoading(false);
  };

  const updateLoadingState = (id: string, isLoading: boolean, success: boolean, error: boolean) => {
    setLoadingStates(prevLoadingStates => ({
      ...prevLoadingStates,
      [id]: { isLoading, success, error },
    }));
  };

  const handleLaunchReminder = async (element: IReminderElement) => {
    setSelectedReminder(element);
    setShowModal(true);
  };

  const launchReminder = element => {
    const { id, action } = element;
    updateLoadingState(id, true, false, false);
    action()
      .then(result => {
        updateLoadingState(id, false, true, false);
      })
      .catch(error => {
        updateLoadingState(id, false, false, true);
      })
      .finally(() => {
        setShowModal(false);
        setTimeout(() => {
          updateLoadingState(id, false, false, false);
        }, 2000);
      });
  };

  const getEntitiesOption = () =>
    selectedAccount?.entities?.map(entity => ({
      value: entity.name,
      label: entity.name,
    }));

  const handleEntitySelection = (name, value, id, e) => {
    const selectedEntity = selectedAccount?.entities?.find(entity => entity.name === value);
    if (selectedEntity) {
      setSelectedEntity(selectedEntity);
    }
    return;
  };

  const isReminderActive = reminder => {
    return reminder.isLinkedToAction
      ? remindersList.entityReminders.some(item => item.templateId === reminder.templateId && item.entityId === selectedEntity._id)
      : remindersList?.accountReminders.some(item => item === reminder.templateId);
  };

  const isReminderAlreadySent = (reminderId, linkedToAction) => {
    if (linkedToAction) {
      if (!selectedEntity) {
        return false;
      }
      if (selectedEntity.actionPlan.actions.length) {
        return selectedEntity.actionPlan.actions.some(action => {
          if (action.sentMails.length) {
            return action.sentMails.some(sentMail =>
              Array.isArray(reminderId) ? reminderId.includes(sentMail.templateId) : sentMail.templateId === reminderId
            );
          }
        });
      }
      return false;
    } else {
      return selectedAccount.users.some(user => user.activity?.sentMails?.some(sentMail => sentMail.templateId === reminderId));
    }
  };

  const handleSearchChange = e => {
    const value = e.target.value;
    setSearch(value === '' ? null : value);
  };

  const getReminders = (element, index) => {
    const { isLoading, success, error } = loadingStates[element.id];
    return (
      <Div key={index} flex gap='15px'>
        <Reminder>
          <ReminderName>
            {element.label}
            <ReminderRecipient>{element.recipients}</ReminderRecipient>
          </ReminderName>
          <ReminderActionsContainer>
            {isLoading ? (
              <SpinnerContainer color='#326c82' />
            ) : error || success ? (
              <ReminderResultContainer status={error ? 'error' : success ? 'success' : null}>
                {success && (
                  <Div flex alignItems='center' gap='15px'>
                    <BsSendCheck size={24} />
                    <p>Reminder launched successfully</p>
                  </Div>
                )}
                {error && (
                  <Div flex alignItems='center' gap='15px'>
                    <BsSendX size={24} />
                    <p>An error occurred</p>
                  </Div>
                )}
              </ReminderResultContainer>
            ) : (
              <ReminderActions
                alreadySent={isReminderAlreadySent(element.templateId, element.isLinkedToAction)}
                onClick={() => handleLaunchReminder(element)}>
                {isReminderAlreadySent(element.templateId, element.isLinkedToAction) ? 'Déjà lancé' : 'Lancer'}
              </ReminderActions>
            )}
          </ReminderActionsContainer>
        </Reminder>
      </Div>
    );
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      setRemindersList(await getRemindersByAccount(selectedAccount._id));
      await refreshMessages();
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Container>
        <TabContainer>
          <Tab selected={viewMode === 'table'} onClick={() => setViewMode('table')}>
            Messages envoyés
          </Tab>

          <Tab selected={viewMode === 'actions'} onClick={() => setViewMode('actions')}>
            Relances à effectuer
          </Tab>
        </TabContainer>
        {viewMode === 'table' && (
          <>
            <Div flex alignItems='center' gap='15px'>
              <SearchContainer>
                <input onChange={handleSearchChange} placeholder={`Rechercher un destinataire...`} type='text' />
              </SearchContainer>
              <Button label={'refresh'} outlined={true} icon={MdRefresh} onClick={() => refreshMessages()} />
            </Div>
            <MessageContainer>
              {loading ? (
                <LoaderContainer>
                  <Loader loaderOnly={true} />
                </LoaderContainer>
              ) : (
                <>
                  <MessagesHeaders>
                    <Label size={2}>STATUS</Label>
                    <Label size={4}>DESTINATAIRE</Label>
                    <Label size={4}>OBJET</Label>
                    <Label size={3}>DATE & HEURE</Label>
                    <Label size={1}>VUS</Label>
                    <Label size={1}>CLICKS</Label>
                  </MessagesHeaders>

                  <MessagesContent>
                    {messages && messages?.length !== 0 ? (
                      getFilteredMessages().map((message, index) => {
                        return (
                          message && (
                            <MessageRow key={index}>
                              <MessageStatus size={2}>
                                <StatusIcon status={message['status']} />
                                {message['status']}
                              </MessageStatus>
                              <Value size={4}>To: {message['to_email']}</Value>
                              <Value size={4}>
                                <MessageSubject>{message['subject']}</MessageSubject>
                              </Value>
                              <Value size={3}>{message['last_event_time']}</Value>
                              <Value size={1}>{message['opens_count']}</Value>
                              <Value size={1}>{message['clicks_count']}</Value>
                            </MessageRow>
                          )
                        );
                      })
                    ) : (
                      <EmptyMessages>
                        <PiWarningLight />
                        <span>Aucun message de relance n'a été envoyé.</span>
                        <span>Plusieurs minutes sont nécessaires à la mise à jour des informations</span>
                        <span>Cliquez sur le bouton pour raffraichir les données.</span>
                      </EmptyMessages>
                    )}
                  </MessagesContent>
                </>
              )}
            </MessageContainer>
          </>
        )}
        {viewMode === 'actions' && (
          <>
            <Title>Relances de compte</Title>
            {REMINDERS.filter(reminder => !reminder.isLinkedToAction && isReminderActive(reminder)).length ? (
              <RemindersContainer>
                {REMINDERS.filter(reminder => !reminder.isLinkedToAction && isReminderActive(reminder)).map((element, index) =>
                  getReminders(element, index)
                )}
              </RemindersContainer>
            ) : (
              <Empty>
                <PiWarningLight />
                <span>Aucune relance a envoyer.</span>
              </Empty>
            )}
            <Title>Relances d'entité</Title>
            <InputContainer>
              <InputComponent
                onChange={handleEntitySelection}
                label={'Séléctionnez une entité'}
                type={'select'}
                options={getEntitiesOption()}
                sharable={false}
              />
            </InputContainer>
            {selectedEntity ? (
              REMINDERS.filter(reminder => reminder.isLinkedToAction && isReminderActive(reminder)).length ? (
                <RemindersContainer>
                  {REMINDERS.filter(reminder => reminder.isLinkedToAction && isReminderActive(reminder)).map((element, index) =>
                    getReminders(element, index)
                  )}
                </RemindersContainer>
              ) : (
                <Empty>
                  <PiWarningLight />
                  <span>Aucune relance a envoyer.</span>
                </Empty>
              )
            ) : (
              <Empty>
                <MdOutlineBusinessCenter />
                <span>
                  Veuillez sélectionner <b>une entité</b> pour afficher ses relances. Une fois sélectionnée, vous pourrez <b>lancer des relances</b>{' '}
                  manuellement.
                </span>
              </Empty>
            )}
          </>
        )}
      </Container>
      <Modal
        alignment={'center'}
        size={{ width: '30%' }}
        show={showModal}
        title={`Êtes-vous sûr·e de vouloir activer cette relance ?`}
        onClose={() => setShowModal(false)}>
        <BasicModalContainer>
          <p>Cette action entrainera l'envoi d'un mail d'information au client. </p>
          <ButtonContainer>
            <Button type='text' onClick={() => setShowModal(false)} label={'Annuler'} />
            <Button type='primary' onClick={() => launchReminder(selectedReminder)} label={'Valider'} />
          </ButtonContainer>
        </BasicModalContainer>
      </Modal>
    </>
  );
};

export default Reminders;

// Constants
export const REMINDERS: IReminderElement[] = [
  {
    id: 'ONBOARDING_REMINDER_CLIENT',
    label: "Relance pour prise de RDV d'onboarding.",
    recipients: 'Clients (Admin(s) + Référent RGPD)',
    templateId: 'd-1a38b1252eaf49c3a0b71b100c58315f',
    isLinkedToAction: false,
    action: async () =>
      store.dispatch(
        sendReminder({
          reminderId: 'd-1a38b1252eaf49c3a0b71b100c58315f',
          wizardRedirection: true,
        })
      ),
  },
  {
    id: 'SURVEYS_COMPLETION_REMINDER_1_NO_COMPLETION_CLIENT',
    label: 'Première relance pour la complétion des questionnaires - Questionnaires vides.',
    recipients: 'Clients (Admin(s) + Référent RGPD)',
    templateId: 'd-710f06fa32bf4e24b69f17ea497c00c3',
    isLinkedToAction: false,
    action: async () =>
      store.dispatch(
        sendReminder({
          reminderId: 'd-710f06fa32bf4e24b69f17ea497c00c3',
          wizardRedirection: false,
        })
      ),
  },
  {
    id: 'SURVEYS_COMPLETION_REMINDER_2_NO_COMPLETION_CLIENT',
    label: 'Deuxième relance pour la complétion des questionnaires - Questionnaires vides.',
    recipients: 'Clients (Admin(s) + Référent RGPD)',
    templateId: 'd-b1a51bb86cc34d48865dd70c4fb20f4c',
    isLinkedToAction: false,
    action: async () =>
      store.dispatch(
        sendReminder({
          reminderId: 'd-b1a51bb86cc34d48865dd70c4fb20f4c',
          wizardRedirection: false,
        })
      ),
  },
  {
    id: 'SURVEYS_COMPLETION_REMINDER_1_PARTIAL_COMPLETION_CLIENT',
    label: 'Première relance pour la complétion des questionnaires - Questionnaires complétés partiellement.',
    recipients: 'Clients (Admin(s) + Référent RGPD)',
    templateId: 'd-3377344855ea44ec9170ca57db54930e',
    isLinkedToAction: false,
    action: async () =>
      store.dispatch(
        sendReminder({
          reminderId: 'd-3377344855ea44ec9170ca57db54930e',
          wizardRedirection: false,
        })
      ),
  },
  {
    id: 'SURVEYS_COMPLETION_REMINDER_2_PARTIAL_COMPLETION_CLIENT',
    label: 'Deuxième relance pour la complétion des questionnaires - Questionnaires complétés partiellement.',
    recipients: 'Clients (Admin(s) + Référent RGPD)',
    templateId: 'd-7c8b56198c114a66963db41995347a28',
    isLinkedToAction: false,
    action: async () =>
      store.dispatch(
        sendReminder({
          reminderId: 'd-7c8b56198c114a66963db41995347a28',
          wizardRedirection: false,
        })
      ),
  },
  {
    id: 'SURVEYS_COMPLETION_REMINDER_GUEST',
    label: 'Relance pour la complétion des questionnaires.',
    recipients: 'Invités',
    templateId: 'd-544a2122ea81420db89dc69473a14fa2',
    isLinkedToAction: false,
    action: async () =>
      store.dispatch(
        sendReminder({
          reminderId: 'd-544a2122ea81420db89dc69473a14fa2',
          wizardRedirection: false,
        })
      ),
  },
  {
    id: 'ACTION_PLAN_ACTIVATION_REMINDER_1_CLIENT',
    label: "Première relance pour la prise de RDV du point d'étapes.",
    recipients: 'Clients (Admin(s) + Référent RGPD)',
    templateId: 'd-898592a74cb44f518cc2bc2733aac21f',
    isLinkedToAction: false,
    action: async () =>
      store.dispatch(
        sendReminder({
          reminderId: 'd-898592a74cb44f518cc2bc2733aac21f',
          wizardRedirection: true,
        })
      ),
  },
  {
    id: 'ACTION_PLAN_ACTIVATION_REMINDER_2_CLIENT',
    label: "Deuxième relance pour la prise de RDV du point d'étapes.",
    recipients: 'Clients (Admin(s) + Référent RGPD)',
    templateId: 'd-839b57c31d094030ba001bc51676f9a9',
    isLinkedToAction: false,
    action: async () =>
      store.dispatch(
        sendReminder({
          reminderId: 'd-839b57c31d094030ba001bc51676f9a9',
          wizardRedirection: true,
        })
      ),
  },
  {
    id: 'ACTION_PLAN_ACTIVATION_REMINDER_3_CLIENT',
    label: "Troisième relance pour la prise de RDV du point d'étapes.",
    recipients: 'Clients (Admin(s) + Référent RGPD)',
    templateId: 'd-bd56dbb95cb748dcb111d7ae63a8e55e',
    isLinkedToAction: false,
    action: async () =>
      store.dispatch(
        sendReminder({
          reminderId: 'd-bd56dbb95cb748dcb111d7ae63a8e55e',
          wizardRedirection: true,
        })
      ),
  },
  {
    id: 'UNCHANGED_ACTION_STATUS',
    label: 'Relance des actions avec un status inchangées pour plus de 3 semaines.',
    recipients: 'Clients (Admin(s) + Référent RGPD) et Invités',
    templateId: ['d-19a6ef7ecb7e40e69139793fb9b7deb0', 'd-1b2d8c76d8bb42978b9716f12541a3d9'],
    isLinkedToAction: true,
    action: fireUnchangedActionStatusReminder,
  },
  {
    id: 'PASSED_ACTION_DUE_DATE',
    label: "Relance des actions avec une date d'échéance dépassé.",
    recipients: 'Clients (Admin(s) + Référent RGPD) et Invités',
    templateId: 'd-392e6c8391ff498e89036788e0ab5c86',
    isLinkedToAction: true,
    action: fireActionsDueDateReminder,
  },
];
