import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  createAccount,
  login,
  resetDbPassword,
  updateRemaining,
  updateUserPassword,
  createUserPassword,
  updateNotifications,
  getDbAccounts,
  updateAccountRights,
  updateEntityStep,
  actionPlanActivation,
  updateAction,
  createAction,
  getBaseUrl,
  createEntity,
  deleteDbEntity,
  updateDbResponses,
  updateSubContractorInfo,
  validateOrRefuseAuditLegalOps,
  addNotification,
  getActions,
  addComment,
  sendReminderToSelectedAccount,
  updateCommentState,
  shareActionPlan,
  shareItemToUser,
  editAction,
  editDirectoryAction,
  deleteAction,
  deleteDirectoryAction,
  updateAccountResponses,
  updateAccountEntities,
  getClientActions,
  getDirectoryActions,
} from '../utils/api';
import { setLoading, setCurrentEntity, setCurrentVersion } from './app.reducer';
import { NewAccountInfos } from '../components/dashboard-create-user/DashboardCreateUser';
import {
  customFetch,
  getActionConditions,
  getEntityTotalFillingPercentage,
  groupSharedQuestionsBySurvey,
  retrieveSurveysResponses,
  setSessionToken,
  setSessionUser,
  verifyResponsesMatchConditions,
} from '../utils/utils';
import { validateOrRefuseAudit } from '@api/audit';

//-------------------- ACCOUNT --------------------
export const getAllAccounts = createAsyncThunk('account/GET_ALL_USERS', async () => {
  let allAccounts = await getDbAccounts();
  let parsedAccounts = allAccounts.result.map(account => {
    let rights = account.actionRights ? account.actionRights : {};
    return { ...account, actionRights: rights };
  });
  return parsedAccounts;
});

export const updateRights = createAsyncThunk(
  'account/UPDATE_RIGHTS',
  async (
    options: {
      accountId: string;
      actionRights: any;
    },
    thunkApi
  ) => {
    let state = thunkApi.getState();
    let dispatch = thunkApi.dispatch;
    dispatch(setLoading(true));
    let account = selectAccount(state);
    let isSelectedAccount = account._id === options.accountId;
    let updatedAccount = await updateAccountRights(options.accountId, options.actionRights);
    dispatch(setLoading(false));
    if (isSelectedAccount) {
      thunkApi.dispatch(setAccount(updatedAccount));
    }

    return updatedAccount;
  }
);

const updateCurrentEntity = (updatedAccount, thunkApi) => {
  let state: any = thunkApi.getState();
  let dispatch = thunkApi.dispatch;
  let currentEntityId = state.app.currentEntity?._id;
  let updatedEntity = updatedAccount.entities.find(entity => entity._id === currentEntityId);
  dispatch(setCurrentEntity(updatedEntity));
};

const updateCurrentVersion = (updatedAccount, thunkApi) => {
  let state: any = thunkApi.getState();
  let dispatch = thunkApi.dispatch;
  let currentEntityId = state.app.currentEntity?._id;
  let updatedEntity = updatedAccount.entities.find(entity => entity._id === currentEntityId);
  let currentVersionId = state.app.currentVersion?._id;
  let currentSurveyId = state.app.currentSurvey?.id;
  let currentSurveyVersions = updatedEntity.responses.find(survey => survey.surveyId === currentSurveyId).versions;
  let updatedVersion = currentSurveyVersions.find(version => version._id === currentVersionId);
  dispatch(setCurrentVersion(updatedVersion));
};

export const updateEntities = createAsyncThunk('account/UPDATE_ENTITIES', async (data: { [key: string]: any }) => {
  return await updateAccountEntities(data);
});

//-------------------- ENTITY --------------------
export const validateAccount = createAsyncThunk('account/VALIDATE_ACCOUNT', async (options: { accountId: string }) => {
  return await customFetch(`${getBaseUrl()}/account/validateAccount`, 'POST', JSON.stringify(options), true);
});

export const validateEntity = createAsyncThunk(
  'account/VALIDATE_ENTITY',
  async (options: { adminId: string; accountId: string; entityId: string }) => {
    return await customFetch(`${getBaseUrl()}/entity/validate`, 'POST', JSON.stringify(options), true);
  }
);

export const refuseEntity = createAsyncThunk(
  'account/REFUSE_ENTITY',
  async (options: { adminId: string; accountId: string; entityId: string; reason: string }) => {
    return await customFetch(`${getBaseUrl()}/entity/refuse`, 'POST', JSON.stringify(options), true);
  }
);

export const updateStep = createAsyncThunk(
  'account/UPDATE_ENTITY_STEP',
  async (data: { adminId; accountId; entityId; stepId; isValidated; activeStep }) => {
    return await updateEntityStep(data.adminId, data.accountId, data.entityId, data.stepId, data.isValidated);
  }
);

export const createNewEntity = createAsyncThunk('account/CREATE_ENTITY', async (data: { accountId: string; userId: string; name: string }) => {
  return await createEntity(data.accountId, data.userId, data.name);
});

export const addVersionToSurvey = createAsyncThunk(
  'account/CREATE_SURVEY',
  async (options: { accountId: string; entityId: string; surveyId: string; name: string }, thunkApi) => {
    let updatedAccount = await customFetch(`${getBaseUrl()}/survey/create`, 'POST', JSON.stringify(options), true);
    updateCurrentEntity(updatedAccount, thunkApi);
    return updatedAccount;
  }
);

export const renameEntity = createAsyncThunk('account/RENAME_ENTITY', async (data: { accountId: string; entityId: string; entityName: string }) => {
  return await customFetch(`${getBaseUrl()}/entity/rename`, 'POST', JSON.stringify(data), true);
});

export const renameSurvey = createAsyncThunk(
  'account/RENAME_SURVEY',
  async (options: { entityId: string; surveyId: string; versionId: string; newName: string }, thunkApi) => {
    let updatedAccount = await customFetch(`${getBaseUrl()}/survey/rename`, 'POST', JSON.stringify(options), true);
    updateCurrentEntity(updatedAccount, thunkApi);
    return updatedAccount;
  }
);

export const cloneSurvey = createAsyncThunk(
  'account/CLONE_SURVEY',
  async (
    options: {
      accountId: string;
      entityId: string;
      surveyId: string;
      versionId: string;
      name: string;
    },
    thunkApi
  ) => {
    let updatedAccount = await customFetch(
      `${getBaseUrl()}/survey/duplicate`,
      'POST',
      JSON.stringify({
        ...options,
        date: new Date(),
      }),
      true
    );
    updateCurrentEntity(updatedAccount, thunkApi);
    return updatedAccount;
  }
);

export const disableSurvey = createAsyncThunk(
  'account/DISABLE_SURVEY',
  async (options: { accountId: string; surveyId: string; disabled: boolean }) => {
    return await customFetch(`${getBaseUrl()}/survey/disable`, 'POST', JSON.stringify(options), true);
  }
);

export const deleteSurvey = createAsyncThunk('account/DELETE_SURVEY', async (options: { versionId: string; surveyId: string }) => {
  return await customFetch(`${getBaseUrl()}/survey/delete`, 'POST', JSON.stringify(options), true);
});

export const migrateSurvey = createAsyncThunk(
  'account/MIGRATE_SURVEY',
  async (data: { accountId: string; sourceSurveyId: string; targetSurveyId: string }) => {
    return await customFetch(`${getBaseUrl()}/survey/migrate`, 'POST', JSON.stringify(data), true);
  }
);

export const revertMigration = createAsyncThunk(
  'account/REVERT_MIGRATION',
  async (data: { accountId: string; sourceSurveyId: string; targetSurveyId: string }) => {
    return await customFetch(`${getBaseUrl()}/survey/revertMigration`, 'POST', JSON.stringify(data), true);
  }
);

export const updateSurveyRefs = createAsyncThunk(
  'survey/UPDATE_SURVEY_REFS',
  async (data: {
    accountId: string;
    surveysIdsMapping: any;
    surveyVersionsIdsMapping: any;
    surveyStepsIdsMapping: any;
    questionsIdsMapping: any;
  }) => {
    return await customFetch(`${getBaseUrl()}/survey/updateSurveyRefs`, 'POST', JSON.stringify(data), true);
  }
);

export const removeOrphanSharedStepsAndQuestions = createAsyncThunk(
  'survey/REMOVE_ORPHAN_SHARED_STEPS_AND_QUESTIONS',
  async (data: { accountId: string; userId: string }) => {
    return await customFetch(`${getBaseUrl()}/survey/removeOrphans`, 'POST', JSON.stringify(data), true);
  }
);

export const deleteEntity = createAsyncThunk('account/DELETE_ENTITY', async (data: { accountId: string; entityId: string }) => {
  return await deleteDbEntity(data.accountId, data.entityId);
});

export const duplicateEntity = createAsyncThunk(
  'account/DUPLICATE_ENTITY',
  async (data: { accountId: string; entityId: string; entityName: string }) => {
    return await customFetch(`${getBaseUrl()}/entity/duplicate`, 'POST', JSON.stringify(data), true);
  }
);

export const updateEntityResponses = createAsyncThunk(
  'account/UPDATE_ENTITY_RESPONSES',
  async (
    data: {
      accountId: string;
      userId: string;
      entityId: string;
      versionId: string;
      surveyId: string;
      stepId: string;
      responses: any;
      loader?: boolean;
    },
    thunkApi
  ) => {
    let dispatch = thunkApi.dispatch;
    if (data.loader) {
      dispatch(setLoading(true));
    }
    let updatedAccount = await updateDbResponses(
      data.accountId,
      data.userId,
      data.entityId,
      data.versionId,
      data.surveyId,
      data.stepId,
      data.responses,
      new Date()
    );
    updateCurrentEntity(updatedAccount, thunkApi);
    updateCurrentVersion(updatedAccount, thunkApi);

    if (data.loader) {
      dispatch(setLoading(false));
    }
    return updatedAccount;
  }
);

export const createDbAccount = createAsyncThunk('account/CREATE_ACCOUNT', async (accountInfos: NewAccountInfos, thunkApi) => {
  let dispatch = thunkApi.dispatch;
  let state = thunkApi.getState();
  let allAccounts = selectAllAccounts(state);
  dispatch(setLoading(true));
  let createdAccount = await createAccount(accountInfos);
  if (createdAccount.ok) {
    dispatch(setAllAccounts([...allAccounts, createdAccount.account]));
    dispatch(setSelectedAccount(createdAccount.account));
    dispatch(setLoading(false));
  }
  dispatch(setLoading(false));
});

export const createPassword = createAsyncThunk(
  'account/CREATE_PASSWORD',
  async (options: { userId: string; userToken; password: any; userName?: any; firstName?: any }) => {
    let response = await createUserPassword(options.userId, options.userToken, options.password, options.userName, options.firstName);
    return response;
  }
);

export const addNote = createAsyncThunk('account/ADD_NOTE', async (options: { accountId: string; userId: string; notes: any }) => {
  return await customFetch(
    `${getBaseUrl()}/account/addNote`,
    'POST',
    JSON.stringify({
      ...options,
      date: new Date(),
    }),
    true
  );
});

export const deleteNote = createAsyncThunk('account/DELETE_NOTE', async (options: { accountId: string; noteId: string }) => {
  return await customFetch(`${getBaseUrl()}/account/deleteNote`, 'POST', JSON.stringify(options), true);
});

export const updateStats = createAsyncThunk('account/UPDATE_STATS', async (options: { entityId: string; stats: any }) => {
  return await customFetch(`${getBaseUrl()}/account/updateStats`, 'POST', JSON.stringify({ ...options }), true);
});

export const resetPassword = createAsyncThunk('account/RESET_PASSWORD', async (data: { email: any }) => {
  let updatedUser = await resetDbPassword(data.email);
  return updatedUser;
});

export const submitLogin = createAsyncThunk('account/SUBMIT_LOGIN', async (credentials: any, thunkApi) => {
  let dbCreatedUser = await login(credentials.email, credentials.password);

  if (dbCreatedUser) {
    let updatedAccount = await updateNotifications(dbCreatedUser.user._id);
    let updatedUser = updatedAccount.users.find(user => user._id === dbCreatedUser.user._id);
    let accountEntities = updatedAccount.entities;

    let userRights = updatedAccount.actionRights;
    userRights = userRights ?? {};

    if (dbCreatedUser.user.lastLogin) {
      localStorage.setItem('lastLogin', dbCreatedUser.user.lastLogin);
    } else {
      localStorage.removeItem('lastLogin');
    }

    return {
      token: dbCreatedUser.xsrfToken,
      account: { ...updatedAccount, actionRights: userRights, entities: accountEntities },
      user: updatedUser,
    };
  }
});

export const updateDbRemaining = createAsyncThunk('account/UPDATE_REMAINING_DL', async (options: { accountId: string; actions: any }) => {
  let response = await updateRemaining(options.accountId, options.actions);
  return { error: response.error, account: response.account };
});

export const updateResponses = createAsyncThunk(
  'account/UPDATE_RESPONSES',
  async (
    data: {
      accountId: string;
      values: any;
      currentEntity?: any;
    },
    thunkApi
  ) => {
    let state = thunkApi.getState();
    let dispatch = thunkApi.dispatch;
    let impersonated = selectImpersonated(state);
    let activeAccount = selectActiveAccount(state);
    let selectedAccount = selectSelectedAccount(state);
    let user = selectUser(state);
    let accountId = data.accountId !== undefined ? data.accountId : activeAccount._id;
    let rawEntities = data.values;
    let enrichedEntities = rawEntities.map(entity => {
      const alteredActions = entity?.actionPlan?.actions?.map(action => {
        if (action.isEntityScoop || action.custom) {
          return { ...action };
        } else {
          // Check actions where conditions doesn't fit anymore => archive it
          const actionConditions = getActionConditions(action.id)?.conditions;
          const { versSurveysAnswers, nonVersSurveysAnswers } = retrieveSurveysResponses(selectedAccount || activeAccount, entity);
          console.log('YOUPI');
          const isMatchConditions = verifyResponsesMatchConditions(actionConditions, versSurveysAnswers, nonVersSurveysAnswers);

          if (!isMatchConditions) {
            return { ...action, isArchived: true, active: false };
          } else {
            return { ...action };
          }
        }
      });

      let entityCompletionPercentage = getEntityTotalFillingPercentage(entity);
      return {
        ...entity,
        completionPercentage: entityCompletionPercentage,
        actionPlan: { ...entity.actionPlan, actions: alteredActions },
      };
    });

    let rawUsers = selectedAccount ? selectedAccount.users : activeAccount.users;
    let enrichedUsers = rawUsers.map(rawUser => {
      if (rawUser._id !== user._id) {
        return rawUser;
      }

      let detailedShare = groupSharedQuestionsBySurvey(rawUser._id);
      if (detailedShare.sharedDetails.length === 0) {
        return rawUser;
      }
      if (detailedShare.isFulfilled) {
        addNotification('information_teams_response_done', accountId, user.email);
      }
      return { ...rawUser, shareFulfilled: detailedShare.isFulfilled };
    });

    let updatedAccount = await updateAccountResponses(accountId, enrichedEntities, enrichedUsers);
    if (impersonated) {
      dispatch(setImpersonated(updatedAccount));
    }
    // Check if there is a currentEntity to handle the change after the Account data update
    if (data.currentEntity) {
      const updatedCurrentEntity = updatedAccount.entities.filter(entity => entity._id === data.currentEntity._id);
      dispatch(setCurrentEntity(updatedCurrentEntity[0]));
    }
    return { ...updatedAccount };
  }
);

export const addNotificationToUserById = createAsyncThunk(
  'user/ADD_NOTIFICATION',
  async (options: { accountId: string; notificationId: string; guestEmail }) => {
    return await customFetch(`${getBaseUrl()}/user/addNotification`, 'POST', JSON.stringify(options), true);
  }
);

export const removeUserFromAccount = createAsyncThunk('account/REMOVE_USER', async (options: { userId: string }) => {
  return await customFetch(`${getBaseUrl()}/user/removeUser`, 'POST', JSON.stringify(options), true);
});

export const adduserToAccount = createAsyncThunk('account/ADD_USER', async (options: { accountId: string; user: any; sendMail: boolean }) => {
  return await customFetch(`${getBaseUrl()}/user/addUser`, 'POST', JSON.stringify(options), true);
});

export const updateAllUsers = createAsyncThunk(
  'account/UPDATE_ALL_USERS',
  async (options: { accountId: string; users: any; newAdminUserList?: any }) => {
    return await customFetch(`${getBaseUrl()}/user/updateUsers`, 'POST', JSON.stringify(options), true);
  }
);

export const updateUser = createAsyncThunk('user/UPDATE_USER', async (options: { userId: string; data: any }) => {
  return await customFetch(`${getBaseUrl()}/user/updateUser`, 'POST', JSON.stringify(options), true);
});

export const updateUnavailableDays = createAsyncThunk(
  'user/UPDATE_UNAVAILABLE_DAYS',
  async (options: { accountId: string; unavailabilityDays: any }) => {
    return await customFetch(`${getBaseUrl()}/account/updateUnavailability`, 'POST', JSON.stringify(options), true);
  }
);

export const updatePassword = createAsyncThunk('account/UPDATE_PASSWORD', async (options: { userId: string; userToken; password: any }) => {
  return await updateUserPassword(options.userId, options.userToken, options.password);
});

export const updateAccount = createAsyncThunk(
  'account/UPDATE_USER',
  async (options: { accountId: string; infosChanges: any; legalOfficerInitialization?: boolean; bypassOnboardingAppointment?: boolean }) =>
    await customFetch(`${getBaseUrl()}/account/updateAccount`, 'POST', JSON.stringify(options), true)
);

export const submitCurrentEntity = createAsyncThunk('account/SUBMIT_ENTITY', async (options: { accountId: string; entityId: string }) => {
  return await customFetch(`${getBaseUrl()}/entity/submit`, 'POST', JSON.stringify(options), true);
});

// ActionPlan
export const activateActionPlan = createAsyncThunk('account/ACTIVATE_ACTION_PLAN', async (data: { accountId: string; entityId: string }) => {
  return await actionPlanActivation(data);
});

export const updateActionPlan = createAsyncThunk('account/UPDATE_ACTION_PLAN', async (data: { [key: string]: any }) => {
  return await updateAction(data);
});

export const updateCommentActionPlan = createAsyncThunk('account/ADD_COMMENT', async (data: { [key: string]: any }) => {
  return await addComment(data);
});

export const updateCommentStateActionPlan = createAsyncThunk('account/UPDATE_COMMENT_STATE', async (data: { [key: string]: any }) => {
  return await updateCommentState(data);
});

export const createCustomAction = createAsyncThunk('account/CREATE_CUSTOM_ACTION', async (data: { [key: string]: any }) => {
  return await createAction(data);
});

export const editCustomAction = createAsyncThunk('account/EDIT_CUSTOM_ACTION', async (data: { [key: string]: any }) => {
  return await editAction(data);
});

export const editDirectoryCustomAction = createAsyncThunk('account/EDIT_DIRECTORY_CUSTOM_ACTION', async (data: { [key: string]: any }) => {
  return await editDirectoryAction(data);
});

export const deleteCustomAction = createAsyncThunk('account/DELETE_CUSTOM_ACTION', async (data: { [key: string]: any }) => {
  return await deleteAction(data);
});

export const deleteDirectoryCustomAction = createAsyncThunk('account/DELETE_DIRECTORY_CUSTOM_ACTION', async (data: { [key: string]: any }) => {
  return await deleteDirectoryAction(data);
});

export const updateActionSubContractorInfo = createAsyncThunk('account/UPDATE_ACTION_SUBCONTRACTOR_INFO', async (data: { [key: string]: any }) => {
  return await updateSubContractorInfo(data);
});

export const handleLegalOfficerAuditDecision = createAsyncThunk(
  'account/LEGAL_OFFICER_AUDIT_DECISION',
  async (data: {
    accountId: string;
    entityId: string;
    actionId: string;
    type: string;
    versionProductId: string;
    relatedProductId?: string;
    subContractorId: string;
  }) => {
    const { accountId, entityId, actionId, type, versionProductId, relatedProductId, subContractorId } = data;
    return await validateOrRefuseAudit(accountId, entityId, actionId, type, versionProductId, relatedProductId, subContractorId);
  }
);

export const handleLegalOpsAuditDecision = createAsyncThunk(
  'account/LEGAL_OPS_AUDIT_DECISION',
  async (data: { type: string; subContractorId: string }) => {
    const { type, subContractorId } = data;
    return await validateOrRefuseAuditLegalOps(type, subContractorId);
  }
);

// REMINDERS
export const sendReminder = createAsyncThunk(
  'reminder/SEND_REMINDER',
  async (options: { reminderId: string; wizardRedirection: boolean }, thunkApi) => {
    const { reminderId, wizardRedirection } = options;
    let state = thunkApi.getState();
    let selectedAccount = selectSelectedAccount(state);
    return await sendReminderToSelectedAccount(reminderId, wizardRedirection, selectedAccount._id);
  }
);

export const fetchEntityActions = createAsyncThunk('account/FETCH_ENTITY_ACTIONS', async (data: { accountId: string; entityId: string }) => {
  const { accountId, entityId } = data;
  return await getActions(accountId, entityId);
});

export const fetchClientEntityActions = createAsyncThunk(
  'account/FETCH_CLIENT_ENTITY_ACTIONS',
  async (data: { accountId: string; entityId: string }) => {
    const { accountId, entityId } = data;
    return await getClientActions(accountId, entityId);
  }
);

export const fetchDirectoryActions = createAsyncThunk('account/FETCH_CUSTOMS_ACTIONS', async () => {
  return await getDirectoryActions();
});

export const shareActionsPlan = createAsyncThunk('account/SHARE_ACTIONS_PLAN', async (data: { [key: string]: any }) => {
  return await shareActionPlan(data);
});

export const shareItem = createAsyncThunk('account/SHARE_ITEM_TO_USER', async (data: { [key: string]: any }) => {
  return await shareItemToUser(data);
});

const updateState = (state, action) => {
  if (action.payload._id === state?.selectedAccount?._id) {
    state.selectedAccount = action.payload;
  }

  if (action.payload._id === state?.impersonated?._id) {
    state.impersonated = action.payload;
  } else if (action.payload._id === state?.account?._id) {
    state.account = action.payload;
  }

  if (action.payload._id) {
    let accountIndex = state.allAccounts.findIndex(account => account._id === action.payload._id);
    state.allAccounts[accountIndex] = action.payload;
  }
};

export const accountReducer = createSlice({
  name: 'ACCOUNT',
  initialState: {
    allAccounts: [],
    selectedAccount: undefined,
    account: undefined,
    user: undefined,
    originalUser: undefined,
    impersonated: undefined,
    downloadOk: true,
    actions: [],
    directoryActions: [],
  },
  reducers: {
    setAllAccounts: (state, action) => {
      state.allAccounts = action.payload;
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setOriginalUser: (state, action) => {
      state.originalUser = action.payload;
    },
    setImpersonated: (state, action) => {
      state.impersonated = action.payload;
    },
    disconnectUser: state => {
      state.selectedAccount = undefined;
      state.account = undefined;
      state.user = undefined;
      state.impersonated = undefined;
    },
    setDownloadOk: (state, action) => {
      state.downloadOk = action.payload;
    },
    clearEntityActions: state => {
      state.actions = [];
    },
    setDirectoryActions: (state, action) => {
      state.directoryActions = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllAccounts.fulfilled, (state, action) => {
        state.allAccounts = action.payload;
      })
      .addCase(updateRights.fulfilled, (state, action) => {
        state.selectedAccount = action.payload;
      })
      .addCase(validateAccount.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(updateStep.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(validateEntity.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(refuseEntity.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(createNewEntity.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(addNote.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(updateStats.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(duplicateEntity.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(deleteEntity.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(renameEntity.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(disableSurvey.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(deleteSurvey.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(renameSurvey.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(addVersionToSurvey.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(cloneSurvey.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(submitLogin.fulfilled, (state, action) => {
        state.account = action.payload.account;
        state.user = action.payload.user;
        setSessionToken(action.payload.token);
        setSessionUser(action.payload.user._id);
        state.downloadOk = action.payload.account.remainingDownload > 0;
      })
      .addCase(updateDbRemaining.fulfilled, (state, action) => {
        state.account.activity.remainingDownload = action.payload.account.activity.remainingDownload;
        state.downloadOk = action.payload.account.activity.remainingDownload > 0;
      })
      .addCase(updateEntityResponses.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(updateResponses.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(updateEntities.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(removeUserFromAccount.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(adduserToAccount.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(updateAllUsers.fulfilled, (state, action) => {
        state.account.users = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        // updateState(state, action);
        // update state only with user updated data
        state.account.users = state.account.users.map(user => {
          if (user._id === action.payload._id) {
            return action.payload;
          }
          return user;
        });
      })
      .addCase(updateUnavailableDays.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(submitCurrentEntity.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(activateActionPlan.fulfilled, (state, action) => {
        state.selectedAccount = action.payload;
      })
      .addCase(updateActionPlan.fulfilled, (state, action) => {
        // Create a map of existing actions by their identifiers for quick lookup
        const existingActionsMap = new Map();
        state.actions.forEach(existingAction => {
          const key = existingAction.actionId || `${existingAction.id}-${existingAction.versionProductId}-${existingAction.relatedProductId || ''}`;
          existingActionsMap.set(key, existingAction);
        });

        // Update the state actions with the new actions from the payload
        state.actions = action.payload.actions.map(action => {
          const key = action.actionId || `${action.id}-${action.versionProductId}-${action.relatedProductId || ''}`;
          const existingActionDataInState = existingActionsMap.get(key);
          if (existingActionDataInState) {
            return { ...existingActionDataInState, ...action };
          }
          return action;
        });
        // TODO!: to be optimized => delete the account response
        updateState(state, { payload: action.payload.account });
      })
      .addCase(updateCommentActionPlan.fulfilled, (state, action) => {
        state.actions = action.payload.actions;
        updateState(state, { payload: action.payload.account });
      })
      .addCase(updateCommentStateActionPlan.fulfilled, (state, action) => {
        state.actions = action.payload.actions;
        updateState(state, { payload: action.payload.account });
      })
      .addCase(createCustomAction.fulfilled, (state, action) => {
        state.actions = action.payload.actions;
        state.directoryActions = action.payload.directoryActions;
      })
      .addCase(editCustomAction.fulfilled, (state, action) => {
        state.actions = action.payload;
      })
      .addCase(editDirectoryCustomAction.fulfilled, (state, action) => {
        state.directoryActions = action.payload;
      })
      .addCase(deleteCustomAction.fulfilled, (state, action) => {
        state.actions = action.payload.actions;
        state.directoryActions = action.payload.directoryActions;
      })
      .addCase(deleteDirectoryCustomAction.fulfilled, (state, action) => {
        state.directoryActions = action.payload;
      })
      .addCase(updateActionSubContractorInfo.fulfilled, (state, action) => {
        state.actions = state.actions.map(prevState => {
          if (prevState.actionId === action.payload.data.actionId) {
            return action.payload.data;
          }
          return prevState;
        });
      })
      .addCase(handleLegalOfficerAuditDecision.fulfilled, (state, action) => {
        console.log('🚀 ~ action payload:', action.payload);
        updateState(state, action);
      })
      .addCase(sendReminder.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(fetchEntityActions.fulfilled, (state, action) => {
        if (action.payload.success) state.actions = action.payload.data;
        else state.actions = [];
      })
      .addCase(fetchClientEntityActions.fulfilled, (state, action) => {
        if (action.payload.success) state.actions = action.payload.data;
        else state.actions = [];
      })
      .addCase(shareActionsPlan.fulfilled, (state, action) => {
        state.actions = action.payload.actions;
        state.account = action.payload.account;
      })
      .addCase(shareItem.fulfilled, (state, action) => {
        state.actions = state.actions.map(prevState => {
          const updatedAction = action.payload.actions.find(action => action.actionId === prevState.actionId);
          if (updatedAction) {
            return { ...prevState, ...updatedAction };
          }
          return prevState;
        });
        updateState(state, { payload: action.payload.account });
      })
      .addCase(revertMigration.fulfilled, (state, action) => {
        updateState(state, action);
      })
      .addCase(fetchDirectoryActions.fulfilled, (state, action) => {
        state.directoryActions = action.payload.data;
      });
  },
});

export const {
  setSelectedAccount,
  setAllAccounts,
  setAccount,
  setUser,
  setImpersonated,
  disconnectUser,
  setDownloadOk,
  clearEntityActions,
  setOriginalUser,
  setDirectoryActions,
} = accountReducer.actions;

export const selectAllAccounts = state => state.account.allAccounts;

export const selectSelectedAccount = state => state.account.selectedAccount;

export const selectAccount = state => state.account.account;

export const selectUser = state => state.account.user;

export const selectImpersonated = state => state.account.impersonated;

export const selectDownloadOk = state => state.account.downloadOk;

export const selectActionRights = state => state?.account?.account?.actionRights;

export const selectActiveAccount = state => state?.account?.impersonated || state?.account?.account;

export const selectEntityActions = state => state.account.actions;

export const selectOriginalUser = state => state.account.originalUser;

export const selectDirectoryActions = state => state.account.directoryActions;

export default accountReducer.reducer;
