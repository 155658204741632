import { Modal } from '../modal/Modal';
import { isSurveyDisabled } from '../../utils/utils';
import {
  ButtonContainer,
  ConformityErrorsSummary,
  DisableLink,
  EmptyMessages,
  ErrorItem,
  ErrorList,
  ErrorSubTitle,
  ErrorTitle,
  ToggleLink,
  Slider,
  SurveyName,
} from './ModalAuthorizedSurveys_.style';
import { useState } from 'react';
import { Button } from '@components/Button';
import { useTranslation } from 'react-i18next';
import { PiWarningLight } from 'react-icons/pi';
import { FiArrowRight } from 'react-icons/fi';

export interface ModalAuthorizedSurveysProps {
  show?: boolean;
  onClose: () => void;
  onSave: () => void;
  onDisableSurvey: ({ id, name }) => void;
  questionsConflicts: any;
  blockingSurveys: any;
}

export const ModalAuthorizedSurveys = ({
  show,
  onClose,
  onSave,
  onDisableSurvey,
  questionsConflicts,
  blockingSurveys,
}: ModalAuthorizedSurveysProps) => {
  const { t } = useTranslation();
  const [showQuestions, setShowQuestions] = useState(false);

  const toggle = () => {
    setShowQuestions(prevState => !prevState);
  };

  return (
    <>
      {show && (
        <Modal onClose={onClose} size={{ height: '75%', width: '40%' }} show={show} title={'Questionnaires incompatibles :'}>
          <ConformityErrorsSummary>
            <ErrorTitle>
              <span>Certaines questions apparaissent dans plusieurs questionnaires autorisés.</span>
              <div>
                <b>Veuillez désactiver le(s) questionnaire(s) concerné(s)</b> avant de valider le changement d'autorisations.
              </div>
              <ToggleLink onClick={toggle}>
                {' '}
                <FiArrowRight /> <div>voir les {!showQuestions ? 'questions' : 'questionnaires'} concernés</div>
              </ToggleLink>
            </ErrorTitle>
            {!showQuestions && (
              <>
                {blockingSurveys?.length > 0 ? (
                  <>
                    <ErrorSubTitle>Veuillez désactiver les questionnaires bloquants :</ErrorSubTitle>
                    <Slider>
                      <ErrorList>
                        {blockingSurveys?.map(
                          (survey, index) =>
                            !isSurveyDisabled(survey.id, true) && (
                              <ErrorItem key={index}>
                                <SurveyName>{survey.name}</SurveyName>
                                <DisableLink onClick={() => onDisableSurvey(survey.id)}>{t('common.disable')}</DisableLink>
                              </ErrorItem>
                            )
                        )}
                      </ErrorList>
                    </Slider>
                  </>
                ) : (
                  <EmptyMessages>
                    <PiWarningLight />
                    <span>Aucun questionnaire n'est bloquant</span>
                  </EmptyMessages>
                )}
              </>
            )}
            {showQuestions && (
              <Slider>
                <ErrorList>
                  {questionsConflicts?.length > 0 &&
                    questionsConflicts?.map((question, index) => (
                      <ErrorItem key={index}>
                        <SurveyName>{question.name}</SurveyName>
                        <span>
                          <b>
                            {question.surveys[0].name} <FiArrowRight /> {question.surveys[1].name}
                          </b>
                        </span>
                      </ErrorItem>
                    ))}
                </ErrorList>
              </Slider>
            )}
            <ButtonContainer>
              <Button type='text' onClick={onClose} label={t('common.cancel')} />
              <Button type='primary' onClick={onSave} label={'Mettre à jour les autorisations'} disabled={questionsConflicts?.length > 0} />
            </ButtonContainer>
          </ConformityErrorsSummary>
        </Modal>
      )}
    </>
  );
};
