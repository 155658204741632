import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { TbUser } from 'react-icons/tb';
import { MdOutlineEmail } from 'react-icons/md';
import { BsPlusCircle } from 'react-icons/bs';

import { selectActiveAccount, selectAllAccounts, selectSelectedAccount } from '@reducers/account.reducer';
import { selectSurveys, selectUserActions } from '@reducers/contentful.reducer';

import { EntityProps, ViewProps } from '@components-new/common/types';
import { Container, Tabs } from '@components-new/atoms';
import { PageWrapper } from '@components-new/molecules';

import { Modal } from '@components/modal/Modal';
import { Button } from '@components/Button';

import Inventory from './inventory/Inventory';
import Audits from './audit/Audit';

import { finalities, newSubContractorInitialState } from './AuditsAndInventory.constants';

import { createSubContractor } from '@api/audit';
import { addEventTracking, getEntityActionsMerged } from '@utils/utils';
import { getAccountById } from '@utils/api';
import { LEGAL_OPS } from '@utils/constants';

import { ViewComponents } from './AuditsAndInventory.types';
import { Label, NewSubContractorContainer, NewSubContractorTitle, StyledInputComponent } from './AuditsAndInventory_.styles';
import { getEntitiesOptions, getFinalityOptions } from './AuditsAndInventory.utils';

const AuditsAndInventory = () => {
  const { t } = useTranslation();

  const allAccounts = useSelector(selectAllAccounts);
  const selectedAccount = useSelector(selectSelectedAccount);
  const userActions = useSelector(selectUserActions);
  const activeAccount = useSelector(selectActiveAccount);
  const contentfulSurveysData = useSelector(selectSurveys);

  const [selectedEntity, setSelectedEntity] = useState<EntityProps>(selectedAccount.entities.length === 1 ? selectedAccount.entities[0] : null);
  const [currentAccount, setCurrentAccount] = useState<any>(selectedAccount);
  const [actionPlanActived, setActionPlanActived] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [view, setView] = useState<ViewProps>({
    label: t('views.inventory'),
    value: 'inventory',
  });
  const [displayNewSubContractorModal, setDisplayNewSubContractorModal] = useState<boolean>(false);
  const [newSubContractor, setNewSubContractor] = useState(newSubContractorInitialState);
  const [isSubContractorCreated, setIsSubContractorCreated] = useState<boolean>(false);

  useEffect(() => {
    changeCurrentAccount(selectedAccount);
  }, []);

  useEffect(() => {
    if (!actionPlanActived) {
      setView({
        label: t('views.inventory'),
        value: 'inventory',
      });
    }
  }, [actionPlanActived]);

  const views = useMemo(() => {
    return [
      {
        label: t('views.inventory'),
        value: 'inventory',
        onClick: () => {
          setView({
            label: t('views.inventory'),
            value: 'inventory',
          });
        },
      },
      actionPlanActived &&
        currentAccount._id !== 'all' &&
        currentAccount._id !== 'myClients' && {
          label: t('views.audits'),
          value: 'audits',
          onClick: () => {
            addEventTracking('audits', 'Click');
            setView({ label: t('views.audits'), value: 'audits' });
          },
        },
    ];
  }, [actionPlanActived, currentAccount]);

  const viewComponents: ViewComponents = {
    inventory: (
      <Inventory
        currentAccount={currentAccount}
        selectedEntity={selectedEntity}
        loading={loading}
        setLoading={setLoading}
        isSubContractorCreated={isSubContractorCreated}
        setIsSubContractorCreated={setIsSubContractorCreated}
      />
    ),
    audits: <Audits currentAccount={currentAccount} selectedEntity={selectedEntity} />,
  };

  const SelectAll = () => {
    return (
      <Container flex alignItems='center' gap='5px'>
        <div>{t('common.viewAllClient')}</div>
        <Container flex alignItems='center' mrChild='-3px'>
          <TbUser size={12} color='#3D3D3D' />
          <TbUser size={12} color='#3D3D3D' />
          <TbUser size={12} color='#3D3D3D' />
        </Container>
      </Container>
    );
  };

  const getAccountsOptions = () => {
    let accounts = [];
    if (view.value === 'inventory') {
      accounts = [
        {
          _id: 'all',
          companyName: t('common.viewAllClient'),
        },
        {
          _id: 'myClients',
          companyName: t('common.my_clients'),
        },
        ...allAccounts,
      ];
    } else {
      accounts = allAccounts;
    }

    return accounts?.map(account => ({
      label: (account._id === 'all' && <SelectAll />) || account.companyName,
      value: account,
    }));
  };

  const getSelectedAccount = () => {
    if (currentAccount) {
      return { label: currentAccount.companyName, value: currentAccount };
    } else if (getAccountsOptions().length === 1) {
      return getAccountsOptions()[0];
    }
    return null;
  };

  const changeCurrentAccount = async (account: any) => {
    let fullAccount;
    if (account._id === 'all' || account._id === 'myClients') {
      fullAccount = account;
      setActionPlanActived(true);
    } else {
      if (account?.entities) {
        fullAccount = account;
      } else {
        fullAccount = await getAccountById(account._id);
      }
      if (fullAccount?.entities?.length) {
        const hasActionAudits = await Promise.all(
          fullAccount.entities.map(async entity => {
            const fullActions = await getEntityActionsMerged(entity?.actionPlan.actions, userActions);
            return fullActions.some(action => action.category === 'Audit' && action.active === true);
          })
        ).then(results => results.some(Boolean));
        setActionPlanActived(hasActionAudits);
      } else {
        setActionPlanActived(false);
      }
    }

    if (fullAccount?.entities?.length === 1) {
      setSelectedEntity(fullAccount.entities[0]);
    } else {
      setSelectedEntity(null);
    }
    setCurrentAccount(fullAccount);
  };

  const getSelectedEntity = () => {
    if (selectedEntity) {
      return { label: selectedEntity.name, value: selectedEntity };
    } else if (getEntitiesOptions(currentAccount)?.length === 1) {
      return getEntitiesOptions(currentAccount)[0];
    }
    return null;
  };

  const versionnableSurveysIds = useMemo(() => {
    return contentfulSurveysData.filter(survey => survey.versioning.enabled).map(survey => survey.id);
  }, [contentfulSurveysData]);

  const getEntityProducts = useMemo(() => {
    return selectedEntity?.responses?.flatMap(response => {
      return response.versions
        .map(version => {
          if (!version.disabled && versionnableSurveysIds.includes(response.surveyId)) {
            return {
              label: version.versionName,
              value: version._id,
            };
          }
          return null;
        })
        .filter(Boolean);
    });
  }, [selectedEntity]);

  const handleNewSubContractorChange = event => {
    const { name, value } = event.target;
    setNewSubContractor(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNewSubContractorCreation = async () => {
    try {
      setLoading(true);
      const subContractorData = {
        ...newSubContractor,
        finality: newSubContractor.finality.value,
        accountId: currentAccount._id,
        entityId: selectedEntity._id,
        relatedSurveyId: newSubContractor.relatedSurveyId.value,
      };
      const result = await createSubContractor(subContractorData);

      if (result.error) {
        throw new Error(result.error);
      }
      if (result.success) {
        setIsSubContractorCreated(true);
        toast.success(t('audits.newSubContractorSuccess'));
        setDisplayNewSubContractorModal(false);
        setNewSubContractor(newSubContractorInitialState);
      }
    } catch (error) {
      console.error('Error creating subContractor :', error);
      toast.error(t('audits.newSubContractorError'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageWrapper customPadding='30px' selectedView={view} applyPadding position={'relative'}>
        <Tabs views={views} selectedView={view.value} tabsUnderlined />
        {!['all', 'myClients'].includes(currentAccount._id) && LEGAL_OPS.includes(activeAccount._id) && selectedEntity && (
          <NewSubContractorContainer onClick={() => setDisplayNewSubContractorModal(true)}>
            <BsPlusCircle size='19px' />
            <span>{t('audits.newSubContractor')}</span>
          </NewSubContractorContainer>
        )}
        <Container flex mb='1rem' mrChild='32px' height='40px'>
          <StyledInputComponent
            type={'select'}
            disabled={loading}
            options={getAccountsOptions()}
            onChange={(_, value) => changeCurrentAccount(value)}
            sharable={false}
            defaultValue={getSelectedAccount()}
            fontFamily='Nunito'
          />
          {currentAccount?._id !== 'all' && currentAccount._id !== 'myClients' && (
            <StyledInputComponent
              placeholder={t('actionPlan.choose_entity')}
              type={'select'}
              disabled={loading}
              options={getEntitiesOptions(currentAccount)}
              onChange={(_, value) => setSelectedEntity(value)}
              value={getSelectedEntity()}
              sharable={false}
              defaultValue={getSelectedEntity()}
              noOptionsMessage={() => t('actionPlan.no_finality_available')}
              fontFamily='Nunito'
              clearable='true'
            />
          )}
        </Container>

        {viewComponents[view.value as keyof ViewComponents] || null}
      </PageWrapper>
      {displayNewSubContractorModal && (
        <Modal top='20%' show={displayNewSubContractorModal} onClose={() => setDisplayNewSubContractorModal(false)} rowGap='21px'>
          <NewSubContractorTitle>
            <MdOutlineEmail color={'#3B4B5F'} size={30} />
            <span>{t('audits.sendSurvey')}</span>
          </NewSubContractorTitle>
          <Container>
            <Label>{t('audits.enterMail')} :</Label>
            <StyledInputComponent
              type='text'
              name='contactEmail'
              placeholder={'Ex: patrick@dipeeo.com'}
              fontFamily='Nunito'
              value={newSubContractor.contactEmail}
              onChange={handleNewSubContractorChange}
            />
          </Container>
          <Container width='100%'>
            <Label>{t('audits.selectProduct')}</Label>
            <StyledInputComponent
              type='select'
              name='finality'
              placeholder={t('common.product')}
              fontFamily='Nunito'
              options={getEntityProducts}
              value={newSubContractor.relatedSurveyId}
              onChange={(_value: any, _field: any, _event: any, option: { label: string; value: string }) => {
                setNewSubContractor(prevState => {
                  return {
                    ...prevState,
                    relatedSurveyId: option,
                  };
                });
              }}
            />
          </Container>
          <Container>
            <Label>{t('audits.subContractorInfos')} :</Label>
            <Container flex gap={'16px'}>
              <StyledInputComponent
                type='text'
                name='subContractorName'
                placeholder={t('audits.enterSubContractor')}
                fontFamily='Nunito'
                value={newSubContractor.subContractorName}
                onChange={handleNewSubContractorChange}
              />
              <StyledInputComponent
                type='select'
                name='finality'
                placeholder={t('common.finality')}
                fontFamily='Nunito'
                options={getFinalityOptions(finalities)}
                value={newSubContractor.finality}
                onChange={(_value: any, _field: any, _event: any, option: { label: string; value: string }) => {
                  setNewSubContractor(prevState => {
                    return {
                      ...prevState,
                      finality: option,
                    };
                  });
                }}
              />
            </Container>
          </Container>
          <Container flex justifyContent='center' mt='13px'>
            <Button
              customColor='#326C82'
              label={t('common.validate')}
              fontFamily='Nunito'
              labelOptions={{ fontWeight: '700', fontSize: '1.1em' }}
              style={{ width: '35%' }}
              onClick={() => handleNewSubContractorCreation()}
            />
          </Container>
        </Modal>
      )}
    </>
  );
};

export default AuditsAndInventory;
