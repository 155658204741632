import styled from 'styled-components';
import { Z_INDEX } from '../../utils/constants';

export const ChildrenContainer = styled.div<{ width?: string }>`
  border-radius: 10px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.81);
  position: absolute;
  top: 65px;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : '500px')};
  background-color: white;
  z-index: ${Z_INDEX.modal};
  border: 1px solid rgba(0, 0, 0, 0.2);
  min-height: 0;
  max-height: 485px;
  right: 0;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: end;
  position: relative;
  svg {
    width: 36px;
    height: 36px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const Header = styled.div`
  max-height: 70px;
  display: flex;
  align-items: center;
  padding: 15px;

  h3 {
    flex: 1;
  }

  svg {
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.2s;
    height: 24px;
    width: 24px;

    &:hover {
      opacity: 1;
    }
  }
`;

export const DropDownMenuContainer = styled.div`
  position: relative;
  font-family: 'Biennale';
`;

export const CloseIcon = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: end;
`;

export const TiteContainer = styled.div`
  display: flex;
  flex: 11;
  align-items: center;
`;

export const OptionLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const OptionContent = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  flex: 1;
  padding: 15px 20px;
  cursor: pointer;

  &:hover {
    transition: 0.2s;
    background: #eff0f6;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: calc(100% - 110px);
  height: calc(100% - 110px);
  font-weight: 400;

  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c7c7c7;
  }

  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 2.1px -1px 12px 0px #f0f0f0;
  }
`;

export const Slider = styled.div`
  flex: 1;
  min-height: 0;
  overflow-y: scroll;
  max-height: 400px;
`;

export const Arrow = styled.div`
  width: 50px;
  height: 25px;
  position: absolute;
  bottom: 100%;
  right: -25px;
  transform: translateX(-50%);
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: white;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 100%;
    left: 50%;
    box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.6);
  }
`;
