import styled from 'styled-components';

export const Input = styled.input<{ width?: string; height?: string }>`
  ${({ width }) => (width ? `width:${width}` : `width:150px`)};
  ${({ height }) => (height ? `height:${height}` : `height:20px`)};
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 1rem;
  outline: none;
  border: 1px solid lightgray;
  letter-spacing: 1px;
  &:focus-visible {
    border: 1px solid #326c82;
  }
  &[type='date'] {
    font-weight: bold;
  }
`;

export const Span = styled.span<{ size?: string }>`
  ${({ size }) => (size ? `font-size:${size}` : `font-size:1.125rem`)};
  font-weight: bold;
`;
